import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { DialogPasswordData } from '../../../interfaces/dialog-password';
import { DriverProvider } from '../../../providers/driver/driver.provider.service';
import { DriverUpdateBody } from '../../../interfaces';
import { FormsService } from '../../../services/utils/forms.service';
import { IDialogPasswordLabels } from '../../../interfaces/labels/dialog-labels.interface';
import { ILanguageLabels } from '../../../interfaces/labels/language-labels.interface';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

const CANCEL = 'Cancel';
const CLOSED = 'Closed';
const CONFIRM = 'Confirm';
const IMG_REF = '../../../assets/driver.svg';
const KEY_CONFIRM_PASSWORD = 'confirmPassword';
const KEY_ERROR_INCORRECT = 'incorrect';
const KEY_ERROR_MINLENGTH = 'minlength';
const KEY_ERROR_NOTMATCH = 'notMatch';
const KEY_ERROR_REQUIRED = 'required';
const KEY_ERROR_WHITESPACE = 'whitespace';
const KEY_PASSWORD = 'password';

@Component({
  selector: 'app-dialog-password',
  templateUrl: './dialog-password.component.html',
  styleUrls: ['./dialog-password.component.scss', '../../../app.component.scss']
})
export class DialogPasswordComponent implements OnInit {
  public confirmPassword: string;
  public image: string;
  public languageLabels: ILanguageLabels;
  public languageSuscription: Subscription;
  public notMatch: boolean;
  public password: string;
  public dialogPasswordLabels: IDialogPasswordLabels;
  public passwordForm: FormGroup;
  public user: string;
  public validPasswords: boolean;

  async ngOnInit(): Promise<void> {
    this.user = this.data.user;
    this.password = '';
    this.confirmPassword = '';
    this.initForm(this.builder);
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
    this.notMatch = true;
    this.validPasswords = false;
    this.validatePasswords();
    this.image = IMG_REF;
  }

  constructor(
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    private dialogRef: MatDialogRef<DialogPasswordComponent>,
    private driverProvider: DriverProvider,
    private formsService: FormsService,
    private readonly builder: FormBuilder,
    private toast: ToastrAlertsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogPasswordData) {
      this.setLanguage();
    }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component
   * @return {void}
   */
  public async getLabels(): Promise<void> {
    await this.getLanguageLabels();
    await this.getDialogPasswordLabels();
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getLabels();
      },
      () => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageLabels(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets Dialog Password Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getDialogPasswordLabels(): Promise<void> {
    this.dialogPasswordLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.DIALOG_PASSWORD_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  public onClickCancel(): void {
    this.dialogRef.close(CANCEL);
  }
  public onClickSave(): void {
    this.onUpdate();
  }
  public onClickClose(): void {
    this.dialogRef.close(CLOSED);
  }

  private initForm(fb: FormBuilder): void {
    this.passwordForm = fb.group({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        this.formsService.noWhitespaceValidator,
        this.formsService.inbetweenSpaceValidator]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        this.formsService.noWhitespaceValidator]),
      }, { validators: this.formsService.passwordValidator });
  }

  /**
   * @description It evaluates the error that should be displayed in the mat error tag
   * @param confirm a flag that helps to know if the form control is for the confirm input
   */
  public getErrorMessage(confirm: boolean = false) {
    let message = '';
    let control: AbstractControl;
    control = confirm ? this.passwordForm.get(KEY_CONFIRM_PASSWORD) : this.passwordForm.get(KEY_PASSWORD);

    if (control.hasError(KEY_ERROR_REQUIRED)) {
      message = this.dialogPasswordLabels ? this.dialogPasswordLabels.messageRequired : '';
    } else if (control.hasError(KEY_ERROR_MINLENGTH)) {
      message = this.dialogPasswordLabels.messageMinLength;
    } else if (this.passwordForm.hasError(KEY_ERROR_NOTMATCH) && confirm) {
      message = this.dialogPasswordLabels.messageNotMatch;
    } else if (control.hasError(KEY_ERROR_WHITESPACE)) {
      message = this.dialogPasswordLabels.messageWhitespace;
    }

    return message;
  }

  /**
   * @description It contains the validations to know if a password is savable
   */
  public validatePasswords(): void {
    this.passwordForm.valueChanges.subscribe(() => {
      if (this.passwordForm.get(KEY_CONFIRM_PASSWORD).hasError(KEY_ERROR_INCORRECT) || this.passwordForm.hasError(KEY_ERROR_NOTMATCH)) {
        this.validPasswords = false;
      } else {
        if (this.passwordForm.get(KEY_CONFIRM_PASSWORD).hasError(KEY_ERROR_WHITESPACE)) {
          this.validPasswords = false;
        } else {
          this.validPasswords = true;
        }
      }
    });
  }

  /**
   * @description It updates the password sending the driver object to the core-api
   */
  public onUpdate(): void {
    if (!this.validPasswords) {
      return;
    } else {
      const driver = this.data.driver;
      const updateBody: DriverUpdateBody = {
        apmaterno: driver.apmaterno,
        appaterno: driver.appaterno,
        email: driver.email,
        imss: driver.imss,
        licenciaId: driver.licenciaId,
        movil: driver.movil,
        nombre: driver.nombre,
        password: this.passwordForm.get(KEY_PASSWORD).value,
        rfc: driver.rfc,
        usuario: this.data.user
      };

      this.driverProvider.updateDriver(driver.lineaTransporte, driver._id, updateBody)
        .then(() => {
          this.toast.successAlert(this.dialogPasswordLabels.tooltipSuccess);
          this.dialogRef.close(CONFIRM);
        })
        .catch(err => {
          console.log({err});
          this.toast.errorAlert(this.dialogPasswordLabels.tooltipErrorUpdate);
        });
    }
  }
}
