import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { View } from '../../interfaces/view';

const apiUrl = environment.apiUrl;

@Injectable()
export class ViewProvider {

  constructor(private http: HttpClient) { }

  public async getViews(): Promise<Array<View>> {
    return await this.http.get<Array<View>>(apiUrl + '/role/view/').toPromise();
  }
}
