import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { AppService } from '../../../app.service';
import { ConfigurationProvider } from '../../../providers/configuration/configuration.provider.service';
import { DialogDynamicLocationConsts, DialogDynamicLocationToastLabels } from './constants/dialog-dynamic-location.constants';
import { DialogLocationComponent } from '../dialog-location/dialog-location.component';
import { GenericRegexp } from './../../../regexp/generic.regexp';
import { LanguageConstants } from './../../../constants/language.constants';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { LocationProvider } from 'src/app/providers/locations/location-provider.service';
import { MapsService } from '../../../providers/maps/maps.service';
import { MOBILITY_CONSTANTS } from './../../../constants/mobility.constants';
import { OrderType } from '../../../../app/enums/order-type';
import { Profiles } from './../../../enums';
import { Shipper } from './../../../interfaces/shipper';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';
import { UniqueLocationFieldRequest } from './../../../interfaces/unique-field.request';
import { uniqueLocationFieldValueAsync } from './../../../validators/unique-location-field.async.validator';
import { WarehouseProvider } from '../../../../app/providers/warehouse/warehouse-provider.service';

import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

const MAX_LENGTH_RFC_PHONE = 13;
const MIN_LENGTH_RFC = 12;

@Component({
  selector: 'app-dialog-dynamic-location',
  templateUrl: './dialog-dynamic-location.component.html',
  styleUrls: ['./dialog-dynamic-location.component.scss', '../../../app.component.scss']
})
export class DialogDynamicLocationComponent implements OnInit, OnChanges {
  private geoCoder: object;
  public autocomplete: any;
  public componentForm: object;
  public dialogDynamicLocationTagsTraslated: any;
  public draggable: boolean;
  public isRequired: boolean;
  public latitude: number;
  public languageLabels: any;
  public languagesuscription: Subscription;
  public locationBodyForm: object;
  public locationFormGroup: FormGroup;
  public locationTypeSelected: string;
  public longitude: number;
  public manuallyAddressResult: object;
  public rfcIsRequired: boolean;
  public routeFormValue: string;
  public settlementFormValue: string;
  public shipperRFCLocations: Array<string>;
  public showExtraFields: boolean;
  public streetNumberFormValue: string;
  public zoom: number;
  public warehouseName: string;

  @Input() locationInfo;
  @Input() locationType;
  @Input() orderType: string;
  @Input() resetForm?: boolean;
  @Input() shipper: Shipper;
  @Input() validateUniqueName: boolean;
  @Input() warehouseCreate?: boolean;
  @Input() requiredFields?: boolean;
  @Output() validWarehouseForm = new EventEmitter<object>();
  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  constructor(
    private appService: AppService,
    private configurationProvider: ConfigurationProvider,
    private locationProvider: LocationProvider,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private readonly builder: FormBuilder,
    public toastService: ToastrAlertsService,
    private warehouseProvider: WarehouseProvider,
    public _map: MapsService,
    public dialogRef: MatDialogRef<DialogLocationComponent>,
    public _languageChangeEventService: LanguageChangeEventService,
    public _languageTranslateService: LanguageTranslateService
  ) {
    this.setLanguage();
    this.rfcIsRequired = false;
   }

  async ngOnInit(): Promise<void> {
    this.isRequired = true;
    if (this.requiredFields === false) {
      this.isRequired = this.requiredFields;
    }
    if (this.warehouseCreate) {
      this.locationType = DialogDynamicLocationConsts.ORIGIN;
    }
    if (this.locationInfo) {
      this.latitude = this.locationInfo.latitude;
      this.longitude = this.locationInfo.longitude;
      this.locationBodyForm = {
        route: this.locationInfo.name,
        political: this.locationInfo.settlement,
        locality: this.locationInfo.municipality,
        administrative_area_level_1: this.locationInfo.state,
        country: this.locationInfo.state,
        postal_code: this.locationInfo.postalCode
      };
      this.warehouseName = this.locationInfo.name;
    } else {
      this.latitude = 19.4325412;
      this.longitude = -99.1338442;
      this.locationBodyForm = {
        street_number: '',
        route: '',
        political: '',
        sublocality_level_1: '',
        locality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: ''
      };
      this.warehouseName = '';
    }
    this.zoom = 15;
    this.draggable = true;
    this.componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      political: 'long_name',
      sublocality_level_1: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name'
    };
    this.settlementFormValue = '';
    this.streetNumberFormValue = null;
    this.routeFormValue = '';
    this.initForm(this.builder);
    await this.extraFieldsOriginValidation();
    this.setMap();
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    await this.getDynamicLocationLabels();
    await this.getShipperSettings();
  }

  public setMap(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      this.autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        });
        this.manuallyAddressResult = {};
        this.fillForm();
      });
    });
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  public getAddress(latitude: number, longitude: number): void {
    this.geoCoder['geocode']({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === DialogDynamicLocationConsts.OK) {
        this.manuallyAddressResult = results;
        if (results[0]) {
          this.fillForm();
        } else {
          window.alert(this.dialogDynamicLocationTagsTraslated.windowAlert);
        }
      } else {
        window.alert(this.dialogDynamicLocationTagsTraslated.geocoderFailed + status);
      }
    });
  }

  public fillForm(): void {
    let place;
    if (!(_.isEmpty(this.manuallyAddressResult))) {
      place = this.manuallyAddressResult[0];
    } else {
      place = this.autocomplete.getPlace();
    }

    this.locationBodyForm = {};
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (this.componentForm[addressType]) {
        const val = place.address_components[i][this.componentForm[addressType]];
        this.locationBodyForm[addressType] = val;
      }
    }
    this.initForm(this.builder);
  }

  public async mapClickedOrDragEnd($event: MouseEvent): Promise<void> {
    (<HTMLInputElement>document.getElementById('search')).value = '';
    if (this.locationInfo && this.locationInfo._id) {
      this.locationInfo = {
        _id: this.locationInfo._id,
        address: '',
        latitude: '',
        longitude: '',
        municipality: '',
        name: '',
        postalCode: '',
        settlement: '',
        state: ''
      };
    } else {
      this.locationInfo = '';
    }
    if (this.warehouseCreate) {
      this.warehouseName = this.locationFormGroup.value.name ? this.locationFormGroup.value.name : '';
    }
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    if (this.locationInfo.hasOwnProperty(DialogDynamicLocationConsts.KEY_ID)) {
      this.locationInfo._id = null;
    }
    await this.extraFieldsOriginValidation();
    this.getAddress(this.latitude, this.longitude);
  }

  /**
   * @description Gets shipper settings and makes required RFC location field
   */
  public async getShipperSettings(): Promise<void> {
    const profile = this.appService.getProfile();
    if (profile === Profiles.Shipper) {
      const shipperOid = this.appService.getShipperOid();
      const shipperSettings = await this.configurationProvider.getShipperConfig(shipperOid);
      this.getShipperLocationsRFC(shipperOid);
      if (shipperSettings.fieldsSettings && shipperSettings.fieldsSettings.locationRFCRequired) {
        this.locationFormGroup.controls['locationRFC'].clearValidators();
        this.locationFormGroup.controls['locationRFC'].updateValueAndValidity();
        this.locationFormGroup.controls['locationRFC'].setValidators(Validators.compose([
          Validators.required,
          Validators.pattern(GenericRegexp.PATTERN_RFC),
          Validators.maxLength(MAX_LENGTH_RFC_PHONE),
          Validators.minLength(MIN_LENGTH_RFC)]));
        this.rfcIsRequired = true;
      }
    }
  }

  /**
   * @description Gets Shipper Locations RFC
   * @param {string} shipperOid Current shipper oid provided
   */
   public async getShipperLocationsRFC(shipperOid: string): Promise<void> {
    try {
      this.shipperRFCLocations = await this.locationProvider.getRFCLocationsByShipper(shipperOid);
    } catch {
      this.toastService.errorAlert(DialogDynamicLocationToastLabels.errorGettingRFCLocations);
    }
  }

  public initForm(fb: FormBuilder): void {
    this.addressValidator();
    if (this.showExtraFields) {
      this.locationFormGroup = fb.group({
        name: new FormControl(this.routeFormValue, [Validators.required, Validators.pattern(GenericRegexp.NON_EMPTY_STRING),
          Validators.maxLength(120)]),
        address: new FormControl(this.locationInfo ? this.locationInfo.address : this.routeFormValue
          + this.streetNumberFormValue, [Validators.required, Validators.pattern(GenericRegexp.NON_EMPTY_STRING),
          Validators.maxLength(120)]),
        settlement: new FormControl(this.settlementFormValue, [Validators.required, Validators.pattern(GenericRegexp.NON_EMPTY_STRING),
          Validators.maxLength(120)]),
        postalCode: new FormControl(this.locationBodyForm['postal_code'], [Validators.required, Validators.maxLength(5),
          Validators.pattern('^[0-9]*$')]),
        municipality: new FormControl(this.locationBodyForm['locality'], [Validators.required,
          Validators.pattern(GenericRegexp.NON_EMPTY_STRING), Validators.maxLength(120)]),
        state: new FormControl(this.locationBodyForm['administrative_area_level_1'], [Validators.required,
          Validators.pattern(GenericRegexp.NON_EMPTY_STRING), Validators.maxLength(120)]),
        phone: new FormControl(this.locationInfo.phone ? this.locationInfo.phone : null, [Validators.maxLength(13),
          Validators.pattern('^[0-9]*$')]),
        responsable: new FormControl(this.locationInfo.responsable ? this.locationInfo.responsable : null, [Validators.maxLength(25)]),
        locationDescription: new FormControl(this.locationInfo.locationDescription ? this.locationInfo.locationDescription : null,
          [Validators.maxLength(150)]),
        latitude: new FormControl(''),
        longitude: new FormControl(''),
        locationRFC: new FormControl(this.locationInfo && this.locationInfo.locationRFC ?
          this.locationInfo.locationRFC : null, Validators.compose([
          Validators.pattern(GenericRegexp.PATTERN_RFC),
          Validators.maxLength(MAX_LENGTH_RFC_PHONE),
          Validators.minLength(MIN_LENGTH_RFC)]))
      });
    } else {
      if (!this.requiredFields) {
        this.locationFormGroup = fb.group({
          name: new FormControl(this.warehouseCreate ? this.warehouseName : this.routeFormValue,
            [Validators.pattern(GenericRegexp.NON_EMPTY_STRING), Validators.minLength(3), Validators.maxLength(50)]),
          address: new FormControl(this.locationInfo ? this.locationInfo.address : this.routeFormValue
            + this.streetNumberFormValue, [Validators.pattern(GenericRegexp.NON_EMPTY_STRING), Validators.minLength(3),
              Validators.maxLength(100)]),
          settlement: new FormControl(this.settlementFormValue, [Validators.pattern(GenericRegexp.NON_EMPTY_STRING),
            Validators.minLength(3), Validators.maxLength(50)]),
          postalCode: new FormControl(this.locationBodyForm['postal_code'], [, Validators.minLength(5), Validators.maxLength(10)]),
          municipality: new FormControl(this.locationBodyForm['locality'], [
            Validators.pattern(GenericRegexp.NON_EMPTY_STRING), Validators.minLength(3), Validators.maxLength(50)]),
          state: new FormControl(this.locationBodyForm['administrative_area_level_1'], [
            Validators.pattern(GenericRegexp.NON_EMPTY_STRING), Validators.minLength(2), Validators.maxLength(50)]),
          latitude: new FormControl(''),
          longitude: new FormControl(''),
          locationRFC: new FormControl(this.locationInfo && this.locationInfo.locationRFC ?
            this.locationInfo.locationRFC : null, Validators.compose([
            Validators.pattern(GenericRegexp.PATTERN_RFC),
            Validators.maxLength(MAX_LENGTH_RFC_PHONE),
            Validators.minLength(MIN_LENGTH_RFC)]))
        });
      } else {
        this.locationFormGroup = fb.group({
          name: new FormControl(this.warehouseCreate ? this.warehouseName : this.routeFormValue,
            [Validators.required, Validators.pattern(GenericRegexp.NON_EMPTY_STRING)]),
          address: new FormControl(this.locationInfo ? this.locationInfo.address : this.routeFormValue
            + this.streetNumberFormValue, [Validators.required, Validators.pattern(GenericRegexp.NON_EMPTY_STRING)]),
          settlement: new FormControl(this.settlementFormValue, [Validators.required, Validators.pattern(GenericRegexp.NON_EMPTY_STRING)]),
          postalCode: new FormControl(this.locationBodyForm['postal_code'], [Validators.required, Validators.minLength(5)]),
          municipality: new FormControl(this.locationBodyForm['locality'], [Validators.required,
          Validators.pattern(GenericRegexp.NON_EMPTY_STRING)]),
          state: new FormControl(this.locationBodyForm['administrative_area_level_1'], [Validators.required,
          Validators.pattern(GenericRegexp.NON_EMPTY_STRING)]),
          latitude: new FormControl(''),
          longitude: new FormControl('')
        });
      }
      if (this.validateUniqueName) {
        this.locationFormGroup.controls['name'].setAsyncValidators([uniqueLocationFieldValueAsync(this.locationProvider,
          this.buildUniqueFieldRequest())]);
        if (this.locationFormGroup.controls['name'].value !== '') {
          this.locationFormGroup.controls['name'].markAsTouched();
        }
      }
      if (this.warehouseCreate) {
        this.locationFormGroup.statusChanges.subscribe(
          (status) => {
            if (status === DialogDynamicLocationConsts.VALID_FORM) {
              this.locationFormGroup.get('latitude').setValue(this.latitude, { emitEvent: false });
              this.locationFormGroup.get('longitude').setValue(this.longitude, { emitEvent: false });
              const locationBody = this.locationFormGroup.value;
              locationBody['latitude'] = this.latitude;
              locationBody['longitude'] = this.longitude;
              this.validWarehouseForm.emit(this.locationFormGroup.value);
            } else {
              this.validWarehouseForm.emit({});
            }
          }
        );
        this.locationFormGroup.updateValueAndValidity();
      }
    }
  }

  public onSubmit(): void {
    const locationBody = this.locationFormGroup.value;
    locationBody['latitude'] = this.latitude;
    locationBody['longitude'] = this.longitude;
    this.dialogRef.close(locationBody);
  }

  public addressValidator() {
    if (this.locationBodyForm['political']) {
      this.settlementFormValue = this.locationBodyForm['political'];
    } else {
      this.settlementFormValue = this.locationBodyForm['sublocality_level_1'];
    }

    if (this.locationBodyForm['street_number']) {
      this.streetNumberFormValue = ' ' + this.locationBodyForm['street_number'];
    } else {
      this.streetNumberFormValue = '';
    }

    if (this.locationBodyForm['route']) {
      this.routeFormValue = this.locationBodyForm['route'];
    } else {
      this.routeFormValue = '';
    }
  }

  /**
   * @description Builds Unique field Request for location name
   */
  public buildUniqueFieldRequest(): UniqueLocationFieldRequest {
    return {
      locationName: '',
      shipperId: this.shipper._id,
      typeDesc: MOBILITY_CONSTANTS.DESTINATION_MODEL_DESC,
      locationId: (this.locationInfo ? this.locationInfo._id : undefined)
    };
  }

  /**
   * @description Do that the user only write numbers on the inputs
   */
   public onKeyPressCode(event: KeyboardEvent): void {
    const input = event.key;
    if (!GenericRegexp.ONLY_NUMBERS.test(input)) {
      event.preventDefault();
    }
  }

  /**
   * @description Determine if phone, responsable and locationDescription fields will be display in origin dialog
   */
  private async extraFieldsOrderTypeValidation(): Promise<void> {
    if (!_.isNull(this.orderType) && this.orderType === OrderType.Picking) {
      await this.checkIsOriginWarehouse();
    } else {
      this.showExtraFields = false;
    }
    this.initForm(this.builder);
  }

  /**
   * @description Check if the Origin Location is a Warehouse and set showExtraFields true or false
   */
  private async checkIsOriginWarehouse(): Promise<void> {
    try {
      if (this.locationInfo._id) {
        const warehouseFound = await this.warehouseProvider.getWarehouseByOid(this.locationInfo._id);
        if (!_.isEmpty(warehouseFound.contactInfo.phoneNumber) || !_.isEmpty(warehouseFound.contactInfo.responsable) ||
            !_.isEmpty(warehouseFound.contactInfo.locationDescription)) {
          this.showExtraFields = false;
        } else {
          this.showExtraFields = true;
        }
      } else {
        this.showExtraFields = true;
      }
    } catch {
      this.showExtraFields = true;
    }
  }

  /**
   * @description Check if current locations is an origin or destination and validate extra fields form
   */
  private async extraFieldsOriginValidation(): Promise<void> {
    if (this.locationType === DialogDynamicLocationConsts.ORIGIN) {
      this.extraFieldsOrderTypeValidation();
    } else {
      this.showExtraFields = true;
    }
    this.initForm(this.builder);
  }

  /**
   * @description React to the SCF language change event setting the configuration in the interface
   * @param {string} languageKey Optional language key string, default is spanish "es"
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changedn by the SCF,
   * setting the configuration in the interace
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languagesuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getDynamicLocationLabels();
      },
      (error => {
        this.toastService.errorAlert(this.languageLabels.errorChangingLanguage);
      })
    );
  }

  /**
   * @description Gets languagelabels from translate JSON files
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toastService.errorAlert(this.languageLabels.errorChangingLanguage);
    });
  }

  /**
   * @description Gets languagelabels from translate JSON files
   * @return {Promise<void>}
   */
  public async getDynamicLocationLabels(): Promise<void> {
    this.dialogDynamicLocationTagsTraslated = await this._languageTranslateService.getLanguageLabels(LanguageConstants.DYNAMIC_LOCATION_MAP)
    .catch(error => {
      this.toastService.errorAlert(this.languageLabels.errorChangingLanguage);
    });
  }
}
