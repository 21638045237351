export const ORDER_SCANNED_STATUS = [
  { value: 'Completa', label: 'complete' },
  { value: 'Incompleta', label: 'incomplete' },
];

export const ORDER_SEARCH_TYPES = [
  { value: 'Folio', label: 'folio' },
  { value: 'Identificador', label: 'identifier' },
  { value: 'Referencia Interna', label: 'internalReference' },
  { value: 'Factura', label: 'invoice' }
];

export const ORDER_STATUS = [
  { value: 'En Tránsito', label: 'onDelivery' },
  { value: 'Entregada', label: 'delivered' },
  { value: 'Asignada', label: 'assigned' },
  { value: 'Sin Asignar', label: 'unassigned' },
  { value: 'No Entregada', label: 'undelivered' },
  { value: 'Liberada', label: 'released' },
  { value: 'Rechazada', label: 'rejected' },
  { value: 'Parcial', label: 'partial' },
  { value: 'Cancelada', label: 'cancelled' },
  { value: 'Sin Evidencia', label: 'noEvidence' }
];
