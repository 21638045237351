<div fxLayout="column" fxFlex="42">
  <mat-card
    class="cardServiceBottom mat-elevation-z2 cardTop matCardStyle"
    [ngClass] = "{'disabled-class': !assignBudget}">
    <mat-card-header class="headerTitle">
      <mat-slide-toggle
        id="assignBudgetToggle"
        [(ngModel)]="assignBudget"
        (change)="assignBudgetToggle(assignBudget)"
        color="primary"
        class="titleToggle">
        {{ label.assignBudget }}
      </mat-slide-toggle>
    </mat-card-header>
    <mat-card-header class="header-subtitle">
      <p class="card-rate-subtitle">{{ label.assignBudgetSubtitle }}</p>
    </mat-card-header>
    <mat-divider [inset]="true" class="divider"></mat-divider>
    <mat-card-content>
      <div class="block icon" fxFlex="18" fxFlexAlign="center">
        <mat-icon>
          <img src="{{ moneyUp }}" alt="money up" />
        </mat-icon>
      </div>
    </mat-card-content>
    <mat-card-content>
      <form [formGroup]="rateFormGroup">
        <section class="section">
          <p *ngIf="tariffFound && tariffSuggested" class="sucess-class"> {{ label.tariffFoundMsg }} {{ tariffSuggested.identifier }} </p>
          <span [ngClass]="'sectionSpan'">{{ label.assignBudgetInput }}</span>
          <mat-form-field class="assignBudgetInput">
            <input
              id="assignBudgetInput"
              (input)="validateAmount(true)"
              (focusout)="validateAmount(true)"
              (keyup.enter)="validateAmount(true)"
              formControlName="amount"
              type="number"
              matInput
              maxlength="25"/>
            <mat-error *ngIf="rateFormGroup.controls['amount'].hasError('required')">
              <strong>{{ label.amountRequired }}</strong>
            </mat-error>
            <p *ngIf="rateFormGroup.controls['amount'].hasError('invalidAmount')" class="error-input">
              {{ label.minAmountError }}
            </p>
            </mat-form-field>
            <mat-icon
              [ngClass]="'infoIcon'"
              matTooltip="{{ label.rateTooltip }}">
              info
            </mat-icon>
        </section>
      </form>
      <mat-slide-toggle
        [disabled]="!assignBudget"
        id="receiveCounterOffersToogle"
        [(ngModel)]="receiveCounterOffers"
        color="primary"
        class="titleToggle">
        {{ label.receiveCounterOffers }}
        <mat-icon
          [ngClass]="'infoIcon'"
          matTooltip="{{ label.counterOffersTooltip }}">
          info
        </mat-icon>
      </mat-slide-toggle>
      <br/>
      <br/>
      <br/>
      <br/>
      <button
        id="saveAmountButton"
        [disabled]="!rateFormGroup.valid || !assignBudget"
        class="mat-raised-button mat-primary continueButton">
        <span>{{ label.save }}</span>
      </button>
    </mat-card-content>
  </mat-card>
</div>
<div fxLayout="column" fxFlex="42">
  <mat-card
    class="cardServiceBottom mat-elevation-z2 cardTop matCardStyle"
    [ngClass] = "{'disabled-class': !receiveOffers}">
    <mat-card-header  class="headerTitle">
      <mat-slide-toggle
        id="receiveOffersToggle"
        [(ngModel)]="receiveOffers"
        (change)="receiveOffersToggle(receiveOffers)"
        color="primary"
        class="titleToggle">
        {{ label.receiveOffers }}
      </mat-slide-toggle>
    </mat-card-header>
    <mat-card-header class="header-subtitle">
      <p class="card-rate-subtitle">{{ label.receiveOffersSubtitle }}</p>
    </mat-card-header>
    <mat-divider [inset]="true" class="divider"></mat-divider>
    <mat-card-content>
      <div class="block icon-2" fxFlex="18" fxFlexAlign="center">
        <mat-icon>
          <img src="{{ moneyCircle }}" alt="money circle"/>
        </mat-icon>
      </div>
    </mat-card-content>
    <p class="card-rate-subtitle">{{ label.commentsToCarrierQuestion }}</p>
    <p class="card-rate-subtitle less-Margin">{{ label.commentsToCarrier }}</p>
    <mat-card-content>
      <form [formGroup]="rateFormGroup">
        <div fxFlexAlign="center" class="divCommentsToCarrier marginBottom">
          <textarea
            [disabled]="!receiveOffers"
            maxlength="300"
            formControlName="commentsToCarrier"
            id="commentsToCarrierTextArea"
            (input)="sendCommentsToCarrier($event.target.value)"
            class="CommentsCarrierInput"
            [ngClass]="!receiveOffers ? 'CommentsCarrierInputDisabled' : 'commentsToCarrierInput'"
            matInput
            placeholder="{{ label.commentsToCarrierPlaceholder }}">
          </textarea>
        </div>
      </form>
    </mat-card-content>
    <button
      id="receiveOffersButton"
      [disabled]="!receiveOffers"
      class="mat-raised-button mat-primary continueButton">
      <span>{{ label.toReceiveOffers }}</span>
    </button>
  </mat-card>
</div>
