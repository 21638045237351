<div fxLayout="row">
  <div fxFlex="95" class="title-page">
    <h2>{{labels.title}} {{shipment.shipmentRequestId ? shipment.shipmentRequestId: shipment.shipmentId}}</h2>
  </div>
  <div fxFlex="5">
    <mat-icon class="cursor-pointer close-button" (click)="onClickClose()">
      close
    </mat-icon>   
  </div>
</div>
<mat-tab-group class="content">
  <mat-tab label="{{labels.generalTabTitle}}">
    <div fxLayout="row">
      <div class="block center" fxFlex="20">
        <mat-icon class="center-horizontal align-truck-icon">
          <img src="../../../../assets/icons/shipment-card/truck-gear.svg" alt="Transport">
        </mat-icon>
      </div>
      <div class="block section" fxFlex="30">
         <p class="subtitle">{{labels.loadSubtitle}}</p>
         <p class="element">
           &bull; {{shipment.transport.vehicle? shipment.transport.vehicle: labels.noAssign}} <br>
           &bull; {{shipment.vehicleType? shipment.vehicleType: labels.noAssign}}<br>
           &bull; {{shipment.loadType}}
         </p>
      </div>
      <div class="block section" fxFlex="50">
        <p class="subtitle">{{labels.specialRequerimentsSubtitle}}</p>
        <div class="scrolleable-section">
          <p *ngFor="let specialReq of requirements" class="element">
            &bull;{{specialReq? specialReq: labels.withoutSpecialRequirements}}
          </p>
          <p
            class="element"
            *ngIf="requirements.length === 0">
            &bull; {{labels.withoutSpecialRequirements}}
          </p>
        </div>
      </div>
    </div>
    <mat-divider class="mat-divider-header"></mat-divider>
    <div fxLayout="row">
      <div class="block center" fxFlex="18">
        <mat-icon class="center-horizontal align-icon">
          <img src="../../../../assets/icons/shipment-card/pallet-gear.svg" alt="Pallet">
        </mat-icon>
      </div>
      <div class="block section" fxFlex="30">
        <p class="subtitle">{{labels.volumetrySubtitle}}</p>
        <p class="element">
          &bull; {{shipment.weight? shipment.weight: labels.noInfo}} {{labels.kilograms}} <br>
          &bull; {{shipment.volume}} {{labels.volume}}
        </p>
      </div>
    </div>
    <mat-divider class="mat-divider-header"></mat-divider>
    <div fxLayout="row">
      <div class="block center" fxFlex=18>
        <mat-icon class="center-horizontal align-icon">
          <img src="../../../../assets//icons/shipment-card/calendar-clock.svg" alt=" Calendar">
        </mat-icon>
      </div>
      <div class="block section" fxFlex="30">
        <p class="subtitle">{{labels.dateTimeShipmentSubtitle}}</p>
        <p class="element">
          &bull; <strong>{{labels.origin}} :</strong> {{ shipment.loadDate ? (shipment.loadDate | date: 'dd/MMM/yyyy &middot; hh:mm a') : labels.noConfirm }}<br>
          &bull; <strong>{{labels.destination}} :</strong> 
            {{ lastOrder.deliveryDate ? (lastOrder.deliveryDate | date : 'dd/MMM/yyyy') : labels.noConfirm }}
            {{ lastOrder.appointmentHour ? ('&middot; ' + (getDateFromTime(lastOrder.appointmentHour) | date: 'hh:mm a')) : '' }}
        </p>
      </div>
      <div class="block section" fxFlex="50">
        <p class="subtitle">{{labels.dateTimeDistributeSubtitle}}</p>
        <div class="destinations">
            <p class="element">
            <span *ngFor="let order of orders; index as i">
              &bull; {{labels.distribute}} {{i + 1}}: 
              {{ order.deliveryDate | date: 'dd/MMM/yyyy'}}
              {{ order.appointmentHour ? ('&middot;' + (getDateFromTime(order.appointmentHour) | date: 'hh:mm a')) : '' }}
              <br>
            </span>
          </p>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{labels.distributeTabTitle}}({{orders.length}})">
    <div fxLayout="row">
      <div class="block center" fxFlex="18">
        <mat-icon class="center-horizontal align-icon">
          <img src="../../../../assets/icons/shipment-card/map-location.svg" alt="map location">
        </mat-icon>
      </div>
      <div class="block section distribute-section" fxFlex="55">
        <div fxLayout="row">
          <div fxFlex="5">
            <mat-icon class="point-origin">
              fiber_manual_record
            </mat-icon>
          </div>
          <div fxFlex="95">
            <p class="subtitle">{{labels.origin}} - {{shipment.origin.name}}</p>
          </div>
        </div>
        <div *ngFor="let order of orders; index as i" fxLayout="row">
          <div fxFlex="5">
            <mat-icon *ngIf="i !== countOrders -1" class="point-stop">
              fiber_manual_record
            </mat-icon>
            <mat-icon *ngIf="i == countOrders -1" class="point-destination">
              fiber_manual_record
            </mat-icon>
          </div>
          <div fxFlex="95">
            <p *ngIf="i !== countOrders -1" class="subtitle destination">{{labels.distribute}} {{i+1}} - {{order.destination.name}}
            </p>
            <p *ngIf="i == countOrders -1" class="subtitle destination">{{labels.finalDestination}} - {{order.destination.name}}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="!inTransit" class="block section route-details" fxFlex="27">
        <p class="subtitle">{{labels.routeDetails}}</p>
        <p class="element">
          &bull; {{labels.totalDistance}}: <strong>{{shipment.distance ? shipment.distance : 0}} {{labels.kilometers}}</strong><br>
          &bull; {{labels.distributeCount}}: <strong>{{countOrders}}</strong>
        </p>
      </div>
      <div *ngIf="inTransit" class="block section route-details" fxFlex="27">
        <p class="subtitle">{{labels.routeDetails}}</p>
        <p class="element">
          &bull; {{labels.distanceTraveled}}: <strong>{{traveledRoute ? traveledRoute : labels.noInfo}}</strong><br>
          &bull; {{labels.distributeCount}}: <strong>{{countOrders}}</strong>
        </p>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
