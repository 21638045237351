import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IImportationTemplate } from 'scf-library';
import { environment } from '../../../environments/environment';
import {
  API_MODULES,
  API_SOLUTION,
  API_TENANTID } from '../../pages/orders/import-orders/import-orders.properties';

  const apiUrl = environment.apiUrl;

@Injectable()
export class ImportationService {
  constructor (private http: HttpClient) {}
  /**
   * @description Save Importation template
   * @param {IImportationTemplate} template Importation template to be created
   * @return {Promise<any>} Success or fail
   */
  public async saveImportationTemplate (template: IImportationTemplate): Promise<any> {
    return await this.http.post<any>(apiUrl + '/importationTemplates', template).toPromise();
  }

  /**
   * @description Get the template by shipper, module and solution
   * @param {tenantId} tenantId The id of the shipper
   * @param {modules} modules The type of module
   * @param {solution} solution The type of solution
   * @return {Promise<IImportationTemplate>} Success or fail
   */
  public async getImportationTemplate (tenantId: string, modules: string, solution: string): Promise<IImportationTemplate[]> {
    return await this.http.get<IImportationTemplate[]>( apiUrl + '/importationTemplates' + API_TENANTID + tenantId +
        API_MODULES + modules + API_SOLUTION + solution).toPromise();
  }
}
