import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TaxStampData } from 'src/app/interfaces';
import { environment } from '../../../environments/environment';

const tmsIntegratorApiUrl = environment.tmsIntegratorApiUrl;
const objectId = environment.tmsIntegratorSystemOid;

@Injectable()
export class TaxStampProvider {
  constructor(private http: HttpClient) { }

  /**
   * @description Sends the TaxStampData to the tax stamp provider service.
   * @param {TaxStampData} body The information mapped to be sent to the microservice.
   * @param {string} tenantOid Tenant object id associated
   * @returns An object with the server response.
   */
  public async generateTaxStamp(body: TaxStampData, tenantOid: string): Promise<object> {
    return this.http.post<object>(`${tmsIntegratorApiUrl}/stampShipment/system/${objectId}/client/${tenantOid}`, body)
      .toPromise();
  }

  /**
   * @description Gets the tax stamp response from tax stamp provider.
   * @param {string} tenantOid Tenant object id from shipment.
   * @param {string} taxStampId Tax stamp id from shipment.
   * @returns An object with all info response, can be errors or the PDF ready to be downloaded.
   */
  public async getTaxStampResponse(tenantOid: string, taxStampId: string): Promise<object> {
    return this.http.get<object>(`${tmsIntegratorApiUrl}/stampShipment/system/${objectId}/client/${tenantOid}/stampId/${taxStampId}`)
      .toPromise();
  }
}
