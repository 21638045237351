import { Coords } from '../../interfaces/map';
import { SpecialShipmentRequirements } from '../../interfaces/index';

export interface ShipmentRequest {
  acceptedCarrier?: AcceptedCarrierAndOffer;
  autoAcceptance?: boolean;
  creationDate?: Date;
  defaultOffer: number;
  destinations: Array<DestinationMP>;
  expirationDate: string;
  finalOffer?: number;
  id?: string;
  identifier?: string;
  insurancedShipment: boolean;
  loadType: {
    id: string;
    name: string;
  };
  marketplaceOffer?: number;
  offers?: Array<AcceptedCarrierAndOffer>;
  origin: {
    address: string;
    city: string;
    geometry: GeometryData;
    label?: string;
    latitude?: number;
    loadDate: string;
    longitude?: number;
    name: string;
    postalCode: string;
    state: string;
  };
  productType: {
    id: string;
    name: string;
  };
  quoteType: QuoteType;
  reference: string;
  requiredCarrierRate?: number;
  services?: Array<string>;
  shipment?: ShipmentData;
  shipperId: string;
  shipperRequirements?: Array<SpecialShipmentRequirements>;
  status?: string;
  type?: string;
  user?: UserData;
  vehicleSpecs: string;
  vehicleType: {
    id: string;
    name: string;
  };
  visibility?: {
    type: string,
    relatedTo?: RelatedTo
  };
  volume: number;
  weight: number;
}

export interface UserData {
  id: string;
  username: string;
}

export interface ShipmentRequestResponse {
  acceptedCarrier?: AcceptedCarrierAndOffer;
  autoAcceptance: boolean;
  creationDate: Date;
  destinations: Array<ShipmentRequestDestination>;
  defaultOffer: number;
  expirationDate: Date;
  id: string;
  identifier: string;
  insurancedShipment: boolean;
  loadType: {
    _id: string;
    name: string;
  };
  marketplaceOffer: number;
  offers?: Array<AcceptedCarrierAndOffer>;
  origin: ShipmentRequestOrigin;
  productType: {
    name: string;
    _id: string;
  };
  quoteType: string;
  reference: string;
  shipment?: ShipmentData;
  shipperRequirements: [];
  shipperId: string;
  status: string;
  statusLog: Array<StatusLog>;
  tenantId: string;
  type: string;
  user: {
    id: string;
    username: string;
  };
  vehicleType: {
    _id: string;
    name: string;
  };
  visibility?: {
    type: string,
    rate?: number,
    relatedTo?: RelatedTo
  };
  transport?: TransportBody;
  _id: string;
  __v: number;
  vehicleSpecs: string;
  volume: number;
  weigth: number;
  weight?: number;
  userCoordinates?: Coords;
}

export interface TransportBody {
  driver: string;
  driverPhone?: string;
  plates: string;
  vehicle: string;
}

export enum ShipmentRequestType {
  MARKETPLACE = 'Marketplace',
  TENDERING = 'Tendering',
}

export enum TypeVisibility {
  ALL = 'all',
  CARRIER = 'carrier',
  GROUP = 'group'
}

export enum ShipmentRequestStatus {
  ASSIGNED = 'Asignada',
  CONFIRMED = 'Confirmada',
  COUNTER_OFFER = 'Contraoferta',
  CREATED = 'Creada',
  PLANNED = 'Planeada',
  WITHOUT_COUNTER_OFFER = 'Sin contraoferta',
}

export interface RelatedTo {
  id: string;
  name: string;
}

export interface GeometryData {
  coordinates: Array<number>;
}

export interface StatusLog {
  status: string;
  timestamp: Date;
  user: {
    username: string;
    _id: string;
  };
  _id: string;
}

export interface ShipmentRequestOrigin {
  address: string;
  city: string;
  geometry: {
    coordinates: Array<number>;
    type: GeometryType.point;
  };
  loadDate: Date;
  name: string;
  postalCode: string;
  state: string;
}

export interface OfferBody {
  carrier: {
    id: string;
    name: string;
    rate?: number;
  };
  date: Date;
  fee: number;
  offer: number;
}

export interface ShipmentRequestDestination {
  address: string;
  cargoType?: string;
  city: string;
  deliveryDate: Date;
  geometry: {
    coordinates: Array<number>
    type: GeometryType.point;
  };
  name: string;
  postalCode: string;
  state: string;
  sequence: number;
}

export enum GeometryType {
  point = 'Point'
}

export interface LatLng {
  lat: number;
  lng: number;
}
export interface ShipmentData {
  id: string;
  shipmentId: string;
}

export interface VisibilityTypeBody {
  rate?: number;
  relatedTo?: RelatedTo;
  type: string;
}

export interface DestinationMP {
  address: string;
  cargoType?: string;
  city: string;
  deliveryDate: string;
  geometry: GeometryData;
  label?: string;
  latitude?: number;
  longitude?: number;
  name: string;
  postalCode: string;
  sequence: number;
  state: string;
}

export interface AcceptedCarrierAndOffer {
  carrier: {
    id: string;
    name: string;
    rate?: number;
  };
  date: Date;
  fee: number;
  offer: number;
}

export enum QuoteType {
  FIXED_COST = 'Costo fijo',
  WITHOUT_QUOTE = 'Sin cotización'
}

export interface ResponseShipmentRequest {
  item: Array<ShipmentRequestResponse>;
  message: string;
}

export interface ResponseItemShipmentRequest {
  item: ShipmentRequestResponse;
  message: string;
}

export interface ShipmentRequestStatusResponse {
  color: string;
  cssClass?: string;
  description: string;
  offers?: ShipmentRequestResponse;
  shipments: Array<ShipmentRequestResponse>;
  status: string;
}

export interface ShipmentRequestStatusResponseBiis extends ShipmentRequestStatusResponse {
  tabIndex: number;
}

export interface FilterShipmentRequest {
  endDate: Date;
  filterSelected: boolean;
  startDate: Date;
  status: string | Array<string>;
}

export interface Services {
  description: string;
  icon: string;
}
