<mat-grid-list cols="3" rowHeight="1:.40">
  <mat-grid-tile [colspan]="2">
    <span class="dialog-title">
      <strong>
        <p>{{data.title}}</p>
      </strong>
    </span>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="1">
    <mat-icon style="cursor: pointer" class="dialog-close-icon" (click)="onClose()">
      close
    </mat-icon>
  </mat-grid-tile>

</mat-grid-list>
<mat-grid-list cols="1" rowHeight="1:.20">
<form [formGroup]="captureGuideForm">
  <mat-grid-tile [colspan]="1">
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field>
      <input type="text"
        [placeholder]="data.guide"
        matInput
        formControlName="guide"
        [required]="true"
        (keyup.enter)="pushGuide($event.target.value)"/>
        <mat-error *ngIf="captureGuideForm.controls['guide'].hasError('required')">
          {{ data.guide }} <strong>{{ data.required }}{{ data.suffixO }}</strong>
       </mat-error>
      </mat-form-field>
    </div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="1">
    <mat-form-field>
      <input type="text"
        [placeholder]="data.capturedGuide"
        matInput
        formControlName="capturedGuides"
        [required]="true"/>
        <mat-error *ngIf="captureGuideForm.controls['capturedGuides'].hasError('required')">
          {{ data.capturedGuide }} <strong>{{ data.required }}{{ data.suffixO }}</strong>
        </mat-error>
    </mat-form-field>
  </mat-grid-tile>

</form>
</mat-grid-list>
