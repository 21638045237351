import { MatPaginator } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class PaginatorService  {
  public paginatorLabels(paginator: MatPaginator, languageKey?: string): void {
    if (languageKey !== undefined) {
      if (languageKey === 'es') {
        paginator._intl.itemsPerPageLabel = 'Elementos por página';
        paginator._intl.nextPageLabel = 'Siguiente';
        paginator._intl.previousPageLabel = 'Anterior';
        paginator._intl.firstPageLabel = 'Primera página';
        paginator._intl.lastPageLabel = 'Última página';
        paginator._intl.getRangeLabel = this.spanishRangeLabel;
      } else {
        paginator._intl.itemsPerPageLabel = 'Items per page';
        paginator._intl.nextPageLabel = 'Next';
        paginator._intl.previousPageLabel = 'Previous';
        paginator._intl.firstPageLabel = 'First page';
        paginator._intl.lastPageLabel = 'Last page';
        paginator._intl.getRangeLabel = this.englishRangeLabel;
      }
    } else {
      paginator._intl.itemsPerPageLabel = 'Items per page';
      paginator._intl.nextPageLabel = 'Next';
      paginator._intl.previousPageLabel = 'Previous';
      paginator._intl.firstPageLabel = 'First page';
      paginator._intl.lastPageLabel = 'Last page';
      paginator._intl.getRangeLabel = this.englishRangeLabel;
    }
  }

  public spanishRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) { return `0 de ${length}`; }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }

  /**
   * @description set the range of pages in the table
   * @param { number } page  element per table
   * @param { number } pageSize page size option
   * @param { number } length total pages
   * @returns table page  range
   */
  public englishRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) { return `0 of ${length}`; }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} of ${length}`;
  }
}
