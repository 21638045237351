import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../../app.service';
import { Driver, DriverUpdateBody } from '../../interfaces';
import { DriverCreateResponse, DriverDeleteResponse, DriverDetail, DriverUpdateResponse } from '../../interfaces/driver';
import { environment } from '../../../environments/environment';

const apiUrl = environment.apiUrl;

@Injectable()
export class DriverProvider {

  constructor(private http: HttpClient, private _appService: AppService) { }

  public async getDriversByCarrierId (carrierId: string): Promise <Array<Driver>> {
    return await this.http.get<Array<Driver>>(apiUrl + '/lineaTransporte/' + carrierId + '/choferes?activo=true' ).toPromise();
  }

  public async updateDriver(carrierId: string, driverId: string, body: DriverUpdateBody | Driver): Promise<DriverUpdateResponse> {
    return await this.http.put<DriverUpdateResponse>(`${apiUrl}/lineaTransporte/${carrierId}/choferes/${driverId}`, body).toPromise();
  }

  public async deleteDriver(carrierId: string, driverId: string): Promise<DriverDeleteResponse> {
    return await this.http.delete<DriverDeleteResponse>(`${apiUrl}/lineaTransporte/${carrierId}/choferes/${driverId}`).toPromise();
  }

  public async createDriver(driverBody: Driver): Promise<DriverCreateResponse> {
    const carrierOId = driverBody.lineaTransporte;
    return await this.http.post<DriverCreateResponse>(apiUrl + '/lineaTransporte/' + carrierOId + '/choferes', driverBody).toPromise();
  }

  /**
   * @description Get Driver's detail by carrier and id
   * @param carrierOid Driver's carrier
   * @param driverOid Driver's id
   * @returns {Promise<DriverDetail>} Driver's detail
   */
  public async getDriver(carrierOid: string, driverOid: string): Promise<DriverDetail> {
    const url = `${apiUrl}/lineaTransporte/${carrierOid}/choferes/${driverOid}`;
    return await this.http.get<DriverDetail>(url).toPromise();
  }

  /**
   * @description Get all drivers from shipper carriers
   * @param {string} shipperOId shipper _id
   * @returns {Array<Driver>} Driver's info
   */
  public async getAllShipperCarriersDrivers(shipperOId: string): Promise<Array<Driver>> {
    const url = `${apiUrl}/shipper/${shipperOId}/drivers`;
    return await this.http.get<Array<Driver>>(url).toPromise();
  }
}
