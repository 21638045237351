<div class="general-container">
  <h3 class="title-page">{{ 'manualDeliveryTags.title' | translate }} </h3>
  <mat-divider class="mat-divider-header"></mat-divider>
  
  <div *ngIf="orderData">
    <div class="manual-delivery-container">
      <div class="folio-container">
        <span class="subtitle-folio text-highlighted">{{ orderData.folio }}</span>
        <span class="subtitle-status">
          {{ (getStatusTranslated(orderData.status) | correctMisspelling) | uppercase }}
        </span>
      </div>

      <mat-divider class="folio-divider"></mat-divider>

      <div class="order-resume">
        <div *ngIf="!visibleSummary" class="section-divider expand-less">
          <div class="subtitle">
            <span>{{ 'manualDeliveryTags.orderData' | translate }}</span>
          </div>
          <div class="divider">
            <hr>
          </div>
          <div class="icon">
            <button mat-icon-button>
              <mat-icon (click)="visibleSummary=!visibleSummary">expand_more</mat-icon>
            </button>
          </div>
        </div>

        <table
          aria-describedby="order-data-table"
          *ngIf="visibleSummary"
          class="resume-table">
          <tr>
            <th id="order-data-header" colspan="2">
              <div class="partial-text-divider">
                <div class="order-subtitle">
                  <span>{{ 'manualDeliveryTags.orderData' | translate }}</span>
                </div>
                <div class="divider">
                  <hr>
                </div>
              </div>
            </th>
            <th id="destination-data-header" colspan="2">
              <div class="partial-text-divider">
                <div class="order-subtitle">
                  <span>{{ 'manualDeliveryTags.destination' | translate }}</span>
                </div>
                <div class="divider">
                  <hr>
                </div>
              </div>
            </th>
            <th id="merchandise-data-header" colspan="5">
              <div class="partial-text-divider">
                <div class="order-subtitle">
                  <span>{{ 'manualDeliveryTags.merchandiseData' | translate }}</span>
                </div>
                <div class="divider">
                  <hr>
                </div>
              </div>
            </th>
            <th id="arrow-icon">
              <mat-icon (click)="visibleSummary=!visibleSummary">expand_less</mat-icon>
            </th>
          </tr>
          <tr>
            <td>{{ 'manualDeliveryTags.folio' | translate }}:</td>
            <td class="text-highlighted">{{ orderData.identifier ? orderData.identifier : ('manualDeliveryTags.noInfo' | translate)}}</td>
            <td class="order-data-property">{{ 'manualDeliveryTags.name' | translate }}:</td>
            <td class="order-data-value text-highlighted">{{ orderData.destination ? orderData.destination.name : ('manualDeliveryTags.noInfo' | translate) }}</td>
            <td rowspan="2" class="order-data-value quantity-numbers">{{ orderData.boxes ? orderData.boxes : ('manualDeliveryTags.doesNotApply' | translate) }}</td>
            <td rowspan="2" class="order-data-value quantity-numbers">{{ orderData.pieces ? orderData.pieces : ('manualDeliveryTags.doesNotApply' | translate) }}</td>
            <td rowspan="2" class="order-data-value quantity-numbers">{{ orderData.pallets ? orderData.pallets : ('manualDeliveryTags.doesNotApply' | translate) }}</td>
            <td rowspan="2" class="order-data-value quantity-numbers">{{ orderData.weight ? orderData.weight + ' kg' : ('manualDeliveryTags.doesNotApply' | translate) }}</td>
            <td rowspan="2" class="order-data-value quantity-numbers">{{ orderData.volume ? orderData.volume : ('manualDeliveryTags.doesNotApply' | translate) }}
              <span *ngIf=orderData.volume> m<sup>3</sup></span>
            </td>
          </tr>
          <tr>
            <td>{{ 'manualDeliveryTags.internalReference' | translate }}:</td>
            <td>{{ orderData.internalReference ? orderData.internalReference : ('manualDeliveryTags.noInfo' | translate) }}</td>
            <td rowspan="3" class="order-data-property">{{ 'manualDeliveryTags.address' | translate }}:</td>
            <td rowspan="3" class="order-data-value">{{ orderData.destination ? orderData.destination.address : ('manualDeliveryTags.noInfo' | translate) }}</td>
          </tr>
          <tr>
            <td>{{ 'manualDeliveryTags.warehouseOrigin' | translate }}:</td>
            <td>{{ orderData.warehouse ? orderData.warehouse.name : ('manualDeliveryTags.noInfo' |  translate) }}</td>
            <td rowspan="3" class="quantity-labels">{{ 'manualDeliveryTags.boxes' | translate }}</td>
            <td rowspan="3" class="quantity-labels">{{ 'manualDeliveryTags.pieces' | translate }}</td>
            <td rowspan="3" class="quantity-labels">{{ 'manualDeliveryTags.pallets' | translate  }}</td>
            <td rowspan="3" class="quantity-labels">{{ 'manualDeliveryTags.weight' | translate }}</td>
            <td rowspan="3" class="quantity-labels">{{ 'manualDeliveryTags.volume' | translate }}</td>
          </tr>
          <tr>
            <td>{{ 'manualDeliveryTags.account' | translate }}:</td>
            <td class="text-highlighted">{{ orderData.account ? orderData.account.name : ('manualDeliveryTags.noInfo' | translate) }}</td>
          </tr>
          <tr>
            <td>{{ 'manualDeliveryTags.scheduleDate' | translate }}:</td>
            <td>
              {{ orderData.deliveryDate ? (orderData.deliveryDate | date : 'dd/MM/yyyy' : 'GMT') : ('manualDeliveryTags.noInfo' | translate) }}
              {{ orderData.appoinmentHour ? ',' + (orderData.appoinmentHour | date: "HH:mm a") : '' }}
            </td>
            <td rowspan="1" class="order-data-property">{{ 'manualDeliveryTags.municipality' | translate }}:</td>
            <td rowspan="1" class="order-data-value">{{ orderData.destination ? orderData.destination.municipality : ('manualDeliveryTags.noInfo' | translate) }}</td>
          </tr>
        </table>
      </div>

      <form [formGroup]="manualDeliveryForm">
        <mat-card>
          <mat-card-header>
            <mat-card-title>
              <span>{{ 'manualDeliveryTags.deliveryData' | translate }}</span>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-grid-list cols="4" rowHeight="1:.30">
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    matInput
                    (keydown.enter)="handleKeyEnter($event)"
                    class="input--pointer"
                    [matDatepicker]="picker"
                    placeholder="{{ 'manualDeliveryTags.deliveryDate' | translate }}"
                    readonly
                    formControlName="deliveryDate"
                    [min]="minDate"
                    [max]="maxDate"
                    required (click)="picker.open()">
                  <mat-error>
                    {{ 'manualDeliveryTags.deliveryDate' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' | translate }}{{ getSuffix('manualDeliveryTags.suffixA' | translate) }}</strong>
                  </mat-error>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    matInput
                    (keydown.enter)="handleKeyEnter($event)"
                    class="input--pointer"
                    type="time"
                    formControlName="deliveryHour"
                    placeholder="{{ 'manualDeliveryTags.deliveryHour' | translate }}"
                    required>
                  <mat-error>
                    {{ 'manualDeliveryTags.deliveryHour' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' | translate }}{{ getSuffix('manualDeliveryTags.suffixA' | translate) }}</strong>
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <mat-select
                    placeholder="{{ 'manualDeliveryTags.deliveryType' | translate }}"
                    formControlName="deliveryType"
                    required
                    disableOptionCentering="true"
                    (selectionChange)="deliveryTypeSelection()">
                    <mat-option *ngFor="let deliveryType of deliveryTypeList" [value]="deliveryType">{{ deliveryType }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    {{ 'manualDeliveryTags.deliveryType' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' | translate }}{{ getSuffix('manualDeliveryTags.suffixO' | translate) }}</strong>
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    matInput
                    (keydown.enter)="handleKeyEnter($event)"
                    formControlName="receptorName"
                    placeholder="{{ 'manualDeliveryTags.receptorName' | translate }}"
                    [required]="manualDeliveryForm.value.deliveryType === typeForm.totalDelivery || manualDeliveryForm.value.deliveryType === typeForm.partialDelivery">
                  <mat-error>
                    {{ 'manualDeliveryTags.receptorName' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' | translate }}{{ getSuffix('manualDeliveryTags.suffixO' | translate) }}</strong>
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="manualDeliveryForm.value.deliveryType === typeForm.partialDelivery || manualDeliveryForm.value.deliveryType === typeForm.rejection">
          <mat-card-header>
            <mat-card-title>{{ 'manualDeliveryTags.merchandiseRejection' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <table aria-describedby="order-data-table"
              class="resume-table-rejections">
                <tr>
                  <th id="pieces">
                    {{ 'manualDeliveryTags.pieces' | translate }}
                  </th>
                  <th id="rejectedPieces">
                    {{ 'manualDeliveryTags.rejectedPieces' | translate }}
                  </th>
                  <th id="boxes">
                    {{ 'manualDeliveryTags.boxes' | translate }}
                  </th>
                  <th id="rejectedBoxes">
                    {{ 'manualDeliveryTags.rejectedBoxes' | translate }}
                  </th>
                  <th id="pallets">
                    {{ 'manualDeliveryTags.pallets' | translate }}
                  </th>
                  <th id="rejectedPallets">
                    {{ 'manualDeliveryTags.rejectedPallets' | translate }}
                  </th>
                  <th id="empty"></th>
                </tr>
                <tr>
                  <td class="rejection-table">
                    <strong>{{orderData.pieces ? orderData.pieces : 0}}</strong>
                  </td>
                  <td class="rejection-table">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        maxlength="10"
                        appNumberOnly
                        min="0"
                        formControlName="rejectedPieces"
                        placeholder="{{ 'manualDeliveryTags.rejectedPieces' | translate }}"
                        [required]="manualDeliveryForm.value.deliveryType === typeForm.partialDelivery || manualDeliveryForm.value.deliveryType === typeForm.rejection"
                        (keyup)="enableSaveEdition(manualDeliveryForm.value.rejectedPieces, typeForm.pieces)">
                      <mat-error>
                        {{ 'manualDeliveryTags.rejectedPieces' | translate }} <strong>{{ ('manualDeliveryTags.isRequired' | translate) | lowercase }}{{ getSuffix('manualDeliveryTags.suffixO' | translate) }}</strong>
                      </mat-error>
                    </mat-form-field>
                  </td>
                  <td class="rejection-table">
                    <strong>{{orderData.boxes ? orderData.boxes : 0}}</strong>
                  </td>
                  <td class="rejection-table">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        maxlength="10"
                        appNumberOnly
                        min="0"
                        formControlName="rejectedBoxes"
                        placeholder="{{ 'manualDeliveryTags.rejectedBoxes' |  translate }}"
                        [required]="manualDeliveryForm.value.deliveryType === typeForm.partialDelivery || manualDeliveryForm.value.deliveryType === typeForm.rejection"
                        (keyup)="enableSaveEdition(manualDeliveryForm.value.rejectedBoxes, typeForm.boxes)">
                      <mat-error>
                        {{ 'manualDeliveryTags.rejectedBoxes' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' | translate }}{{ getSuffix('manualDeliveryTags.suffixO' | translate) }}</strong>
                      </mat-error>
                    </mat-form-field>
                  </td>
                  <td class="rejection-table">
                    <strong>{{orderData.pallets ? orderData.pallets : 0}}</strong>
                  </td>
                  <td class="rejection-table">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        maxlength="10"
                        min="0"
                        decimal="true"
                        pattern="^\d+(\.\d{1,2})?$"
                        appNumberOnly
                        formControlName="rejectedPallets"
                        placeholder="{{ 'manualDeliveryTags.rejectedPallets' | translate }}"
                        [required]="manualDeliveryForm.value.deliveryType === typeForm.partialDelivery || manualDeliveryForm.value.deliveryType === typeForm.rejection"
                        (keyup)="enableSaveEdition(manualDeliveryForm.value.rejectedPallets, typeForm.pallets)">
                      <mat-error>
                        {{ 'manualDeliveryTags.rejectedPallets' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' | translate }}{{ getSuffix('manualDeliveryTags.suffixO' | translate) }}</strong>
                      </mat-error>
                    </mat-form-field>
                  </td>
                  <td>
                    <button mat-button
                      *ngIf="isRejectionEdit"
                      (click)="acceptRejectionMerchandise()"
                      [disabled]="isSaveEditionEnabled">
                      <mat-icon>check</mat-icon>
                    </button>
                    <button mat-button
                      *ngIf="!isRejectionEdit"
                      [disabled]="isCompleteRejection"
                      (click)="enableRejectionEdition()">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </td>
                </tr>
            </table>
            <mat-grid-list cols="3" rowHeight="1:.30">
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    formControlName="skus"
                    placeholder="{{ 'manualDeliveryTags.skus' | translate }}"
                    [required]="manualDeliveryForm.value.deliveryType === typeForm.partialDelivery || manualDeliveryForm.value.deliveryType === typeForm.rejection">
                  <mat-error>
                    {{ 'manualDeliveryTags.skus' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' | translate }}{{ getSuffix('manualDeliveryTags.suffixO' | translate) }}</strong>
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <mat-select
                  placeholder="{{ 'manualDeliveryTags.rejectionResponsible' | translate }}"
                  formControlName="rejectionResponsible"
                  [required]="manualDeliveryForm.value.deliveryType === typeForm.partialDelivery || manualDeliveryForm.value.deliveryType === typeForm.rejection"
                  disableOptionCentering="true">
                  <mat-option *ngFor="let rejectionResponsible of rejectionResponsibleList" [value]="rejectionResponsible">{{ rejectionResponsible.name }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ 'manualDeliveryTags.rejectionResponsible' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' |translate  }}{{ getSuffix('manualDeliveryTags.suffixO' | translate) }}</strong>
                </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-slide-toggle formControlName="warehouseMerchandise">
                  {{ 'manualDeliveryTags.warehouseMerchandise' | translate }}
                </mat-slide-toggle>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-header>
            <mat-card-title>
              <div class="add-file">
                <span>{{ 'manualDeliveryTags.deliveryFile' | translate }}</span>
                <button
                  (click)="clickFile()"
                  class="icon-button"
                  isIconButton="true">
                  <mat-icon class="white-icon" aria-label="Browse and add new files">add</mat-icon>
                </button>
                <input
                  type="file"
                  name="files"
                  id="inputFiles"
                  multiple
                  (change)="addFiles()"
                  hidden
                  accept=".jpg,.jpeg,.png">
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-grid-list cols="4" rowHeight="1:.30">
              <mat-grid-tile>
                <mat-form-field>
                  <mat-select
                    placeholder="{{ 'manualDeliveryTags.fileType' | translate }}"
                    formControlName="fileType"
                    required
                    (selectionChange)="resetFileReason()"
                    disableOptionCentering="true">
                    <mat-option *ngFor="let fileType of fileTypeList" [value]="fileType">{{ fileType }}</mat-option>
                  </mat-select>
                  <mat-error>
                    {{ 'manualDeliveryTags.fileType' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' | translate }}{{ getSuffix('manualDeliveryTags.suffixO' | translate) }}</strong>
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <mat-select
                    placeholder="{{ 'manualDeliveryTags.fileReason' | translate }}"
                    formControlName="fileReason"
                    required
                    disableOptionCentering="true">
                    <div *ngIf="manualDeliveryForm.value.fileType !== typeForm.incidence">
                      <mat-option *ngFor="let fileReason of evidenceReasons" [value]="fileReason">{{ fileReason.descripcion }}
                      </mat-option>
                    </div>
                    <div *ngIf="manualDeliveryForm.value.fileType === typeForm.incidence">
                      <mat-option *ngFor="let fileReason of incidenceReasons" [value]="fileReason">
                        {{ fileReason.descripcion }}
                      </mat-option>
                    </div>
                  </mat-select>
                  <mat-error>
                    {{ 'manualDeliveryTags.fileReason' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' | translate }}{{ getSuffix('manualDeliveryTags.suffixO' | translate) }}</strong>
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="{{ 'manualDeliveryTags.fileDescription' | translate }}"
                    formControlName="fileDescription"
                    required>
                  <mat-error>
                    {{ 'manualDeliveryTags.fileDescription' | translate }} <strong>{{ 'manualDeliveryTags.isRequired' | translate }}{{ getSuffix('manualDeliveryTags.suffixO' | translate) }}</strong>
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
            </mat-grid-list>
            <div class="padding-drop-zone">
              <div
                id="drop-zone"
                class="custom-dropzone scroll max-width-file"
                ngx-dropzone
                [accept]="'image/*'"
                (change)="addFilesByDrop($event)"
                (click)="openDisabled()"
                expandable="true">
                <ngx-dropzone-label>
                  <div class="dropzone-label">
                    <span>{{ 'manualDeliveryTags.dragAndDropLabelTitle' | translate }}</span><br>
                    <span class="subtitle-label">{{ 'manualDeliveryTags.dragAndDropLabelSubtitle' | translate }}</span><br>
                    <span class="subtitle-label">{{ 'manualDeliveryTags.dragAndDropLabelSubtitleFormat' | translate }}</span>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-image-preview
                  id="image-preview"
                  class="dropzone-image"
                  ngProjectAs="ngx-dropzone-preview"
                  *ngFor="let f of manualFiles; let i = index"
                  [file]="f.file"
                  removable=true
                  (removed)="removeFile(i)"
                  (click)="onFileClick(f.file)">
                  <ngx-dropzone-label *ngIf="f.file.type === 'application/pdf'">
                    <div class="dropzone-label">
                      <div class="label-pdf">
                        {{f.file.name}}
                      </div>
                      <img
                        id="default-pdf-image"
                        src="../../../../assets/pdf_Icon.png"
                        alt="PDF icon">
                    </div>
                  </ngx-dropzone-label>
                </ngx-dropzone-image-preview>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </form>
    </div>

    <app-subtitle-divider *ngIf="subtitleInfo" [info]="subtitleInfo"></app-subtitle-divider>
    <div class="visits-container" *ngIf="hasVisits">
      <app-delivery-detail
        (uploadFiles)="uploadByDetail($event)"
        *ngIf="infoDeliveryDetail.length"
        [infoDeliveryDetail]='infoDeliveryDetail'>
      </app-delivery-detail>
    </div>

    <div class="container-buttons">
      <mat-grid-list cols="2" rowsHeight="1:.10">
        <mat-grid-tile>
          <button
            mat-stroked-button
            color="primary"
            class="secondary-button align-right"
            (click)="onCancel()">
            {{ 'manualDeliveryTags.cancel' | translate }}
          </button>
        </mat-grid-tile>
        <mat-grid-tile>
          <button
            mat-raised-button
            color="primary"
            class="primary-button align-left"
            (click)="onSave()"
            [disabled]="!manualDeliveryForm.valid || isRejectionEdit">
            {{ 'manualDeliveryTags.save' | translate }}
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>
