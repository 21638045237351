import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import {
  RoadnetAuthenticationRequest,
  RoadnetItemLocation,
  RoadnetLocationsCreateBody,
  RoadnetLocationsCreateResponse,
  RoadnetOrderBody,
  RoadnetOrdersImportResponse,
  RoadnetRegionResponse,
  RoadnetShipmentBody,
  RoadnetToken
} from '../../interfaces/index';

const AUTHORIZATION_TYPE = 'Bearer ';
const DETAILS_EXPAND = '&expand=[Stops, StopTasks, WorkersInfo, EquipmentInfo, StopTimeWindowDetails]';
const KEY_AUTHORIZATION = 'Authorization';
const KEY_TOKEN = 'token';
const roadnetApiUrl = environment.roadnetApiUrl;

@Injectable()
export class RoadnetProvider {

  constructor(private http: HttpClient) { }

  public async getRoadnetToken(body: RoadnetAuthenticationRequest): Promise<RoadnetToken> {
    return await this.http.post<RoadnetToken>(roadnetApiUrl + '/login', body).toPromise();
  }

  public async createOrders(body: RoadnetOrderBody, authentication: RoadnetToken): Promise<RoadnetOrdersImportResponse> {
    const options = { headers: new HttpHeaders().append(KEY_AUTHORIZATION, AUTHORIZATION_TYPE + authentication[KEY_TOKEN]) };
    return await this.http.post<RoadnetOrdersImportResponse>(roadnetApiUrl + '/dailyplan/orders', body, options).toPromise();
  }

  /**
   * @description Sends locations to RNA to be created
   * @param body Object with an array of all locations to be created
   * @param authentication Object with info to authenticate in RNA
   * @returns Response with success and errors in location create
   */
  public async createLocations(body: RoadnetLocationsCreateBody, authentication: RoadnetToken): Promise<RoadnetLocationsCreateResponse> {
    const options = { headers: new HttpHeaders().append(KEY_AUTHORIZATION, AUTHORIZATION_TYPE + authentication[KEY_TOKEN]) };
    return await this.http.post<RoadnetLocationsCreateResponse>(`${roadnetApiUrl}/admin/locations`, body, options).toPromise();
  }

  public async getRoadnetOrdersInSession(sessionDate: string, authentication: RoadnetToken, pageToLook: number): Promise<RoadnetOrderBody> {
    const options = { headers: new HttpHeaders().append(KEY_AUTHORIZATION, AUTHORIZATION_TYPE + authentication[KEY_TOKEN]) };
    return await this.http.get<RoadnetOrderBody>(roadnetApiUrl + '/dailyplan/orders?sessionDate='
      + sessionDate + '&pageIndex=' + pageToLook, options).toPromise();
  }

  /**
   * @description Get the shipments in active plan according to parameters
   * @param sessionDate Date as a string to find as session in Roadnet
   * @param region Roadnet region to search
   * @param authentication Roadnet token authorization
   */
  public async getRoadnetShipmentsInSession(sessionDate: string, region: string, authentication: RoadnetToken):
  Promise<RoadnetShipmentBody> {
    const options = { headers: new HttpHeaders().append(KEY_AUTHORIZATION, AUTHORIZATION_TYPE + authentication[KEY_TOKEN]) };
    return await this.http.get<RoadnetShipmentBody>(roadnetApiUrl + '/dailyplan/routes?sessionDate=' + sessionDate + '&region=' + region +
    DETAILS_EXPAND, options).toPromise();
  }

  /**
   * @description Get all location data according to item found by entity key
   * @param entityKey Roadnet entity key associated to location
   * @param authentication Roadnet token authorization
   */
  public async getRoadnetLocationInfo(entityKey: number, authentication: RoadnetToken): Promise<RoadnetItemLocation> {
    const options = { headers: new HttpHeaders().append(KEY_AUTHORIZATION, AUTHORIZATION_TYPE + authentication[KEY_TOKEN]) };
    return await this.http.get<RoadnetItemLocation>(roadnetApiUrl + '/admin/locations/' + entityKey, options).toPromise();
  }

  /**
   * @description Get regions availables according to Roadnet user
   * @param authentication Roadnet token authorization
   */
  public async getRoadnetUserRegions(authentication: RoadnetToken): Promise<RoadnetRegionResponse> {
    const options = { headers: new HttpHeaders().append(KEY_AUTHORIZATION, AUTHORIZATION_TYPE + authentication[KEY_TOKEN]) };
    return await this.http.get<RoadnetRegionResponse>(roadnetApiUrl + '/admin/regions', options).toPromise();
  }

  /**
   * @description Get order data found by identifier in Roadnet
   * @param authentication Roadnet token authorization
   * @param identifier The identifier to find in Roadnet
   */
  public async getRoadnetOrderByIdentifier(authentication: RoadnetToken, identifier: string): Promise<RoadnetOrderBody> {
    const options = { headers: new HttpHeaders().append(KEY_AUTHORIZATION, AUTHORIZATION_TYPE + authentication[KEY_TOKEN]) };
    return await this.http.get<RoadnetOrderBody>(roadnetApiUrl + '/dailyplan/orders?identifiers=' + identifier, options).toPromise();
  }

  /**
   * @description Delete an order in Roadnet by entityKey
   * @param authentication Roadnet token authorization
   * @param orderToDelete The entityKey to delete in Roadnet
   */
  public async deleteRoadnetOrder(authentication: RoadnetToken, orderToDelete: string): Promise<void> {
    const options = { headers: new HttpHeaders().append(KEY_AUTHORIZATION, AUTHORIZATION_TYPE + authentication[KEY_TOKEN]) };
    return await this.http.delete<any>(roadnetApiUrl + '/dailyplan/orders/' + orderToDelete, options).toPromise();
  }

  /**
    * @description Delete a route in Roadnet by entityKey
    * @param authentication Roadnet token authorization
    * @param routeToDelete The entityKey to delete in Roadnet
    */
  public async deleteRoadnetShipment(authentication: RoadnetToken, routeToDelete: number): Promise<void> {
    const options = { headers: new HttpHeaders().append(KEY_AUTHORIZATION, AUTHORIZATION_TYPE + authentication[KEY_TOKEN]) };
    return await this.http.delete<any>(roadnetApiUrl + '/dailyplan/routes/' + routeToDelete, options).toPromise();
  }
}
