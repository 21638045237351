import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Account } from './../../../interfaces/account';
import { ConfigurationProvider } from 'src/app/providers/configuration/configuration.provider.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ShipperConfiguration } from '../../../interfaces/index';
import { LoadPlanProperties } from './load-plan.properties';
import { OrderProvider } from '../../../providers/orders/order-provider.service';
import { OrderForShipment } from './../../../interfaces/shipment';
import { ShipmentRowExtended } from '../../../interfaces';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import getAverageColor from 'get-average-color';
import { find as _find } from 'lodash';

const CODE128PROPERTY = 'CODE128';
const DEFAULTIMAGE = '../../../assets/biis.png';
const DESTINATIONPROPERTY = 'destination';
const LOADPLANCOLUMNS = ['folioId', 'orderId', 'invoice', 'deliveryDate', 'totalPallets', 'totalBoxes', 'totalPieces'];
const MAX_ORDERS_PER_PAGE = 16;
const NAMEPROPERTY = 'name';
const PLANNEDWAREHOUSEPROPERTY = 'plannedWarehouse';
const PORTAGETRIPTYPES = ['Porteo', 'Porteo con reparto'];
const SVGPROPERTY = 'svg';
const TITLECONTAINER = 'title-container';
const ZERO = 0;

@Component({
  selector: 'app-load-plan',
  templateUrl: './load-plan.component.html',
  styleUrls: ['./load-plan.component.scss', '../../../app.component.scss']
})
export class LoadPlanComponent implements OnInit, OnDestroy, OnChanges {

  @Input() shipmentData: ShipmentRowExtended;

  public codeProperty: string;
  public dataSource: MatTableDataSource<Array<object>>;
  public displayedColumns: Array<string>;
  public languageSuscription: Subscription;
  public languageLabels: any;
  public dividedStops: Array<Array<object>>;
  public logoUrl: string;
  public orderObjectsId: Array<string>;
  public orders: Array<object>;
  public ordersInShipment: Array<Array<object>>;
  public ordersPerStop: object;
  public shipmentUsedAccounts: Array<Account>;
  public shipperConfig: ShipperConfiguration;
  public stops: Array<object>;
  public svgProperty: string;
  public totalBoxes: number;
  public totalPallets: number;
  public totalPieces: number;

  constructor(
    private configProvider: ConfigurationProvider,
    private orderProvider: OrderProvider,
    private toast: ToastrAlertsService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService
  ) {
    this.setLanguage();
  }

  /**
   * @description Angular destroy lifecycle
   */
  public ngOnDestroy() {
    this.languageSuscription?.unsubscribe();
  }

  /**
   * @description Angular lifecycle for component initialization
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    this.logoUrl = await this.configProvider.getShipperLogo();
    this.svgProperty = SVGPROPERTY;
    this.codeProperty = CODE128PROPERTY;
    this.setBarDividerColor();
    this.displayedColumns = LOADPLANCOLUMNS;
    this.stops = [];
    this.orders = [];
    this.ordersInShipment = [];
    this.orderObjectsId = [];
    this.setOrdersPerStop();
    this.setShipmentAccounts();
  }

  ngOnChanges() {
    this.setBarDividerColor();
    this.stops = [];
    this.orders = [];
    this.ordersInShipment = [];
    this.orderObjectsId = [];
    this.calculateTotals();
    this.setShipmentAccounts();
  }

  /**
   * @description Sets all current shipment accounts that has at least one order
   */
  public setShipmentAccounts() {
    const usedAccounts = [];
    if (this.shipmentData) {
      let ordersAccounts = _.map(this.shipmentData.orders, (order: OrderForShipment) => {
        return order.account._id;
      });
      ordersAccounts = _.uniq(ordersAccounts);
      ordersAccounts.forEach((orderAccountId: string) => {
        const foundAccount = _.find(this.shipmentData.account, (account: Account) => {
          return account._id === orderAccountId;
        });
        if (foundAccount) {
          usedAccounts.push(foundAccount);
        }
      });
    }
    this.shipmentUsedAccounts = usedAccounts;
  }

  public async setOrdersPerStop(): Promise<void> {
    this.ordersPerStop = _.groupBy(this.shipmentData.orders, order => {
      return order.stop;
    });

    const ordersIds = { ordersIds: this.shipmentData.orders.map(order => order._id) };
    const retrievedOrders = await this.orderProvider.getOrderByOids(ordersIds);
    for (const stop in this.ordersPerStop) {
      if (this.ordersPerStop) {
        this.orders = [];
        this.ordersPerStop[stop].forEach(order => {
          const invoice = (_find(retrievedOrders, (retrievedOrder) => order._id === retrievedOrder._id))?.invoice;
          const orderAux = {
            appointmentHour: order.appointmentHour,
            folioId: order.folio,
            orderId: order.orderId,
            deliveryDate: order.deliveryDate,
            invoice: invoice,
            totalPallets: order.pallets,
            totalBoxes: order.boxes,
            totalPieces: order.pieces
          };
          this.orders.push(orderAux);
        });
        this.ordersInShipment.push(this.orders);
      }
    }
    this.setStops();
    this.dataSource = new MatTableDataSource(this.ordersInShipment);
  }

  public setStops(): void {
    for (const stop in this.ordersPerStop) {
      if (this.ordersPerStop) {
        const stopAux = {
          stop: this.ordersPerStop[stop][0].stop,
          address: _.includes(PORTAGETRIPTYPES, this.shipmentData.tripType) ?
            this.setPortageWarehouseName(this.ordersPerStop[stop][0]) : this.ordersPerStop[stop][0].destination.name
        };
        this.stops.push(stopAux);
      }
    }
    _.reverse(this.stops);
    _.reverse(this.ordersInShipment);
    const newOrders = [];
    this.dividedStops = [];
    if (this.stops.length > MAX_ORDERS_PER_PAGE) {
      for (let i = 0; i < this.stops.length; i += MAX_ORDERS_PER_PAGE) {
        const auxStops = this.stops.slice(i, i + MAX_ORDERS_PER_PAGE);
        const auxOrders = this.ordersInShipment.slice(i, i + MAX_ORDERS_PER_PAGE);
        newOrders.push(auxOrders);
        this.dividedStops.push(auxStops);
      }
    } else {
      newOrders.push(this.ordersInShipment);
      this.dividedStops.push(this.stops);
    }
    this.ordersInShipment = newOrders;

  }

  public setDefaultImage(event): void {
    event.target.src = DEFAULTIMAGE;
    document.getElementById(TITLECONTAINER).style.borderBottom = LoadPlanProperties.borderLinePropertiesWithColor;
  }

  public async setBarDividerColor() {
    await getAverageColor(this.logoUrl)
      .then(rgb => {
        const barColor = 'rgb(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ')';
        document.getElementById(TITLECONTAINER).style.borderBottom = LoadPlanProperties.borderLinePropertiesWithoutColor + barColor;
      }
        , error => console.log(error)
      );
  }

  /**
   * @description Returns the properly name for stop depending on if stop has plannedWarehouse or not
   * @param order The element to get planned warehouse if exists
   * @returns {string} The properly name for the stop
   */
  public setPortageWarehouseName(order: Object): string {
    if (order[PLANNEDWAREHOUSEPROPERTY]) {
      return order[PLANNEDWAREHOUSEPROPERTY][NAMEPROPERTY];
    } else {
      return order[DESTINATIONPROPERTY][NAMEPROPERTY];
    }
  }

  /**
   * @description It calculates the total of quantities in case that the totals of the shipment are incorrect
   */
  public calculateTotals(): void {
    const orders = this.shipmentData.orders;
    let auxBoxes = 0;
    let auxPallets = 0;
    let auxPieces = 0;

    for (const order of orders) {
      auxBoxes += order.boxes ?? ZERO;
      auxPieces += order.pieces ?? ZERO;
      auxPallets += order.pallets ?? ZERO;
    }
    this.totalBoxes = auxBoxes;
    this.totalPieces = auxPieces;
    this.totalPallets = auxPallets;
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
      },
      (error) => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }
}
