import { Injectable } from '@angular/core';

import { Subscription } from 'rxjs';
import { ToastrService, ActiveToast } from 'ngx-toastr';

import { IToastrAlertsServiceLabels } from '../../interfaces/labels/toastr-alerts-service-labels.interface';
import { LanguageChangeEventService } from '../translate/language-change-event.service';
import { LanguageConstants } from '../../constants/language.constants';
import { LanguageTranslateService } from '../translate/language-translate.service';

@Injectable()
export class ToastrAlertsService {
  public languageSubscription: Subscription;
  public toasterid: number;
  public toastrAlertsServiceLabels: IToastrAlertsServiceLabels;
  public toastRef: ActiveToast<ToastrService>;

  constructor(
    private toastr: ToastrService,
    public _languageChangeEventService: LanguageChangeEventService,
    public _languageTranslateService: LanguageTranslateService
  ) {
    this.subscribeLanguageChangeEvent();
    this.getToastrAlertsServiceLabels();
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSubscription = this._languageChangeEventService._languageEmitter.subscribe(
      async () => {
        await this.getToastrAlertsServiceLabels();
      },
      () => {});
  }

  /**
   * @description Gets Toastr Alerts Service Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getToastrAlertsServiceLabels(): Promise<IToastrAlertsServiceLabels> {
    this.toastrAlertsServiceLabels = await this._languageTranslateService
    .getLanguageLabels(LanguageConstants.TOASTR_ALERTS_SERVICE_LABELS)
    .catch(() => {});
    return this.toastrAlertsServiceLabels;
  }

  public successAlert(message: string, title = this.toastrAlertsServiceLabels.done): void {
    this.toastr.success(message, title);
  }

  public errorAlert(message: string, title = this.toastrAlertsServiceLabels.error): void {
    this.toastr.error(message, title);
  }

  public infoAlert(message: string, title = this.toastrAlertsServiceLabels.information): void {
    this.toastr.info(message, title);
  }

  public warningAlert(message: string, title = this.toastrAlertsServiceLabels.attention): void {
    this.toastr.warning(message, title);
  }

  /**
   * @description It displays an alert with an effect of processing data.
   * Important: It needs to be closed with the closeProcessing()
   * @param message A default message that can be overwritten
   * @param title A default title that can be overwritten
   */
  public processingAlert(message: string = this.toastrAlertsServiceLabels.processingAlert,
    title: string = this.toastrAlertsServiceLabels.processingTitle): number {
    const messageHtml = '<div class=\"toast--loading\">' + message + '<span>.</span><span>.</span><span>.</span></div>';
    this.toastRef = this.toastr.info(messageHtml, title, {
      disableTimeOut: true,
      tapToDismiss: false
    });

    return this.toastRef.toastId;
  }

  /**
   * @description Closes processing toastr by toastId given or if undefined closes latest created toast
   * @param { number } toastId ToastId to close * Optional parameter
   */
  public closeProcessing(toastId?: number) {
    if (toastId) {
      this.toastr.clear(toastId);
    } else {
      this.toastr.clear(this.toastRef.toastId);
    }
  }
}
