import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { isEmpty as _isEmpty, isUndefined as _isUndefined, first as _first } from 'lodash';
import { Subscription } from 'rxjs';

import { DialogFilePreviewComponent } from '../dialog/dialog-file-preview/dialog-file-preview.component';
import { DragAndDropDialogProperties } from './drag-and-drop.properties';
import { DragAndDropFile } from '../../interfaces/index';
import { IDragAndDropLabels } from '../../interfaces/labels/drag-and-drop-labels.interface';
import { ILanguageLabels } from '../../interfaces/labels/language-labels.interface';
import { LanguageChangeEventService } from '../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../constants/language.constants';
import { LanguageTranslateService } from '../../services/translate/language-translate.service';
import { Receiver } from '../../interfaces/receiver/receiver.interface';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';

const DEFAULT_ALLOWED_EXTENSION = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
const MAXPDFFILESIZE = 1024000;
const MAX_IMAGE_SIZE = 1024000;
const PDFFILETYPE = 'application/pdf';

const MAXIMUM_FILES_PERMITTED = 30;
const LOAD_KEY = 'load';
const COMMAN_SEPARATOR = ',';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent implements OnInit, OnChanges {
  @Input() allowedFormatExtensions: Array<string>;
  @Input() dataToEdit: Receiver;
  @Input() filesAddedByButton: FileList;
  @Input() filesInDropZone: Array<DragAndDropFile>;
  @Input() isEdit: boolean;
  @Input() label: string;
  @Input() maxFilesAllowed: number;
  @Input() maxImageResolution: { width: number, height: number };
  @Input() multiple: boolean;
  @Input() previewTitle: string;
  @Input() validateImageResolution: boolean;

  @Output() filesDropped = new EventEmitter<Array<DragAndDropFile>>();
  @Output() isFilesChanged = new EventEmitter<boolean>();

  public allowedExtension: string;
  public dragAndDropLabels: IDragAndDropLabels;
  public filesInitial: Array<DragAndDropFile>;
  public hasValidSize: boolean;
  public invalidFiles: Array<string>;
  public isReadOnly: boolean;
  public isValidFile: boolean;
  public languageLabels: ILanguageLabels;
  public languageSuscription: Subscription;

  constructor(
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    private dialog: MatDialog,
    private toast: ToastrAlertsService
  ) {
    this.setLanguage();
  }

  public async ngOnInit() {
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
    this.filesInitial = this.filesInDropZone;
    this.hasValidSize = false;
    if (_isUndefined(this.filesInDropZone)) {
      this.filesInDropZone = [];
    }
    this.configComponent();
  }

  async ngOnChanges() {
    if (!(_isUndefined(this.filesAddedByButton))) {
      this.addFilesByButton(this.filesAddedByButton);
    }
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
    this.configComponent();
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @return {void}
   */
   public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component
   * @return {void}
   */
  public async getLabels(): Promise<void> {
    await this.getLanguageLabels();
    await this.getDragAndDropLabels();
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
   public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getLabels();
      },
      () => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language labels from translate JSON files.
   * @return {Promise<void>}
   */
   public async getLanguageLabels(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets Drag and Drop Labels from translate JSON files.
   * @return {Promise<void>}
   */
   public async getDragAndDropLabels(): Promise<void> {
    this.dragAndDropLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.DRAG_AND_DROP_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Push files dropped in drag and drop area and emmit the filesInDropZone array
   * @param event The event when a files is dropped
   */
  public async addFilesByDrop(event) {
    if (this.filesInDropZone.length < this.maxFilesAllowed) {
      if (this.isReadOnly) {
        this.toast.warningAlert(this.dragAndDropLabels.dragAndDropNotAllow);
      } else {
        this.invalidFiles = [];
        for (const file of event.addedFiles) {
          this.isValidFile = false;
          for (let i = 0; i < this.allowedFormatExtensions.length; i++) {
            if (file.type === this.allowedFormatExtensions[i]) {
              if (file.type === PDFFILETYPE) {
                if (file.size > MAXPDFFILESIZE) {
                  this.toast.warningAlert(this.dragAndDropLabels.invalidSize + this.invalidFiles);
                  return;
                }
              } else {
                if (file.size > MAX_IMAGE_SIZE) {
                  this.toast.errorAlert(this.dragAndDropLabels.invalidFileSize);
                  return;
                } else if (this.maxImageResolution) {
                  const imageResult = await this.getFileAsBase64String(file);
                  const hasValidResolution = await this.checkDimension(imageResult);
                  if (!hasValidResolution) {
                    this.toast.warningAlert(this.dragAndDropLabels.invalidImageResolution);
                    return;
                  }
                }
              }
              this.isValidFile = true;
              const files = {
                file: file,
                name: file.name,
                isMobilityEvidence: false
              };
              this.filesInDropZone.push(files);
            }
          }
          if (!this.isValidFile) {
            this.invalidFiles.push(file.name);
          }
        }
        if (!_isEmpty(this.invalidFiles)) {
          this.toast.warningAlert(this.dragAndDropLabels.invalidFile + this.invalidFiles);
        }
      }
      this.filesDropped.emit(this.filesInDropZone);
    } else {
      this.toast.warningAlert(this.dragAndDropLabels.maximumFilesReached);
    }
  }

  /**
   * @description Removes a files in drag and drop area and emit the filesInDropZone array
   * @param index The index number of the file to delete
   */
  public removeFile(index: number): void {
    if (index >= 0) {
      this.filesInDropZone.splice(index, 1);
    }
    this.filesDropped.emit(this.filesInDropZone);
  }

  /**
   * @description Functionality to not allow clicking inside the drag and drop area
   * @returns {boolean} Flag in order to not allow click inside drag and drop
   */
  public openDisabled(): boolean {
    return false;
  }

  /**
   * Display a mat- dialog with the file selected
   * @param file File clicked to view
   */
  public onFileClick(file: File): void {
    if (file) {
      this.dialog.open(DialogFilePreviewComponent, {
        data: {
          title: this.previewTitle ?
            this.dragAndDropLabels.dialogFilePreviewTitle + this.previewTitle :
            this.dragAndDropLabels.dialogFileDefaultPreviewTitle,
          file: file
        },
        width: DragAndDropDialogProperties.dialogFilePreviewWidth
      });
    }
  }

  /**
   * @description Function to add images by clicking a button, this function is external to be apply in a button
   * it's necessary to use it with this component
   */
  public async addFilesByButton(file: FileList) {

    if (this.filesInDropZone.length < this.maxFilesAllowed) {
      this.invalidFiles = [];
      let fileSizes = 0;
      for (let i = 0; i < file.length; i++) {
        this.isValidFile = false;
        const fileInput = file[i];
        for (let k = 0; k < this.allowedFormatExtensions.length; k++) {
          if (fileInput.type === this.allowedFormatExtensions[k]) {
            if (fileInput.type === PDFFILETYPE) {
              if (fileInput.size > MAXPDFFILESIZE) {
                this.toast.errorAlert(this.dragAndDropLabels.invalidSize + this.invalidFiles);
                return;
              }
            } else {
              if (fileInput.size > MAX_IMAGE_SIZE) {
                this.toast.errorAlert(this.dragAndDropLabels.invalidFileSize);
                return;
              } else if (this.maxImageResolution) {
                const imageResult = await this.getFileAsBase64String(_first(file));
                const hasValidResolution = await this.checkDimension(imageResult);
                if (!hasValidResolution) {
                  this.toast.warningAlert(this.dragAndDropLabels.invalidImageResolution);
                  return;
                }
              }
            }
            this.isValidFile = true;
            this.filesInDropZone.push({ file: file[i], name: file[i].name, isMobilityEvidence: false });
          }
        }
        if (!this.isValidFile) {
          this.invalidFiles.push(fileInput.name);
        }
      }

      for (let j = 0; j < file.length; j++) {
        fileSizes = file[j].size + fileSizes;
      }
      if (!_isEmpty(this.invalidFiles)) {
        this.toast.warningAlert(this.dragAndDropLabels.invalidFile + this.invalidFiles);
      }

      this.filesDropped.emit(this.filesInDropZone);
    } else {
      this.toast.warningAlert(this.dragAndDropLabels.maximumFilesReached);
    }

  }

  /**
   * @description Gets the image file as base64 string reperesentation
   * @param {File} file
   * @returns {Promise<string | ArrayBuffer>} Image base64 string
   */
  public getFileAsBase64String(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  /**
   * @description Checks for valid image resolution given by config
   * @param {string | ArrayBuffer} base64 Base64 string image representation
   * @returns {Promise<boolean>} Whether image has valid resolution
   */
  public checkDimension(base64: string | ArrayBuffer): Promise<boolean> {
    return new Promise((resolve) => {
      let hasValidSize = false;
      const img = new Image();
      img.src = base64 as string;
      img.addEventListener(LOAD_KEY, () => {
        if (img.width > 0 && img.width <= this.maxImageResolution.width
          && img.height > 0 && img.height <= this.maxImageResolution.height) {
          hasValidSize = true;
        } else {
          hasValidSize = false;
        }
        resolve(hasValidSize);
      });
    });
  }

  /**
   * @description Sets the config given by the input variables
   */
  public configComponent(): void {
    this.multiple = this.multiple ? this.multiple : false;
    this.allowedExtension = this.allowedFormatExtensions ? this.allowedFormatExtensions.join(COMMAN_SEPARATOR) :
      DEFAULT_ALLOWED_EXTENSION.join(COMMAN_SEPARATOR);
    this.maxFilesAllowed = this.maxFilesAllowed ? this.maxFilesAllowed : MAXIMUM_FILES_PERMITTED;
    this.label = this.label ? this.label : this.dragAndDropLabels.dragAndDropLabelSubtitleFormat;
    this.maxImageResolution = this.maxImageResolution ? this.maxImageResolution : undefined;
  }

}
