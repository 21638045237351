export enum OrderStatus {
  Assigned = 'Asignado',
  Delivered = 'Entregado',
  NoAssigned = 'No Asignado',
  NoEvidence = 'Sin Evidencia',
  OnDelivery = 'En Transito',
  Partial = 'Parcial',
  Released = 'Liberada',
  Rejected = 'Rechazado',
  Undelivered = 'No Entregado'
}

export enum OrderApiStatus {
  Assigned = 'Asignada',
  Delivered = 'Entregada',
  NotAssigned = 'Sin Asignar',
  OnDelivery = 'En Transito',
  Partial = 'Parcial',
  Rejected = 'Rechazada',
  Undelivered = 'No Entregada'
}

export const OrderStatusAndLabel = [
  { value: 'En Tránsito', label: 'OnDelivery' },
  { value: 'Entregada', label: 'Delivered' },
  { value: 'Asignada', label: 'Assigned' },
  { value: 'Sin Asignar', label: 'Unassigned' },
  { value: 'No Entregada', label: 'Undelivered' },
  { value: 'Liberada', label: 'Released' },
  { value: 'Rechazada', label: 'Rejected' },
  { value: 'Parcial', label: 'Partial' },
  { value: 'Cancelada', label: 'Cancelled' },
  { value: 'Sin Evidencia', label: 'NoEvidence' }
];
