import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { GenericRegexp } from './../../regexp/generic.regexp';
import { MultipleInputObject } from '../../interfaces/shipment';

const MINIMAL_THEME = 'minimal';

@Component({
  selector: 'app-multiple-input-search',
  templateUrl: './multiple-input-search.component.html',
  styleUrls: ['./multiple-input-search.component.scss']
})
export class MultipleInputSearchComponent implements OnInit {

  @Output() public inputElements: EventEmitter<Array<string>>;
  @Input() public elements: Array<string>;

  public splitPattern: RegExp;
  public tagsInput = [];
  public theme: string;

  constructor() {
    this.inputElements = new EventEmitter<Array<string>>();
  }

  ngOnInit(): void {
    this.theme = MINIMAL_THEME;
    this.splitPattern = new RegExp(GenericRegexp.MULTIPLE_SEARCH_SPLIT);
    this.tagsInput = this.elements ? this.elements : [];
  }

  /**
   * @description Split items when a new tag is added to the array
   * @param {MultipleInputObject} item New input that was submitted
   */
  public onAdd(item: MultipleInputObject): void {
    const newItems = item.value.split(this.splitPattern);
    const toAddMultiple = newItems.length > 1;
    if (toAddMultiple) {
      newItems.forEach(newItem => {
        const newObject = {display: newItem, value: newItem};
        if (!this.containsObject(newObject, this.tagsInput)) {
          this.tagsInput.push(newObject);
        }
      });
      for (let tag = 0; tag < this.tagsInput.length; tag++) {
        const currentTag = this.tagsInput[tag];
        if (currentTag.value === item.value) {
          this.tagsInput.splice(tag, 1);
          break;
        }
      }
    }
    this.inputElements.emit(this.tagsInput);
  }

  /**
   * @description Update tags when item is removed from input box
   */
  public onRemove(): void {
    this.inputElements.emit(this.tagsInput);
  }

  /**
   * @description Checks if the object has the new tag
   * @param {MultipleInputObject} obj A single tag object from array
   * @returns Boolean if it contains the input
   */
  public containsObject(obj: MultipleInputObject, list: Array<MultipleInputObject>): boolean {
    for (let index = 0; index < list.length; index++) {
        if (list[index].value === obj.value) {
          return true;
        }
    }
    return false;
  }

  /**
   * @description Deletes all tags from input box
   */
  public deleteItems(): void {
    this.tagsInput = [];
    this.inputElements.emit(this.tagsInput);
  }
}
