<button
  id="star"
  mat-icon-button
  *ngFor="let ratingId of ratingArr; index as i"
  [id]="'star_' + i"
  [matTooltip]="ratingId + 1"
  matTooltipPosition="above">
  <mat-icon>{{ showIcon(i) }}</mat-icon>
</button>
<mat-error *ngIf="starCount == null || starCount == 0">
  {{ label.starRequired }}
</mat-error>
