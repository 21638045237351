import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogStandardFocus } from './../../../enums/dialog-stantdard-focus';

@Component({
  selector: 'app-dialog-revert-failed-stamp',
  templateUrl: './dialog-revert-failed-stamp.component.html',
  styleUrls: ['./dialog-revert-failed-stamp.component.scss', '../../../app.component.scss']
})
export class DialogRevertFailedStampComponent implements OnInit {
  revertReasonFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogRevertFailedStampComponent>,
    private readonly builder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initForm(this.builder);
  }

  public initForm(formBuilder: FormBuilder) {
    this.revertReasonFormGroup = formBuilder.group({
      revertReasonInput: new FormControl(null, [Validators.required, Validators.minLength(3)])
    });
  }

  public onClickCancel(): void {
    this.dialogRef.close(DialogStandardFocus.CANCEL);
  }

  public onClickConfirm(): void {
    const result = {
      revertReason: this.revertReasonFormGroup.controls.revertReasonInput.value,
      dialogResult: DialogStandardFocus.CONFIRM
    };
    this.dialogRef.close(result);
  }

  public onClickClose(): void {
    this.dialogRef.close(DialogStandardFocus.CLOSED);
  }
}
