import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Coords } from '../../interfaces/map';
import {
  DialogAssociateShipmentComponent
} from '../../components/dialog/dialog-marketplace/dialog-associate-shipment/dialog-associate-shipment.component';
import { DialogFilePreviewComponent } from '../dialog/dialog-file-preview/dialog-file-preview.component';
import {
  DialogQuoteBiisShipmentComponent
} from '../../components/dialog/dialog-marketplace/dialog-quote-biis-shipment/dialog-quote-biis-shipment.component';
import {
  DialogShipmentRequestDetailsComponent
} from '../../components/dialog/dialog-marketplace/dialog-shipment-request-details/dialog-shipment-request-details.component';
import { IShipmentCardInfoLabels, ShipmentCardInfoLabels } from './shipment-card.labels';
import { IShipmentCardInfoProperties,
  ShipmentCardInfoProperties,
  ShipmentRequestFormatDialogProperties,
  VALID_STATUS_SHIPMENT_REQUEST_VIEW_OFFERS
} from './shipment-card.properties';
import { MARKETPLACE_SHIPPER_CONSTANTS } from '../../constants/marketplace-shipper/marketplace-shipper.constants';
import { MPShipmentRequestStatus } from '../../enums/MPShipmentRequestStatus';
import { ShipmentCardRequestProperties } from './shipment-card.properties';
import { ShipmentRequest } from '../../interfaces/marketplace-shipper/shipment-request';
import { ShipmentRequestResponse } from '../../interfaces/marketplace-shipper/shipment-request';
import { SHIPMENT_REQUEST_STATUS } from '../../pages/shipper-shipment-request/shipper-shipment-request.labels';
import { SHIPMENT_REQUEST_STATUS_BIIS,
  TAB_CUSTOMER_SHIPMENT
} from '../../pages/customer-shipment-request/customer-shipment-request.labels';

import { Subscription } from 'rxjs';
import * as moment from 'moment';

const LIMITDELIVELY = 1;
const MIN_QUOTE = 0;
@Component({
  selector: 'app-shipment-card-info',
  templateUrl: './shipment-card-info.component.html',
  styleUrls: ['./shipment-card-info.component.scss', '../../app.component.scss']
})
export class ShipmentCardInfoComponent implements OnInit {
  @Input() public isBiisPerson: boolean;
  @Input() public shipmentRequests: ShipmentRequestResponse;
  @Input() public userCoordinates?: Coords;
  @Output() captureQuote = new EventEmitter<number>();
  @Output() public shipmentSelected: EventEmitter<ShipmentRequest>;
  @Output() refreshShipment = new EventEmitter<boolean>();

  public affiliateShipmentIcon: string;
  public boxIcon: string;
  public dateFormat: string;
  public dataAvailable: boolean;
  public groupIcon: string;
  public labels: IShipmentCardInfoLabels;
  public modalSub: Subscription;
  public moneyIcon: string;
  public mpShipmentRequestStatus = MPShipmentRequestStatus;
  public numberFormat: string;
  public numberIcon: string;
  public personIcon: string;
  public phoneIcon: string;
  public pinIcon: string;
  public plateIcon: string;
  public properties: IShipmentCardInfoProperties;
  public region: string;
  public searchIcon: string;
  public shipmentIcon: string;
  public tracingIcon: string;
  public vehicleIcon: string;
  public weigthIcon: string;

  constructor(public dialog: MatDialog) {
    this.boxIcon = ShipmentCardRequestProperties.box;
    this.dateFormat = MARKETPLACE_SHIPPER_CONSTANTS.DATE_FORMAT;
    this.groupIcon = ShipmentCardRequestProperties.group;
    this.numberFormat = MARKETPLACE_SHIPPER_CONSTANTS.NUMBER_FORMAT;
    this.numberIcon = ShipmentCardRequestProperties.number;
    this.labels = ShipmentCardInfoLabels;
    this.moneyIcon = ShipmentCardRequestProperties.money;
    this.affiliateShipmentIcon = ShipmentCardRequestProperties.affiliateShipment;
    this.personIcon = ShipmentCardRequestProperties.person;
    this.pinIcon = ShipmentCardRequestProperties.pin;
    this.plateIcon = ShipmentCardRequestProperties.plate;
    this.properties = ShipmentCardInfoProperties;
    this.phoneIcon = ShipmentCardRequestProperties.phone;
    this.region = MARKETPLACE_SHIPPER_CONSTANTS.REGION;
    this.searchIcon = ShipmentCardRequestProperties.search;
    this.shipmentIcon = ShipmentCardRequestProperties.shipment;
    this.tracingIcon = ShipmentCardRequestProperties.tracing;
    this.vehicleIcon = ShipmentCardRequestProperties.vehicle;
    this.weigthIcon = ShipmentCardRequestProperties.weigth;
    this.shipmentSelected = new EventEmitter<ShipmentRequest>();
  }

  /**
   * @description Fires when initialize component
   */
  public async ngOnInit(): Promise<void> {
  }

  /**
   * @description This function format the date
   * @param {Date} date The date received from the shipment request
   * @returns {string} The date formated
   */
  public getDate(date: Date): string {
    return moment(date, MARKETPLACE_SHIPPER_CONSTANTS.MOMENT_FORMAT).format(this.dateFormat);
  }

  /**
   * @description Determinated if destination apply
   * @param {number} value The value received from the shipment request destinations array
   * @returns {string} The value display in view
   */
  public applyDestination(value: number): string {
    return value <= LIMITDELIVELY ? this.labels.noApply : value.toString();
  }

  /**
   * @description Get class name of dot by status or offers number of shipment request
   * @param {ShipmentRequestResponse} shipmentData info from shipment request selected
   * @returns {string} The class name of css from status of shipment
   */
  public getClassDot(shipmentData: ShipmentRequestResponse): string {
    if (this.isBiisPerson) {
      const shipmentStatusBiis =
      SHIPMENT_REQUEST_STATUS_BIIS.find(shipment => shipment.status.toLocaleLowerCase() === shipment.status?.toLocaleLowerCase());
      if (!shipmentStatusBiis) {
        const defaultStatus =
        SHIPMENT_REQUEST_STATUS_BIIS.find(shipment => shipment.tabIndex === TAB_CUSTOMER_SHIPMENT.everything);
        return defaultStatus.cssClass;
      }
      return shipmentStatusBiis.cssClass;
    }
    if (((shipmentData.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED ||
          shipmentData.status === MARKETPLACE_SHIPPER_CONSTANTS.COUNTER_OFFER_STATUS) && (shipmentData.offers?.length > 1))) {
      const shipmentStatus = SHIPMENT_REQUEST_STATUS.find(shipment =>
                             shipment.status === MARKETPLACE_SHIPPER_CONSTANTS.MULTI_OFFER_STATUS);
      return shipmentStatus?.cssClass;
    } else if (shipmentData.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED && !shipmentData.offers) {
      const shipmentStatus = SHIPMENT_REQUEST_STATUS.find(shipment =>
        shipment.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED);
      return shipmentStatus?.cssClass;
    } else if (shipmentData.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED && shipmentData.offers?.length === 1) {
      const shipmentStatus = SHIPMENT_REQUEST_STATUS.find(shipment =>
        shipment.status === MARKETPLACE_SHIPPER_CONSTANTS.ONE_OFFER_STATUS);
      return shipmentStatus?.cssClass;
    } else {
      const shipmentStatus = SHIPMENT_REQUEST_STATUS.find(shipment =>
        shipment.status.toLocaleLowerCase() === shipmentData.status.toLocaleLowerCase());
      return shipmentStatus?.cssClass;
    }
  }

  /**
   * @description Get tooltip by status shipment request
   * @param {ShipmentRequestResponse} shipmentInfo Data from shipment request
   * @returns {string} status from de request
   */
  public getTooltip(shipmentInfo: ShipmentRequestResponse): string {
    if (this.isBiisPerson) {
      const shipmentStatusBiis =
      SHIPMENT_REQUEST_STATUS_BIIS.find(shipment => shipment.status.toLocaleLowerCase() === shipmentInfo.status.toLocaleLowerCase());
      if (!shipmentStatusBiis) {
        return this.labels.noInfo;
      }
      return shipmentStatusBiis.description;
    }
    if (((shipmentInfo.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED ||
          shipmentInfo.status === MARKETPLACE_SHIPPER_CONSTANTS.COUNTER_OFFER_STATUS) && (shipmentInfo.offers?.length > 1))) {
      return ShipmentCardInfoLabels.multiOffers;
    } else if (shipmentInfo.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED && !shipmentInfo.offers) {
      return ShipmentCardInfoLabels.withoutOffers;
    } else if (shipmentInfo.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED && shipmentInfo.offers?.length === 1) {
      return ShipmentCardInfoLabels.oneOffer;
    } else {
      const shipmentStatus =
      SHIPMENT_REQUEST_STATUS.find(shipment => shipment.status.toLocaleLowerCase() === shipmentInfo. status.toLocaleLowerCase());
      if (!shipmentStatus) {
        return this.labels.noInfo;
      }
      return shipmentStatus.description;
    }
  }

  /**
   * @description Fires when click on view details button
   */
  public async viewDetails(): Promise<void> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = this.properties.detailsDialogHeight;
    dialogConfig.width = this.properties.detailsDialogWidth;
    dialogConfig.data = this.shipmentRequests;
  }

  /**
   * @description Open the dialog of the shipment request quote
   */
  public async openDialogQuote(): Promise<void> {
    const dialogRef = this.dialog.open(DialogQuoteBiisShipmentComponent, {
      data: this.shipmentRequests,
      width:  this.properties.detailsDialogWidth
    });
    this.modalSub = dialogRef.afterClosed().subscribe(async result => {
      if (result > MIN_QUOTE) {
        this.captureQuote.emit(result);
      }
    });
  }

  /**
   * @description method to emit the data of the shipment selected
   * @param {ShipmentRequest} shipmentData data of the shipment selected
   */
  public goToOffersShipment(shipmentData: ShipmentRequest): void {
    this.shipmentSelected.emit(shipmentData);
  }

  /**
   * @description Open the dialog of the shipment request detail
   * @param {ShipmentRequestResponse} data The data of the shipment request selected
   */
  public openDialogDetails(): void {
    this.shipmentRequests.userCoordinates = this.userCoordinates;
    this.dialog.open(DialogShipmentRequestDetailsComponent, {
      data: this.shipmentRequests,
      width: MARKETPLACE_SHIPPER_CONSTANTS.WIDTH_DETAIL
    });
  }

  /**
   * @description Open the dialog to associate a request with shipment
   * @param {ShipmentRequestResponse} row data of request selected
   */
  public openAssociateShipmentDialog(row: ShipmentRequestResponse): void {
    this.dialog.open(DialogAssociateShipmentComponent, {
      data: row,
      width: MARKETPLACE_SHIPPER_CONSTANTS.ASSOCIATE_SHIPMENT_WIDTH,
      height: MARKETPLACE_SHIPPER_CONSTANTS.ASSOCIATE_SHIPMENT_HEIGHT
    });
  }

  /**
   * @description Verify if disable button goToOffersButton
   * @param {ShipmentRequestResponse} shipmentRequest The object to valid the status
   * @returns {boolean} Value for determinate if disable button or not
   */
  public validViewOffers(shipmentRequest: ShipmentRequestResponse): boolean {
    return VALID_STATUS_SHIPMENT_REQUEST_VIEW_OFFERS.includes(shipmentRequest?.status.toLowerCase());
  }

  /**
   * @description Show Shipment request format preview
   * @param {ShipmentRequestResponse} shipmentRequest Selected shipment request
   */
  public showShipmentRequestFormat(shipmentRequest: ShipmentRequestResponse): void {
    this.dialog.open(DialogFilePreviewComponent, {
      data: {
        isPrintable: true,
        isDownloadable: false,
        shipmentRequestData: shipmentRequest
      },
      width: ShipmentRequestFormatDialogProperties.width,
      height: ShipmentRequestFormatDialogProperties.height
    });
  }
}
