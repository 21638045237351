<div class="multiple-input-search-container">
    <div id="multipleSearchArea" class="custom-multiple-input-search">
        <div id="innerSearchArea">
            <div class="delete-icon">
                <mat-icon
                    id="deleteAllTagsButton"
                    [matTooltip]="'multipleInputSearchLabels.deleteAllTags' | translate"
                    matTooltipPosition='above'
                    class="icon-button"
                    (click)="deleteItems()">
                    delete
                </mat-icon>
            </div>
            <div class="tag-input-div">
                <tag-input
                    id="tagInput"
                    [(ngModel)]="tagsInput"
                    [addOnPaste]="true"
                    [pasteSplitPattern]="splitPattern"
                    placeholder="{{ 'multipleInputSearchLabels.tagInputPlaceholder' | translate }}"
                    secondaryPlaceholder="{{ 'multipleInputSearchLabels.tagInputSecondaryPlaceholder' | translate }}"
                    (onAdd)="onAdd($event)"
                    (onRemove)="onRemove()"
                    [theme]="theme">
                </tag-input>
            </div>
        </div>
    </div>
</div>
