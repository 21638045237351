<ng-container>
  <div class="shipment-request-item mat-card-shipper matCardStyle">
    <div fxFlex="2" class="pointDiv">
      <mat-icon class="mt-5"
        matTooltip="{{ getTooltip(shipmentRequests) }}"
        matTooltipPosition="above"
        [ngClass]="getClassDot(shipmentRequests)">
        fiber_manual_record 
      </mat-icon>
    </div>
    <div>
      <mat-divider vertical class="verticalDivider"></mat-divider> 
    </div>
    <div fxFlex="20" *ngIf="isBiisPerson" class="largeDiv">
      <div fxLayout="column">
        <div class="id-style"  matTooltip="{{ shipmentRequests.identifier ? shipmentRequests.identifier : labels.noInfo }}" matTooltipPosition="above">
          {{ shipmentRequests.identifier ? shipmentRequests.identifier : labels.noInfo }}
        </div>
        <div *ngIf="isBiisPerson" class="reference" matTooltip="{{ labels.noInfo }}" matTooltipPosition="above">
          {{ labels.noInfo }}
        </div>
        <div *ngIf="!isBiisPerson" class="reference" matTooltip="{{ shipmentRequests.reference ? shipmentRequests.reference : labels.noReference }}" matTooltipPosition="above">
          {{ shipmentRequests.reference ? shipmentRequests.reference : labels.noReference }}
        </div>
      </div>
    </div>
    <div fxFlex="15" *ngIf="!isBiisPerson" class="largeDiv">
      <div fxLayout="column">
        <div class="id-style"  matTooltip="{{ shipmentRequests.identifier ? shipmentRequests.identifier : labels.noInfo }}" matTooltipPosition="above">
          {{ shipmentRequests.identifier ? shipmentRequests.identifier : labels.noInfo }}
        </div>
        <div class="reference" matTooltip="{{ shipmentRequests.reference ? shipmentRequests.reference : labels.noReference }}" matTooltipPosition="above">
          {{ shipmentRequests.reference ? shipmentRequests.reference : labels.noReference }}
        </div>
      </div>
    </div>
    <div>
      <mat-divider vertical class="verticalDivider"></mat-divider> 
    </div>
    <div fxFlex="16" *ngIf="!isBiisPerson" class="largeDiv">
      <div fxLayout="column">
        <div class="contentFont" matTooltip="{{ shipmentRequests.origin.name ? shipmentRequests.origin.name : labels.noInfo }}" matTooltipPosition="above">
          <div>
            <mat-icon class="icons contentGray">
              <img src="{{ pinIcon }}" alt="pin icon"/>
            </mat-icon>
          </div>
          <div>
            {{ shipmentRequests.origin.name ? shipmentRequests.origin.name : labels.noInfo }}
          </div>
        </div>
        <div class="contentFont"
            matTooltip="{{ shipmentRequests.destinations[this.shipmentRequests.destinations.length - 1].name ? 
                  shipmentRequests.destinations[this.shipmentRequests.destinations.length - 1].name : labels.noInfo }}" matTooltipPosition="above">
          <div>
            <mat-icon class="icons contentGray">
              <img src="{{ pinIcon }}" alt="pin icon" />
            </mat-icon>
          </div>
          <div>
            {{ shipmentRequests.destinations[this.shipmentRequests.destinations.length - 1].name ? 
              shipmentRequests.destinations[this.shipmentRequests.destinations.length - 1].name : labels.noInfo }}
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="20" *ngIf="isBiisPerson" class="largeDiv">
      <div fxLayout="column">
        <div class="contentFont" matTooltip="{{ shipmentRequests.origin.name ? shipmentRequests.origin.name : labels.noInfo }}" matTooltipPosition="above">
          <div>
            <mat-icon class="icons contentGray">
              <img src="{{ pinIcon }}" alt="pin icon" />
            </mat-icon>
          </div>
          <div>
            {{ shipmentRequests.origin.name ? shipmentRequests.origin.name : labels.noInfo }}
          </div>
        </div>
        <div class="contentFont" 
            matTooltip="{{ shipmentRequests.destinations[this.shipmentRequests.destinations.length - 1].name ? 
                  shipmentRequests.destinations[this.shipmentRequests.destinations.length - 1].name : labels.noInfo }}" matTooltipPosition="above">
          <div>
            <mat-icon class="icons contentGray">
              <img src="{{ pinIcon }}"alt="pin icon" />
            </mat-icon>
          </div>
          <div>
            {{ shipmentRequests.destinations[this.shipmentRequests.destinations.length - 1].name ? 
              shipmentRequests.destinations[this.shipmentRequests.destinations.length - 1].name : labels.noInfo }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isBiisPerson">
      <mat-divider vertical class="verticalDivider"></mat-divider> 
    </div>
    <div *ngIf="!isBiisPerson" fxFlex="14" class="largeDiv">
      <div fxLayout="column">
        <div class="contentFont" matTooltip="{{ shipmentRequests.origin.loadDate ? getDate(shipmentRequests.origin.loadDate) : labels.noDate }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons">
              <img class="" src="{{ shipmentIcon }}" alt="shipment icon"/>
            </mat-icon>
          </div>
          <div>
            {{ shipmentRequests.origin.loadDate ? getDate(shipmentRequests.origin.loadDate) : labels.noDate }}
          </div>
        </div>
        <div class="contentFont" matTooltip=" {{ shipmentRequests.destinations[shipmentRequests.destinations.length-1].deliveryDate ? getDate(shipmentRequests.destinations[shipmentRequests.destinations.length-1].deliveryDate) : labels.noDate }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons contentGray">
              <img class="center-pin" src="{{ pinIcon }}" alt="pin icon" />
            </mat-icon>
          </div>
          <div>
            {{ shipmentRequests.destinations[shipmentRequests.destinations.length-1].deliveryDate ? getDate(shipmentRequests.destinations[shipmentRequests.destinations.length-1].deliveryDate) : labels.noDate }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-divider vertical class="verticalDivider"></mat-divider> 
    </div>
    <div fxFlex="15" *ngIf="isBiisPerson" class="smallDiv">
      <div fxLayout="column">
        <div class="contentFont" matTooltip="{{ labels.noInfo }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons">
              <img class="" src="{{ shipmentIcon }}" alt="shipment icon" />
            </mat-icon>
          </div>
          <div>
            {{ labels.noInfo }}
          </div>
        </div>
        <div class="contentFont" matTooltip="{{ labels.noApply }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons contentGray">
              <img class="center-pin" src="{{ pinIcon }}"  alt="pin icon"/>
            </mat-icon>
          </div>
          <div>
            {{ labels.noInfo }}
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="7" *ngIf="!isBiisPerson" class="smallDiv">
          <div fxLayout="column">
            <div class="contentFont" matTooltip="{{ shipmentRequests.vehicleType.name ? shipmentRequests.vehicleType.name : labels.noApply }}" matTooltipPosition="above">
              <div class="mr-12">
                <mat-icon class="icons">
                  <img class="" src="{{ vehicleIcon }}" alt="vehicle icon"/>
                </mat-icon>
              </div>
              <div>
                {{ shipmentRequests.vehicleType.name ? shipmentRequests.vehicleType.name : labels.noApply }}
              </div>
            </div>
            <div class="contentFont" matTooltip="{{ shipmentRequests.transport ? shipmentRequests.transport.plates : labels.noApply }}" matTooltipPosition="above">
              <div class="mr-12">
                <mat-icon class="icons ">
                  <img class="" src="{{ plateIcon }}"  alt ="palet icon"/>
                </mat-icon>
              </div>
              <div>
                {{ shipmentRequests.transport ? shipmentRequests.transport.plates : labels.noApply }}
              </div>
            </div>
          </div>
    </div>
    <div>
      <mat-divider vertical class="verticalDivider"></mat-divider> 
    </div>
    <div fxFlex="10" *ngIf="isBiisPerson" class="smallDiv">
      <div fxLayout="column">
        <div class="contentFont" matTooltip="{{ shipmentRequests.vehicleType.name ? shipmentRequests.vehicleType.name : labels.noApply }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons ">
              <img class="pl-12" src="{{ vehicleIcon }}" alt=" vehicle icon" />
            </mat-icon>
          </div>
          <div>
            {{ shipmentRequests.vehicleType.name ? shipmentRequests.vehicleType.name : labels.noApply }}
          </div>
        </div>
        <div class="contentFont " matTooltip="{{ shipmentRequests.vehicleType.name ? shipmentRequests.vehicleType.name : labels.noApply }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons">
              <img class="pl-12" src="{{ boxIcon }}" alt ="box icon"/>
            </mat-icon>
          </div>
          <div>
            {{ labels.noInfo }}
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="7" *ngIf="!isBiisPerson" class="smallDiv">
      <div fxLayout="column">
        <div class="contentFont" matTooltip="{{ shipmentRequests.acceptedCarrier ? shipmentRequests.acceptedCarrier.carrier.name : labels.noInfo }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons ">
              <img class="pl-12" src="{{ vehicleIcon }}" alt="vehicle icon"/>
            </mat-icon>
          </div>
          <div>
            {{ shipmentRequests.acceptedCarrier ? shipmentRequests.acceptedCarrier.carrier.name : labels.noInfo }}
          </div>
        </div>
        <div class="contentFont " matTooltip="{{ applyDestination(shipmentRequests.destinations.length) }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons">
              <img class="pl-12" src="{{ numberIcon }}" alt="number icon" />
            </mat-icon>
          </div>
          <div>
            {{ applyDestination(shipmentRequests.destinations.length) }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-divider vertical class="verticalDivider"></mat-divider> 
    </div>
    <div fxFlex="10" *ngIf="isBiisPerson" class="divPerson smallDiv">
      <div class="contentFont" matTooltip="{{ shipmentRequests.weight }}" matTooltipPosition="above">
        <div class="mr-12">
          <mat-icon class="icons mr-5">
            <img class="mr-10" src="{{ weigthIcon }}"alt="weigth icon" />
          </mat-icon>
        </div>
        <div>
          {{ shipmentRequests.weight ? (shipmentRequests.weight | number: numberFormat:region) + ' ' + labels.kg : labels.noInfo }}
        </div>
      </div>
      <div class="contentFont " matTooltip="{{ shipmentRequests.volume }}" matTooltipPosition="above">
        <div class="mr-12">
          <mat-icon class="icons mr-5">
            <img class="ml-4 mr-10" src="{{ boxIcon }}" alt="box icon" />
          </mat-icon>
        </div>
        <div>
          {{ shipmentRequests.volume ? (shipmentRequests.volume | number: numberFormat:region) + ' ' + labels.cubicMeters : labels.noInfo }}
        </div>
      </div>
    </div>
    <div fxFlex="7" *ngIf="!isBiisPerson" class="divPerson smallDiv">
          <div class="contentFont" matTooltip="{{ shipmentRequests.transport ? shipmentRequests.transport.driver : labels.noInfo }}" matTooltipPosition="above">
            <div class="mr-12">
              <mat-icon class="icons ">
                <img class="pl-12" src="{{ personIcon }}" alt="person icon" />
              </mat-icon>
            </div>
            <div>
              {{ shipmentRequests.transport ? shipmentRequests.transport.driver : labels.noInfo }}
            </div>
          </div>
          <div class="contentFont " matTooltip="{{ shipmentRequests.transport && shipmentRequests.transport.driverPhone ? shipmentRequests.transport.driverPhone : labels.noInfo }}" matTooltipPosition="above">
            <div class="mr-12">
              <mat-icon class="icons">
                <img class="pl-12" src="{{ phoneIcon }}" alt="phone icon" />
              </mat-icon>
            </div>
            <div>
              {{ shipmentRequests.transport && shipmentRequests.transport.driverPhone ? shipmentRequests.transport.driverPhone : labels.noInfo }}
            </div>
          </div>
    </div>
    <div>
      <mat-divider vertical class="verticalDivider"></mat-divider> 
    </div>
    <div fxFlex="10" *ngIf="isBiisPerson" class="smallDiv">
      <div fxLayout="column">
        <div class="contentFont" matTooltip="{{ labels.noInfo }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons ">
              <img src="{{ moneyIcon }}" alt="money icon" />
            </mat-icon>
          </div>
          <div>
            {{ labels.noInfo }}
          </div>
        </div>
        <div class="contentFont " matTooltip="{{ labels.withoutQuote }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons">
              <img src="{{ moneyIcon }}" alt ="money icon"/>
            </mat-icon>
          </div>
          <div>
            {{ labels.withoutQuote }}
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="8" *ngIf="!isBiisPerson" class="smallDiv">
      <div fxLayout="column">
        <div class="contentFont" matTooltip="{{ shipmentRequests.weight ? ((shipmentRequests.weight | number: numberFormat:region) + ' ' + labels.kg  ): labels.noInfo }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons ">
              <img src="{{ weigthIcon }}" alt="weigth icon" />
            </mat-icon>
          </div>
          <div>
            {{ shipmentRequests.weight ? (shipmentRequests.weight | number: numberFormat:region) + ' ' + labels.kg : labels.noInfo }}
          </div>
        </div>
        <div class="contentFont " matTooltip="{{ shipmentRequests.volume ? (shipmentRequests.volume | number: numberFormat:region) + ' ' + labels.cubicMeters : labels.noInfo }}" matTooltipPosition="above">
          <div class="mr-12">
            <mat-icon class="icons">
              <img class="ml-4" src="{{ boxIcon }}" alt = "box icon" />
            </mat-icon>
          </div>
          <div>
            {{ shipmentRequests.volume ? (shipmentRequests.volume | number: numberFormat:region) + ' ' + labels.cubicMeters : labels.noInfo }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-divider vertical class="verticalDivider"></mat-divider> 
    </div>
    <div class="buttonsDiv" *ngIf="!isBiisPerson">
      <button mat-icon-button 
          class="contentGray buttonStyle" 
          id="btnOpenDialogDetails"
          (click)="openDialogDetails()"
          matTooltip="{{ labels.viewDetail }}">
        <mat-icon class="iconSize"> remove_red_eye </mat-icon>
      </button>
      <button mat-icon-button 
          class="contentGray buttonShipment"
          [ngClass]="{'icon--disabled': !validViewOffers(shipmentRequests)}"
          [disabled] = "!validViewOffers(shipmentRequests)"
          id="goToOffersButton"
          (click)="goToOffersShipment(shipmentRequests)"
          matTooltip="{{ labels.viewOffers }}">
        <mat-icon class="shipmentIcon" class="iconSize">
          <img class="imgButton" src="{{ moneyIcon }}" alt="money icon" />
        </mat-icon>
      </button>
      <div class="div-button-class" *ngIf="shipmentRequests.status === labels.assignedStatus"
        [matTooltip]= 'shipmentRequests.shipment ? labels.shipmentDetected : labels.associateShipment'
        matTooltipPosition="above" >
        <button mat-icon-button
          class="contentGray buttonStyle"
          (click)="openAssociateShipmentDialog(shipmentRequests)"
          id="affiliateWithShipmentBtn"
          [ngClass]="{'icon--disabled': shipmentRequests.shipment}"
          [disabled]="shipmentRequests.shipment">
          <mat-icon class="shipmentIcon" class="iconSize">
            <img class="imgButton" src="{{ affiliateShipmentIcon }}" alt="affiliate shipment icon"/>
          </mat-icon>
        </button>
      </div>
      <div class="div-button-class" *ngIf="shipmentRequests.status !== labels.assignedStatus"
        [matTooltip]= 'labels.requestNotAssignedMessage'
        matTooltipPosition="above" >
        <button mat-icon-button
          class="contentGray buttonStyle"
          id="affiliateWithShipmentBtnAlt"
          [ngClass]="{'icon--disabled': shipmentRequests.status !== labels.assignedStatus}"
          [disabled]="true">
          <mat-icon class="shipmentIcon" class="iconSize">
            <img class="imgButton" src="{{ affiliateShipmentIcon }}" alt="affiliate shipment icon"/>
          </mat-icon>
        </button>
      </div>
      <button mat-icon-button
        (click)="showShipmentRequestFormat(shipmentRequests)"
        class="contentGray buttonShipment"
        id="btnPrintShipmentRequest"
        [ngClass]="{'icon--disabled': shipmentRequests.status !== mpShipmentRequestStatus.ASSIGNED}"
        [matTooltip]="labels.printLabel">
        <mat-icon class="iconSize"> print </mat-icon>
      </button>
      <button mat-icon-button
        class="contentGray buttonCancel"
        id="btnDeleteShipment"
        matTooltip="{{ labels.cancelOffer }}">
        <mat-icon class="iconSize"> cancel </mat-icon>
      </button>
    </div>
    <div class="buttonsDiv-biis-person" *ngIf="isBiisPerson">
      <button mat-icon-button 
          class="contentGray buttonStyle" 
          id="btnViewDetail"
          matTooltip="{{ labels.viewDetail }}">
        <mat-icon class="iconSize"> remove_red_eye </mat-icon>
      </button>
      <button *ngIf="shipmentRequests.defaultOffer"
          mat-icon-button
          class="contentGray buttonShipment"
          matTooltip="{{ labels.assignedMarketplace }}"
          (click)="openDialogQuote()"
          matTooltipPosition="above"
          id="btnOpenDialogQuote">
        <mat-icon class="shipmentIcon" class="iconSize">
          <img class="imgButton" src="{{ moneyIcon }}" alt="money icon" />
        </mat-icon>
      </button>
      <button *ngIf="shipmentRequests.defaultOffer"
          mat-icon-button
          class="contentGray buttonCancel"
          id="btnAssigneCarrier"
          matTooltip="{{ labels.assignedCarrier }}">
        <mat-icon class="iconSize">
          <img class="imgButton" src="{{ shipmentIcon }}" alt="shipment icon" />
        </mat-icon>
      </button>
    </div>
  </div>
</ng-container>
