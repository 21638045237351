import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccountBody, AccountResponse, PaginatedAccountsResponse } from './../../interfaces/account';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { PaginationRequest } from './../../interfaces/pagination/pagination.request';

const apiUrl = environment.apiUrl;

@Injectable()
export class AccountProvider {

  constructor(private http: HttpClient, private _appService: AppService) { }

  /**
   * @description Gets all related shipper accounts by given shipperId
   * @param {string} shipperId - Optional Param to get related accounts
   * @returns {Promise<AccountResponse>} Shipper found accounts
   */
  public async getAccounts(shipperId?: string): Promise<AccountResponse> {
    const shipperOid = shipperId ? shipperId : this._appService.getShipperOid();
    return await this.http.get<AccountResponse>(apiUrl + '/embarcadores/' + shipperOid + '/cuentas').toPromise();
  }

  /**
   * @description Get shipper accounts paginated
   * @param {string} shipperId - Optional Param to get related accounts
   * @param {PaginationRequest} shipperId - Optional Param to get related accounts
   * @returns {Promise<PaginatedAccountsResponse>} Shipper found accounts
   */
  public async getPaginatedAccounts(shipperOid: string, requestBody: PaginationRequest): Promise<PaginatedAccountsResponse> {
    return await this.http.post<PaginatedAccountsResponse>(apiUrl + '/embarcadores/' + shipperOid + '/cuentas', requestBody).toPromise();
  }

  /**
   * @description Create an account
   * @param {account} AccountBody - account body
   * @returns {Promise<AccountResponse>} Created account
   */
  public async createAccount(account: AccountBody): Promise<AccountResponse> {
    return await this.http.post<AccountResponse>(apiUrl + '/cuentas', account).toPromise();
  }

  /**
   * @description Get all RFCs of account
   * @returns {Promise<Array<string>>} RFCs of account
   */
  public async getRFCs(): Promise<Array<string>> {
    return await this.http.get<Array<string>>(apiUrl + '/cuentas' + '?rfc=true').toPromise();
  }

  /**
   * @description Update an account
   * @param {account} AccountBody - account body
   * @param {accountId} string - account id
   * @returns {Promise<AccountResponse>} Updated account
   */
  public async updateAccount(account: AccountBody, accountId: string): Promise<AccountResponse> {
    return await this.http.put<AccountResponse>(apiUrl + '/cuentas/' + accountId, account).toPromise();
  }

  /**
   * @description Delete an account
   * @param {accountId} string - account id
   * @returns {Promise<AccountResponse>} Updated account
   */
  public async deleteAccount(accountId: string): Promise<AccountResponse> {
    return await this.http.delete<AccountResponse>(apiUrl + '/cuentas/' + accountId).toPromise();
  }

  /**
   * @description Get accounts by name search
   * @param {string} name Account name to be looking for
   * @param {string} tenantId Shipper's Id
   * @returns {Promise<Array<AccountBody>>} Accounts found
   */
  public async getAccountsByNameCoincidences(name: string, tenantId: string): Promise<Array<AccountBody>> {
    const url = apiUrl + '/shipper/' + tenantId + '/account/' + name + '?byCoincidence=true';
    return await this.http.get<Array<AccountBody>>(url).toPromise();
  }

  /**
   * @description Gets accounts by name list search
   * @param {Array<string>} names Array with account names to be looking for
   * @param {string} shipperId Shipper's Id
   * @returns {Promise<Array<AccountBody>>} Accounts found
   */
  public async getAccountsByNameList(names: Array<string>, shipperId: string): Promise<Array<AccountBody>> {
    return await this.http.post<Array<AccountBody>>(`${apiUrl}/shipper/${shipperId}/account/byNames`, names).toPromise();
  }
}
