import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { AppConstants } from '../../../constants/app-constants.constants';
import { AppService } from '../../../app.service';
import { BlobProvider } from '../../../providers/evidence/blob-provider.service';
import { CommunicationService } from '../../../services/communication';
import { DialogFilePreviewComponent } from '../../../components/dialog/dialog-file-preview/dialog-file-preview.component';
import { DialogStandardComponent } from '../../../components/dialog/dialog-standard/dialog-standard.component';
import { environment } from '../../../../environments/environment';
import { EvidenceProvider } from '../../../providers/evidence/evidence-provider.service';
import { IncidenceProvider } from '../../../providers/incidence/incidence-provider.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ManualDeliveryProperties } from './manual-delivery.properties';
import { EvidenceToDisplay } from '../../../interfaces/evidence';
import { MOBILITY_CONSTANTS } from './../../../constants/mobility.constants';
import { OrderProvider } from '../../../providers/orders/order-provider.service';
import { ShipmentsService } from '../../../providers/shipments/shipments.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';
import { TrackingService } from '../../../providers/tracking/tracking.service';
import { UserService } from '../../../providers/user/user.service';
import { WarehouseProvider } from '../../../providers/warehouse/warehouse-provider.service';

import * as _ from 'lodash';
import { FileReason, ArrayObject } from './manual-delivery.interfaces';
import { Subscription } from 'rxjs';

const BLOBNAMEPROPERTY = 'blobName';
const CONFIRM = 'Confirm';
const EVIDENCEFILE = 'EvidenceFile';
const EVIDENCETYPE = 'evidenceType';
const INCIDENCE = 'Incidence';
const INCIDENCETYPE = 'incidenceType';
const INPUTFILES = 'inputFiles';
const KEY_ADDRESSINFO = 'addressInfo';
const KEY_DATEINROUTE = 'dateInRoute';
const KEY_DELIVERYTYPE = 'deliveryType';
const KEY_DESCRIPTION = 'description';
const KEY_DETAILDATA = 'detailData';
const KEY_DETAILOID = 'detailOid';
const KEY_FILES = 'files';
const KEY_FILETYPE = 'fileType';
const KEY_FOLIO = 'folio';
const KEY_ORDER = 'order';
const KEY_ORDEROID = 'orderOid';
const KEY_REJECTEDBOXES = 'rejectedBoxes';
const KEY_REJECTEDPALLETS = 'rejectedPallets';
const KEY_REJECTEDPIECES = 'rejectedPieces';
const KEY_REJECTIONRESPONSIBLE = 'rejectionResponsible';
const KEY_SASURL = 'sasUrl';
const KEY_SHIPMENTOID = 'shipmentOid';
const KEY_SHIPMENTREQUEST = 'shipmentRequest';
const KEY_SKUS = 'skus';
const KEY_TIMESTAMP = 'timestamp';
const KEY_TYPE = 'type';
const KEY_LANGUAGE_DEFAULT = 'es';
const KEY_WAREHOUSEDEFAULT = 'warehouseDefault';
const KEY_WAREHOUSENAME = 'warehouseName';
const KEY_WAREHOUSEMERCHANDISE = 'warehouseMerchandise';
const MAXPDFFILESIZE = 1024000;
const NOTIMAGE = 'not image';
const ORDERSTATUS = ['Entregada', 'Parcial', 'Rechazada', 'No Entregada', 'En Transito'];
const PDFFILETYPE = 'application/pdf';
const PICTURE = 'Picture';
const SIGNATURE = 'Signature';
const SPACE = ' ';
const TYPE_REASON = ['Evidencia', 'Incidencia'];
const URL = environment.baseStorageUrl + environment.mainContainer;

@Component({
  selector: 'app-manual-delivery',
  templateUrl: './manual-delivery.component.html',
  styleUrls: ['./manual-delivery.component.scss', '../../../app.component.scss']
})

export class ManualDeliveryComponent implements OnInit, OnDestroy {
  @Input() public infoOrder: Object;
  @Input() public isReadonly: boolean;
  @Input() public shipmentName: string;
  public blobURLFile: Array<string>;
  public currentVisitNumber: number;
  public deliveryTypeList: Array<string>;
  public detailOid: string;
  public evidenceReasons: Array<FileReason>;
  public file: any;
  public fileTypeList: Array<string>;
  public hasVisits: boolean;
  public imagesEvidences: Array<string>;
  public imagesIncidences: Array<string>;
  public incidenceReasons: Array<FileReason>;
  public infoDeliveryDetail: Array<object>;
  public invalidFiles: Array<string>;
  public isCompleteRejection: boolean;
  public isRejection: boolean;
  public isRejectionEdit: boolean;
  public isSaveEditionEnabled: boolean;
  public isValidFile: boolean;
  public language: string;
  public languageLabels: any;
  public languageSuscription: Subscription;
  public manualDeliveryForm: FormGroup;
  public manualFiles: Array<EvidenceToDisplay>;
  public manualDeliveryLabelsTranslated: any;
  public manualDeliveryRejectionsLabelsTranslated: any;
  public manualDeliveryRejectionsTranslated: any;
  public manualDeliveryStatusCatalog: any;
  public manualDeliveryInfoStatusCatalog: any;
  public maxDate: Date;
  public minDate: Date;
  public orderData: any;
  public orderOid: string;
  public rejectionResponsibleList: Array<ArrayObject>;
  public shipmentOid: string;
  public subtitleInfo: object;
  public tenantId: string;
  public typeForm: any;
  public userInfo: object;
  public userName: string;
  public visibleSummary: boolean;
  public warehouseInfo: object;
  constructor(
    private _appService: AppService,
    private blobProvider: BlobProvider,
    private orderCom: CommunicationService,
    private orderProvider: OrderProvider,
    private readonly builder: FormBuilder,
    public dialog: MatDialog,
    public incidenceProvider: IncidenceProvider,
    public shipmentService: ShipmentsService,
    public toast: ToastrAlertsService,
    public trackingProvider: TrackingService,
    public userService: UserService,
    public warehouseProvider: WarehouseProvider,
    public evidenceProvider: EvidenceProvider,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService
  ) {
    this.setLanguage();
    this.typeForm = {};
  }

  /**
   * @description Angular destroy Lifecycle
   */
  public ngOnDestroy(): void {
    this.languageSuscription?.unsubscribe();
  }

  /**
   * @description Init lifeCycleHook about Angular Core
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    await this.getManualDeliveryLabels();
    await this.getManualDeliveryRejectionLabels();
    await this.getManualDeliveryRejectionTextLabels();
    await this.getManualDeliveryStatusCatalog();
    await this.getManualDeliveryInfoStatusCatalog();
    this.language = this._languageTranslateService.getLanguage();
    this.isRejection = false;
    this.isRejectionEdit = true;
    this.isSaveEditionEnabled = true;
    this.userName = this._appService.getShipperNameCookie();
    this.tenantId = this._appService.getShipperOid();
    this.visibleSummary = true;
    this.invalidFiles = [];
    this.manualFiles = [];
    this.blobURLFile = [];
    this.maxDate = new Date();
    this.imagesEvidences = [];
    this.imagesIncidences = [];
    this.infoDeliveryDetail = [];
    this.detailOid = this.infoOrder ? this.infoOrder[KEY_DETAILOID] : '';
    this.orderOid = this.infoOrder[KEY_ORDEROID];
    this.shipmentOid = this.infoOrder[KEY_SHIPMENTOID];
    this.minDate = this.infoOrder[KEY_DATEINROUTE];
    this.getOrderData().then(() => {
      this.getVisitsDone();
    });
    this.setDeliveryList();
    this.setFileTypeList();
    this.setTypeForm();
    this.incidenceProvider.getIncidenceReasons()
      .then((res) => {
        this.evidenceReasons = res.motivosIncidencia.filter(reason => (reason.kind === TYPE_REASON[0]) && reason.active);
        this.incidenceReasons = res.motivosIncidencia.filter(reason => (reason.kind === TYPE_REASON[1]) && reason.active);
        if (this.evidenceReasons.length === 0 && this.incidenceReasons.length === 0) {
          this.toast.warningAlert(this.manualDeliveryLabelsTranslated.toastWarnNoReasons);
        } else if (this.evidenceReasons.length === 0) {
          this.toast.warningAlert(this.manualDeliveryLabelsTranslated.toastWarnNoEvidenceReasons);
        } else if (this.incidenceReasons.length === 0) {
          this.toast.warningAlert(this.manualDeliveryLabelsTranslated.toastWarnNoIncidenceReasons);
        }
      })
      .catch(() => { this.toast.errorAlert(this.manualDeliveryLabelsTranslated.getIncidenceReasonError); });
    this.initForm(this.builder);
    this.initRejectionResponsibleList();
  }

  /**
   * @description Create the list deliveryTypeList, for select options
   */
  private setDeliveryList(): void {
    this.deliveryTypeList = [
      this.manualDeliveryLabelsTranslated.totalDelivery,
      this.manualDeliveryLabelsTranslated.partialDelivery,
      this.manualDeliveryLabelsTranslated.rejection,
      this.manualDeliveryLabelsTranslated.undelivered,
    ];
  }

  /**
   * @description Build the list fileTypeList, for select options
   */
  private setFileTypeList(): void {
    this.fileTypeList = [
      this.manualDeliveryLabelsTranslated.evidence,
      this.manualDeliveryLabelsTranslated.incidence
    ];
  }

  /**
   * @description Sets the values ​​for the typeForm object to perform different evaluations
   */
  private setTypeForm(): void {
    this.typeForm.totalDelivery = this.manualDeliveryLabelsTranslated.totalDelivery;
    this.typeForm.partialDelivery = this.manualDeliveryLabelsTranslated.partialDelivery;
    this.typeForm.rejection = this.manualDeliveryLabelsTranslated.rejection;
    this.typeForm.pieces = this.manualDeliveryLabelsTranslated.pieces;
    this.typeForm.pallets = this.manualDeliveryLabelsTranslated.pallets;
    this.typeForm.boxes = this.manualDeliveryLabelsTranslated.boxes;
    this.typeForm.incidence = this.manualDeliveryLabelsTranslated.incidence;
    this.typeForm.evidence = this.manualDeliveryLabelsTranslated.evidence;
  }

  /**
   * @description Get the complete order's information
   */
  public async getOrderData(): Promise<void> {
    const orderAux = await this.orderProvider.getOrderByOids({ ordersIds: [this.orderOid] });
    this.orderData = orderAux[0];
    const userOid = this._appService.getUserOid();
    this.userInfo = await this.userService.getUserById(userOid);
    this.warehouseInfo = this.userInfo[KEY_WAREHOUSEDEFAULT] ?
      await this.warehouseProvider.getWarehouseByOid(this.userInfo[KEY_WAREHOUSEDEFAULT]._id) : undefined;
  }

  /**
   * @description Initialize Manual Delivery Form
   * @param fb Builder to create an abstact control
   */
  public initForm(fb: FormBuilder): void {
    this.manualDeliveryForm = fb.group(
      {
        deliveryDate: new FormControl(null, [Validators.required]),
        deliveryHour: new FormControl(null, [Validators.required]),
        deliveryType: new FormControl(null, [Validators.required]),
        receptorName: new FormControl(null),
        fileType: new FormControl(null, [Validators.required]),
        fileReason: new FormControl(null, [Validators.required]),
        fileDescription: new FormControl(null, [Validators.required]),
        rejectedPieces: new FormControl(0),
        rejectedBoxes: new FormControl(0),
        rejectedPallets: new FormControl(0),
        skus: new FormControl(null),
        rejectionResponsible: new FormControl(null),
        warehouseMerchandise: new FormControl(false)
      }
    );
  }

  /**
   * @description Gets a reference to the specified value of the input attribute
   */
  public clickFile(): void {
    document.getElementById(INPUTFILES).click();
  }

  /**
   * @description Push files selected when clicking in add-icon
   */
  public addFiles(): void {
    this.invalidFiles = [];
    let fileSizes = 0;
    this.file = document.getElementById(INPUTFILES);
    for (let i = 0; i < this.file.files.length; i++) {
      this.isValidFile = false;
      const fileInput = this.file.files[i];
      for (let acceptedFileIndex = 0; acceptedFileIndex <
        MOBILITY_CONSTANTS.MANUAL_DELIVERY_ACCEPTED_FILE_TYPES.length; acceptedFileIndex++) {
        if (fileInput.type === MOBILITY_CONSTANTS.MANUAL_DELIVERY_ACCEPTED_FILE_TYPES[acceptedFileIndex]) {
          if (fileInput.type === PDFFILETYPE) {
            if (fileInput.size > MAXPDFFILESIZE) {
              this.toast.errorAlert(this.manualDeliveryLabelsTranslated.invalidSize + this.invalidFiles);
              return;
            }
          }
          this.isValidFile = true;
          this.manualFiles.push({ file: this.file.files[i], name: this.file.files[i].name, isMobilityEvidence: false });
        }
      }
      if (!this.isValidFile) {
        this.invalidFiles.push(fileInput.name);
      }
    }

    for (let j = 0; j < this.file.files.length; j++) {
      fileSizes = this.file.files[j].size + fileSizes;
    }
    if (!_.isEmpty(this.invalidFiles)) {
      this.toast.errorAlert(this.manualDeliveryLabelsTranslated.invalidFile + this.invalidFiles);
    }
  }

  /**
  * @description Push files dropped in drag and drop area
  */
  public addFilesByDrop(event): void {
    if (this.isReadonly) {
      this.toast.warningAlert(this.manualDeliveryLabelsTranslated.dragAndDropNotAllow);
    } else {
      this.invalidFiles = [];
      event.addedFiles.forEach(file => {
        this.isValidFile = false;
        for (let i = 0; i < MOBILITY_CONSTANTS.MANUAL_DELIVERY_ACCEPTED_FILE_TYPES.length; i++) {
          if (file.type === MOBILITY_CONSTANTS.MANUAL_DELIVERY_ACCEPTED_FILE_TYPES[i]) {
            if (file.type === PDFFILETYPE) {
              if (file.size > MAXPDFFILESIZE) {
                this.toast.errorAlert(this.manualDeliveryLabelsTranslated.invalidSize + this.invalidFiles);
                return;
              }
            }
            this.isValidFile = true;
            const files = {
              file: file,
              name: file.name,
              isMobilityEvidence: false
            };
            this.manualFiles.push(files);
          }
        }
        if (!this.isValidFile) {
          this.invalidFiles.push(file.name);
        }
      });
      if (!_.isEmpty(this.invalidFiles)) {
        this.toast.errorAlert(this.manualDeliveryLabelsTranslated.invalidFile + this.invalidFiles);
      }
    }
  }

  /**
   * Removes a files in drag and drop area
   * @param index The index number of the file to delete
   */
  public removeFile(index: number): void {
    if (index >= 0) {
      this.manualFiles.splice(index, 1);
    }
  }

  /**
   * @description Functionality to not allow clicking inside the drag and drop area
   * @returns {boolean} Flag in order to not allow click inside drag and drop
   */
  public openDisabled(): boolean {
    return false;
  }

  /**
   * Display a mat- dialog with the file selected
   * @param file File clicked to view
   */
  public onFileClick(file: File): void {
    if (file) {
      this.dialog.open(DialogFilePreviewComponent, {
        data: {
          title: this.manualDeliveryLabelsTranslated.previewTitle,
          file: file
        },
        width: ManualDeliveryProperties.previewDialogWidth
      });
    }
  }

  /**
   * Cancel event if it's cancelable without stopping functionality
   * @param event Event which takes place in the DOM.
   */
  public handleKeyEnter(event: Event): void {
    event.preventDefault();
  }

  /**
   * @description Calls dialog to create de manualDelivery
   */
  public onSave(): void {
    const dialogRef = this.dialog.open(DialogStandardComponent, {
      data: {
        title: this.manualDeliveryLabelsTranslated.registerDelivery,
        resume: this.manualDeliveryLabelsTranslated.registerResume + (this.currentVisitNumber + 1),
        imgRef: '../../../assets/releaseOrders1.svg',
        question: this.manualDeliveryLabelsTranslated.registerQuestion,
        button1: this.manualDeliveryLabelsTranslated.registerButtonRejection,
        button2: this.manualDeliveryLabelsTranslated.registerButtonConfirmation
      },
      width: ManualDeliveryProperties.registerDialogWidth
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === CONFIRM) {
        if (await this.isValidTimeStamp()) {
          this.toast.processingAlert();
          try {
            if (!this.manualFiles || !this.manualFiles.length) {
              this.toast.warningAlert(this.manualDeliveryLabelsTranslated.toastWarningRequiredFile);
            } else {
              await this.createDelivery();
              await this.updateOrderStatus(this.orderOid);
              await this.orderProvider.updateManualDelivered(this.buildUpdateManualDeliveredBody()).then(() => {
                  this.toast.successAlert(this.manualDeliveryLabelsTranslated.deliveryRegistered);
                  this.orderCom.showManualDelivery();
                });
            }
          } catch (error)  {
              this.toast.errorAlert(this.manualDeliveryLabelsTranslated.requestFailed);
          } finally {
            this.toast.closeProcessing();
          }
        }
      }
    });
  }

  public onCancel(): void {
    this.orderCom.showManualDelivery();
    return;
  }

  /**
   * @description For every file calls createFile function
   * @returns {Promise<boolean>} Promise with flag if creation was done
   */
  public async createDelivery(uploadFilesByDetail?: object): Promise<boolean> {
    let createStatus = false;
    if (!_.isUndefined(this.manualFiles)) {
      if (_.isEmpty(this.manualFiles)) {
        const body = await this.bodyEvidenceWithNoFile();
        try {
          const resultCreate = await this.trackingProvider.createEvidence(this.shipmentOid, this.detailOid, body[KEY_TYPE], body);
          return true;
        } catch (error) {
          return false;
        }
      } else {
        for (let i = 0; i < this.manualFiles.length; i++) {
          const extension: Array<string> = this.manualFiles[i].name.split('.');
          createStatus = await this.createFile(this.manualFiles[i].file, extension[extension.length - 1], uploadFilesByDetail);
          if (!createStatus) {
            return;
          }
        }
      }
      return createStatus;
    }
  }

  /**
   * @description Creates Sas Url, blobContainer and save the manualDelivery
   * @param file File to be saved
   * @param extension Extension of the file
   * @returns {Promise<boolean>} Promise with flag if creation was done
   */
  public async createFile(file: File, extension: string, uploadFilesByDetail?: object): Promise<boolean> {
    const reader = new FileReader();
    let sasUrl: string;
    let blobName: string;
    let MIMEType: string;

      return new Promise<boolean>((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const resultSAS = await this.trackingProvider.generateSAS('.' + extension.toLowerCase());
          sasUrl = resultSAS[KEY_SASURL];
          blobName = resultSAS[BLOBNAMEPROPERTY];
          MIMEType = await this.blobProvider.getMIMEtype(extension.toLowerCase());
          const blob = new Blob([file], { type: MIMEType });
          await this.blobProvider.sendBlob(sasUrl, blob).catch(() => false);
          const body = await this.bodyGenerator(blobName, MIMEType, uploadFilesByDetail);
          const resultCreate = await this.trackingProvider.createEvidence(this.shipmentOid, this.detailOid, body[KEY_TYPE], body)
            .catch(() => false);
          if (resultCreate.hasOwnProperty(KEY_SHIPMENTREQUEST)) {
            resolve(true);
          } else {
            resolve(false);
          }
        };
      });
  }

  /**
   * @description Creates the body evidence without attachment to be save
   * @return {object} The body with manualDelivery information
   */
  public async bodyEvidenceWithNoFile(): Promise<object> {
    const deliveryType = this.manualDeliveryForm.value.deliveryType;
    const hourSelected = this.manualDeliveryForm.value.deliveryHour.split(':');
    const fileType = this.manualDeliveryForm.value.fileType;
    const timeStamp = new Date(this.manualDeliveryForm.value.deliveryDate).setHours(hourSelected[0], hourSelected[1]);
    const body = {
      detailOid: this.detailOid,
      timestamp: new Date(timeStamp),
      type: _.isEqual(fileType, this.manualDeliveryLabelsTranslated.evidence) ? PICTURE.toLocaleLowerCase() : INCIDENCE.toLocaleLowerCase(),
      className: _.isEqual(fileType, this.manualDeliveryLabelsTranslated.evidence) ?
      _.isEqual(deliveryType, this.manualDeliveryLabelsTranslated.rejection) ? PICTURE : SIGNATURE
      : INCIDENCE,
      description: this.manualDeliveryForm.value.fileDescription,
      latitude: !_.isUndefined(this.warehouseInfo) ? this.warehouseInfo[KEY_ADDRESSINFO].latitude : 0,
      longitud: !_.isUndefined(this.warehouseInfo) ? this.warehouseInfo[KEY_ADDRESSINFO].longitude : 0,
      file: {
        _className: EVIDENCEFILE,
        name: fileType,
        contentType: fileType
      },
      user: this.userName
    };

    if (_.isEqual(fileType, this.manualDeliveryLabelsTranslated.evidence)) {
      body[EVIDENCETYPE] = this.manualDeliveryForm.value.fileReason.codigo + ' - ' + this.manualDeliveryForm.value.fileReason.descripcion;
      body[KEY_ORDER] = this.orderOid;
      body[KEY_FOLIO] = this.orderData.identifier;
    } else {
      body[INCIDENCETYPE] = this.manualDeliveryForm.value.fileReason.codigo +
        ' - ' + this.manualDeliveryForm.value.fileReason.descripcion;
    }
    return body;
  }

  /**
   * @description Creates the body to be save
   * @param fileName The name of the File
   * @param contentType The ContentType of the current File
   * @returns {object} The body with manualDelivery information
   */
  public async bodyGenerator(fileName: string, contentType: string, uploadFilesByDetail?: object): Promise<object> {
    if (!uploadFilesByDetail) {
      const fileType = this.manualDeliveryForm.value.fileType;
      const deliveryType = this.manualDeliveryForm.value.deliveryType;
      const hourSelected = this.manualDeliveryForm.value.deliveryHour.split(':');
      const timeStamp = new Date(this.manualDeliveryForm.value.deliveryDate).setHours(hourSelected[0], hourSelected[1]);
      const type = _.isEqual(fileType, this.manualDeliveryLabelsTranslated.evidence)
        ? PICTURE.toLocaleLowerCase() : INCIDENCE.toLocaleLowerCase();
      const body = {
        detailOid: this.detailOid,
        timestamp: new Date(timeStamp),
        type: type,
        className: _.isEqual(fileType, this.manualDeliveryLabelsTranslated.evidence) ?
          _.isEqual(deliveryType, this.manualDeliveryLabelsTranslated.rejection) ? PICTURE : SIGNATURE
          : INCIDENCE,
        description: this.manualDeliveryForm.value.fileDescription,
        latitude: !_.isUndefined(this.warehouseInfo) ? this.warehouseInfo[KEY_ADDRESSINFO].latitude : 0,
        longitud: !_.isUndefined(this.warehouseInfo) ? this.warehouseInfo[KEY_ADDRESSINFO].longitude : 0,
        file: {
          _className: EVIDENCEFILE,
          name: fileName,
          contentType: contentType
        },
        user: this.userName
      };
      if (_.isEqual(fileType, this.manualDeliveryLabelsTranslated.evidence)) {
        body[EVIDENCETYPE] = this.manualDeliveryForm.value.fileReason.codigo + ' - ' + this.manualDeliveryForm.value.fileReason.descripcion;
        body[KEY_ORDER] = this.orderOid;
        body[KEY_FOLIO] = this.orderData.identifier;
      } else {
        body[INCIDENCETYPE] = this.manualDeliveryForm.value.fileReason.codigo +
          ' - ' + this.manualDeliveryForm.value.fileReason.descripcion;
      }

      return body;
    } else {
      const fileType = uploadFilesByDetail[KEY_FILETYPE];
      let deliveryType: string;
      switch (this.orderData) {
        case this.manualDeliveryLabelsTranslated.orderDelivered:
          deliveryType = this.manualDeliveryLabelsTranslated.totalDelivery;
          break;
        case this.manualDeliveryLabelsTranslated.partialOrder:
          deliveryType = this.manualDeliveryLabelsTranslated.partialDelivery;
          break;
        case this.manualDeliveryLabelsTranslated.orderRejected:
          deliveryType = this.manualDeliveryLabelsTranslated.rejection;
          break;
        case this.manualDeliveryLabelsTranslated.noDelivered:
          deliveryType = this.manualDeliveryLabelsTranslated.undelivered;
          break;
        default:
          deliveryType = this.manualDeliveryLabelsTranslated.totalDelivery;
      }
      const type = _.isEqual(fileType, this.manualDeliveryLabelsTranslated.evidence)
        ? PICTURE.toLocaleLowerCase() : INCIDENCE.toLocaleLowerCase();
      const body = {
        detailOid: this.detailOid,
        timestamp: uploadFilesByDetail[KEY_TIMESTAMP],
        type: type,
        className: _.isEqual(fileType, this.manualDeliveryLabelsTranslated.evidence) ?
          _.isEqual(deliveryType, this.manualDeliveryLabelsTranslated.rejection) ? PICTURE : SIGNATURE
          : INCIDENCE,
        description: uploadFilesByDetail[KEY_DESCRIPTION],
        latitude: !_.isUndefined(this.warehouseInfo) ? this.warehouseInfo[KEY_ADDRESSINFO].latitude : 0,
        longitud: !_.isUndefined(this.warehouseInfo) ? this.warehouseInfo[KEY_ADDRESSINFO].longitude : 0,
        file: {
          _className: EVIDENCEFILE,
          name: fileName,
          contentType: contentType
        }
      };
      if (_.isEqual(fileType, this.manualDeliveryLabelsTranslated.evidence)) {
        body[EVIDENCETYPE] = uploadFilesByDetail[EVIDENCETYPE];
        body[KEY_ORDER] = this.orderOid;
        body[KEY_FOLIO] = this.orderData.identifier;
      } else {
        body[INCIDENCETYPE] = uploadFilesByDetail[INCIDENCETYPE];
      }

      return body;
    }
  }

  /**
   * @description Update the current order's status
   * @param orderOid The ObjectId of the current order
   */
  public async updateOrderStatus(orderOid: string): Promise<void> {
    const body = {
      orderIds: [orderOid],
      status: this.getOrderStatus(),
      user: this.userName,
      isManualDelivery: true
    };
    await this.orderProvider.updateOrderStatus(this.tenantId, body);
  }

  /**
   * @description Verify which status should be use to update the order
   * @returns {string} The status to be updated
   */
  public getOrderStatus(): string {
    let orderStatus: string;
    switch (this.manualDeliveryForm.value.deliveryType) {
      case this.manualDeliveryLabelsTranslated.totalDelivery:
        orderStatus = ORDERSTATUS[0];
        break;
      case this.manualDeliveryLabelsTranslated.partialDelivery:
        orderStatus = ORDERSTATUS[1];
        break;
      case this.manualDeliveryLabelsTranslated.rejection:
        orderStatus = ORDERSTATUS[2];
        break;
      case this.manualDeliveryLabelsTranslated.undelivered:
        orderStatus = ORDERSTATUS[3];
        break;
      default:
        orderStatus = ORDERSTATUS[4];
    }
    return orderStatus;
  }

  /**
   * @description Validation if the selected date and hour is lower than current date and hour
   * @returns {Promise<boolean>} Returns true or false according to conditional
   */
  public async isValidTimeStamp(): Promise<boolean> {
    const hourSelected = this.manualDeliveryForm.value.deliveryHour.split(':');
    const timeStamp = new Date(this.manualDeliveryForm.value.deliveryDate).setHours(hourSelected[0], hourSelected[1]);
    const currentDate = new Date();

    if (new Date(timeStamp) > currentDate) {
      this.toast.warningAlert(this.manualDeliveryLabelsTranslated.timestampError);
      return false;
    } else {
      return true;
    }
  }

  /**
   * @description Get all the information from visit done
   */
  public async getVisitsDone(filesUpdated?: any): Promise<void> {
    if (filesUpdated) {
      this.infoOrder[KEY_DETAILDATA] = filesUpdated[0];
    }
    this.infoDeliveryDetail = [];
    const infoDeliveryDetailAux = [];
    const groupedEvidences = _.groupBy(this.infoOrder[KEY_DETAILDATA].evidencias.foto, KEY_TIMESTAMP);
    const groupedIncidences = _.groupBy(this.infoOrder[KEY_DETAILDATA].incidencias, KEY_TIMESTAMP);
    const warehouseName = !_.isUndefined(this.warehouseInfo)
      ? this.warehouseInfo[KEY_WAREHOUSENAME] : this.manualDeliveryLabelsTranslated.noInfo;
    this.currentVisitNumber = 0;
    let index = 0;
    for (const inTimestamp in groupedEvidences) {
      if (inTimestamp) {
        await this.getFileEvidences(groupedEvidences[inTimestamp]).then(() => {
          const deliveryDetailBody = {
            orderId: this.orderOid,
            detailId: this.detailOid,
            _id: groupedEvidences[inTimestamp][0].folio,
            numVisit: index + 1,
            warehouseName: warehouseName,
            state: !_.isUndefined(this.warehouseInfo) ? this.warehouseInfo[KEY_ADDRESSINFO].state : '',
            municipality: !_.isUndefined(this.warehouseInfo) ? this.warehouseInfo[KEY_ADDRESSINFO].municipality :
            this.manualDeliveryLabelsTranslated.noInfo,
            date: groupedEvidences[inTimestamp][0].timestamp,
            description: !groupedEvidences[inTimestamp][0].description ? this.manualDeliveryLabelsTranslated.noInfo :
              groupedEvidences[inTimestamp][0].description,
            type: this.manualDeliveryLabelsTranslated.evidence,
            reason: groupedEvidences[inTimestamp][0].evidenceType,
            reasonDescription: !groupedEvidences[inTimestamp][0].description ? this.manualDeliveryLabelsTranslated.noInfo :
              groupedEvidences[inTimestamp][0].description,
            files: this.imagesEvidences,
            timestamp: new Date(inTimestamp)
          };
          infoDeliveryDetailAux.push(deliveryDetailBody);
          index++;
        });
      }
    }

    for (const inTimestamp in groupedIncidences) {
      if (inTimestamp) {
        await this.getFileIncidences(groupedIncidences[inTimestamp]).then(() => {
          const warehouseNameValue = !_.isUndefined(this.warehouseInfo)
            ? this.warehouseInfo[KEY_WAREHOUSENAME] : this.manualDeliveryLabelsTranslated.noInfo;
          const deliveryDetailBody = {
            orderId: this.orderOid,
            detailId: this.detailOid,
            _id: this.manualDeliveryLabelsTranslated.noInfo,
            numVisit: index + 1,
            warehouseName: warehouseNameValue,
            state: !_.isUndefined(this.warehouseInfo) ? this.warehouseInfo[KEY_ADDRESSINFO].state : '',
            municipality: !_.isUndefined(this.warehouseInfo) ?
              this.warehouseInfo[KEY_ADDRESSINFO].municipality : this.manualDeliveryLabelsTranslated.noInfo,
            date: groupedIncidences[inTimestamp][0].timestamp,
            description: groupedIncidences[inTimestamp][0].descripcion,
            type: this.manualDeliveryLabelsTranslated.incidence,
            reason: groupedIncidences[inTimestamp][0].tipo,
            reasonDescription: groupedIncidences[inTimestamp][0].descripcion,
            files: this.imagesIncidences,
            timestamp: new Date(inTimestamp)
          };
          infoDeliveryDetailAux.push(deliveryDetailBody);
          index++;
        });
      }
    }
    this.infoDeliveryDetail = infoDeliveryDetailAux;
    this.subtitleInfo = {
      subtitle: this.manualDeliveryLabelsTranslated.visitsDone,
      number: index.toString(),
      title: this.manualDeliveryLabelsTranslated.noVisitRegistered
    };

    this.currentVisitNumber = index;
    index > 0 ? this.hasVisits = true : this.hasVisits = false;
  }

  /**
   * @description Upload a new image to the same visit
   * @param filesToUpload Param received from app detail component
   */
  public async uploadByDetail(filesToUpload): Promise<void> {
    let infoOrderAux: object;
    this.toast.processingAlert();
    this.manualFiles = filesToUpload[KEY_FILES];
    try {
      const isDeliveryCreated = await this.createDelivery(filesToUpload);
      if (isDeliveryCreated) {
        await this.updateOrderStatus(this.orderOid);
        await this.orderProvider.updateManualDelivered({ ordersIds: [this.orderOid], manualDelivered: true }).then(async () => {
          this.toast.closeProcessing();
          this.toast.successAlert(this.manualDeliveryLabelsTranslated.deliveryRegistered);
          this.manualFiles = [];
          await this.shipmentService.getOneShipment(this.shipmentName).then(async (resp) => {
            infoOrderAux = resp.detalles.filter(e => _.isEqual(e._id, this.detailOid));
            await this.getVisitsDone(infoOrderAux);
          });
        });
      } else {
        this.toast.closeProcessing();
        this.toast.errorAlert(this.manualDeliveryLabelsTranslated.requestFailed);
        this.manualFiles = [];
      }
    } catch (error) {
      this.toast.closeProcessing();
      this.toast.errorAlert(this.manualDeliveryLabelsTranslated.requestFailed);
      this.manualFiles = [];
    }
  }

  /**
  *@description This function get evidences of detail
  *@returns void
  **/
  public async getFileEvidences(evidenceToFind): Promise<void> {
    const ids = { evidencesFile: [] };
    evidenceToFind.forEach((element) => {
      ids.evidencesFile.push({ _id: element.img });
    });
    const images = await this.evidenceProvider.getByOids(ids);
    this.setImages(images);
  }

  /**
  *@description This function set array of images
  *@params indexPosition = index of image select
  *@returns void
  **/
  public async setImages(imgs: Object): Promise<void> {
    this.imagesEvidences = [];
    _.forEach(imgs, (value) => {
      if (value) {
        this.imagesEvidences.push(URL + value.nombre);
      } else { this.imagesEvidences.push(NOTIMAGE); }
    });
  }

  /**
  *@description This function get incidences of detail
  *@returns void
  **/
  public async getFileIncidences(incidenceToFind): Promise<void> {
    const ids = { evidencesFile: [] };
    incidenceToFind.forEach((element) => {
      if (element.img !== null) {
        ids.evidencesFile.push({ _id: element.img });
      }
    });
    const image = await this.evidenceProvider.getByOids(ids);
    if (Object.keys(image).length >= 1) {
      this.setImagesIncidences(image);
    } else { this.imagesIncidences.push(NOTIMAGE); }
  }

  /**
  *@description This function set array of images
  *@params indexPosition = index of image select
  *@returns void
  **/
  public setImagesIncidences(imgs: Object): void {
    _.forEach(imgs, (value) => {
      if (value) {
        this.imagesIncidences.push(URL + value.nombre);
      } else { this.imagesIncidences.push(NOTIMAGE); }
    });
  }

  /**
   * @description Reset fileReason's value when fileType selectionn changes
   */
  public resetFileReason(): void {
    this.manualDeliveryForm.patchValue({ fileReason: null });
  }

  /**
   * @description Enables or disables the fields depending on selection of delivery type
   */
  public deliveryTypeSelection(): void {
    const deliveryType = this.manualDeliveryForm.get(KEY_DELIVERYTYPE).value;
    if (deliveryType === this.typeForm.rejection) {
      this.manualDeliveryForm.patchValue({ rejectedBoxes: this.orderData.boxes ? this.orderData.boxes : 0 });
      this.manualDeliveryForm.patchValue({ rejectedPallets: this.orderData.pallets ? this.orderData.pallets : 0 });
      this.manualDeliveryForm.patchValue({ rejectedPieces: this.orderData.pieces ? this.orderData.pieces : 0 });
      this.manualDeliveryForm.controls[KEY_REJECTEDPIECES].disable();
      this.manualDeliveryForm.controls[KEY_REJECTEDBOXES].disable();
      this.manualDeliveryForm.controls[KEY_REJECTEDPALLETS].disable();
      this.isRejectionEdit = false;
      this.isCompleteRejection = true;
      this.isRejection = true;
    } else if (deliveryType === this.typeForm.partialDelivery) {
      this.manualDeliveryForm.controls[KEY_REJECTEDPIECES].enable();
      this.manualDeliveryForm.controls[KEY_REJECTEDBOXES].enable();
      this.manualDeliveryForm.controls[KEY_REJECTEDPALLETS].enable();
      this.isRejectionEdit = true;
      this.isCompleteRejection = false;
      this.isRejection = true;
    } else {
      this.manualDeliveryForm.patchValue({ rejectedBoxes: 0 });
      this.manualDeliveryForm.patchValue({ rejectedPallets: 0 });
      this.manualDeliveryForm.patchValue({ rejectedPieces: 0 });
      this.manualDeliveryForm.patchValue({ skus: SPACE });
      this.manualDeliveryForm.patchValue({ rejectionResponsible: {id: SPACE, name: SPACE} });
      this.isRejectionEdit = false;
      this.isRejection = false;
    }
  }

  /**
   * @description Enable edition of rejection if it's just partial delivery
   */
  public enableRejectionEdition(): void {
    this.isRejectionEdit = true;
    this.manualDeliveryForm.controls[KEY_REJECTEDPIECES].enable();
    this.manualDeliveryForm.controls[KEY_REJECTEDBOXES].enable();
    this.manualDeliveryForm.controls[KEY_REJECTEDPALLETS].enable();
  }

  /**
   * @description Disables rejection form to avoid modifications
   */
  public acceptRejectionMerchandise(): void {
    this.isRejectionEdit = false;
    this.manualDeliveryForm.controls[KEY_REJECTEDPIECES].disable();
    this.manualDeliveryForm.controls[KEY_REJECTEDBOXES].disable();
    this.manualDeliveryForm.controls[KEY_REJECTEDPALLETS].disable();
  }

  /**
   * @description Validates if the edition of rejection could be saved
   * @param {string} inputValue value to validate
   * @param {string} type type of input among pieces, pallets and boxes
   */
  public enableSaveEdition(inputValue: string, type: string): void {
    if (inputValue) {
      const value = Number(inputValue);
      let valid = false;
      let validValue;
      switch (type) {
        case this.typeForm.pieces:
          validValue = this.orderData.pieces ? this.orderData.pieces : 0;
          valid = value <= validValue;
          break;
        case this.typeForm.boxes:
          validValue = this.orderData.boxes ? this.orderData.boxes : 0;
          valid = value <= validValue;
          break;
        case this.typeForm.pallets:
          validValue = this.orderData.pallets ? this.orderData.pallets : 0;
          valid = value <= validValue;
          break;
        default:
          valid = false;
          break;
      }
      if (valid) {
        if (value && value >= 0) {
          if (value % 1 !== 0) {
            if (type === this.typeForm.pallets) {
              this.isSaveEditionEnabled = false;
            } else {
              this.isSaveEditionEnabled = true;
              this.toast.warningAlert(this.manualDeliveryLabelsTranslated.toastWarnRejectionPositiveIntegerBoxesPieces);
            }
          } else {
            this.isSaveEditionEnabled = false;
          }
        } else if (value && value < 0) {
          this.isSaveEditionEnabled = true;
          if (type === this.typeForm.pallets) {
            this.toast.warningAlert(this.manualDeliveryLabelsTranslated.toastWarnRejectionPositivePallets);
          } else {
            this.toast.warningAlert(this.manualDeliveryLabelsTranslated.toastWarnRejectionPositiveIntegerBoxesPieces);
          }
        } else {
          this.isSaveEditionEnabled = true;
        }
      } else {
        this.isSaveEditionEnabled = true;
        this.toast.warningAlert(this.manualDeliveryLabelsTranslated.toastWarnRejectionExceedQuantity);
      }
    }
  }

  /**
   * @description Init list of rejection responsible
   */
  private initRejectionResponsibleList(): void {
    const rejectionList = Object.keys(this.manualDeliveryRejectionsLabelsTranslated)
      .map(key => ({id: key, name: this.manualDeliveryRejectionsLabelsTranslated[key]}));
    this.rejectionResponsibleList = _.sortBy(rejectionList, [(rejectionResponsible) => rejectionResponsible.name]);
  }

  /**
   * @description Builds manual delivered body to send
   * @returns {Object} Generated object
   */
  private buildUpdateManualDeliveredBody(): Object {
    const objectRejection = this.manualDeliveryForm.get(KEY_REJECTIONRESPONSIBLE).value;
    objectRejection.name = this.translatedValue(objectRejection.name, this.manualDeliveryRejectionsTranslated);
    let body = {};
    if (this.isRejection) {
      body = {
        ordersIds: [this.orderOid],
        manualDelivered: true,
        rejectedPieces: this.manualDeliveryForm.get(KEY_REJECTEDPIECES).value,
        rejectedBoxes: this.manualDeliveryForm.get(KEY_REJECTEDBOXES).value,
        rejectedPallets: this.manualDeliveryForm.get(KEY_REJECTEDPALLETS).value,
        skus: this.manualDeliveryForm.get(KEY_SKUS).value,
        rejectionResponsible: objectRejection,
        warehouseMerchandise: this.manualDeliveryForm.get(KEY_WAREHOUSEMERCHANDISE).value
      };
    } else {
      body = {
        ordersIds: [this.orderOid],
        manualDelivered: true,
        rejectedPieces: this.orderData.pieces,
        rejectedBoxes: this.orderData.boxes,
        rejectedPallets: this.orderData.pallets,
      };
    }

    return body;
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   * @return {void}
   */
   public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getManualDeliveryLabels();
        await this.getManualDeliveryRejectionLabels();
        await this.getManualDeliveryRejectionTextLabels();
        await this.getManualDeliveryStatusCatalog();
        await this.getManualDeliveryInfoStatusCatalog();
        this.language = this._languageTranslateService.getLanguage();
        this.setDeliveryList();
        this.setFileTypeList();
        this.setTypeForm();
        this.initRejectionResponsibleList();
        this.getOrderData().then(() => {
          this.getVisitsDone();
        });
      },
      (error) => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets manual delivery Status Catalog from translate JSON files.
   * @return {Promise<void>}
   */
  public async getManualDeliveryStatusCatalog(): Promise<void> {
    this.manualDeliveryStatusCatalog =
      await this._languageTranslateService.getLanguageLabels(LanguageConstants.MANUAL_DELIVERY_STATUS_CATALOG)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets manual delivery information status Catalog from translate JSON files.
   * @return {Promise<void>}
   */
  public async getManualDeliveryInfoStatusCatalog(): Promise<void> {
    this.manualDeliveryInfoStatusCatalog =
      await this._languageTranslateService.getLanguageLabels(LanguageConstants.MANUAL_DELIVERY_INFO_STATUS_CATALOG)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets manual delivery Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getManualDeliveryLabels(): Promise<void> {
    this.manualDeliveryLabelsTranslated = await this._languageTranslateService.getLanguageLabels(LanguageConstants.MANUAL_DELIVERY_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets manual delivery Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getManualDeliveryRejectionLabels(): Promise<void> {
    this.manualDeliveryRejectionsLabelsTranslated =
      await this._languageTranslateService.getLanguageLabels(LanguageConstants.MANUAL_DELIVERY_REJECTION_RESPONSIBLE_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets the delivery rejection text from translate JSON files.
   * @return {Promise<void>}
   */
  public async getManualDeliveryRejectionTextLabels(): Promise<void> {
    this.manualDeliveryRejectionsTranslated =
      await this._languageTranslateService.getLanguageLabels(LanguageConstants.MANUAL_DELIVERY_REJECTION_RESPONSIBLE)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Get the list of status for report
   * @returns {Array<string>} The status of shipment translated
   */
  public getStatusList(): Array<string> {
    return [
      this.manualDeliveryLabelsTranslated.assigned,
      this.manualDeliveryLabelsTranslated.cancelled,
      this.manualDeliveryLabelsTranslated.unassigned,
      this.manualDeliveryLabelsTranslated.inTransit,
      this.manualDeliveryLabelsTranslated.rejected,
      this.manualDeliveryLabelsTranslated.delivered,
      this.manualDeliveryLabelsTranslated.notDelivered,
      this.manualDeliveryLabelsTranslated.partial,
      this.manualDeliveryLabelsTranslated.released
    ];
  }

  /**
   * @description Get the status general for report
   * @param {string} status of order to display
   * @returns {string} The status translated
   */
  public getStatusTranslated(status: string): string {
    let statusTranslated: string;
    for (const statusValue of this.getStatusList()) {
      if (this.manualDeliveryStatusCatalog.hasOwnProperty(statusValue) &&
      this.manualDeliveryStatusCatalog[statusValue].toLowerCase() ===  status.toLowerCase()) {
        statusTranslated = statusValue;
        break;
      }
    }

    return statusTranslated ?? status;
  }

  /**
   * @description Translated value for some equivalence object
   * @param {string} value to find inthe second param
   * @param {any} statusObjectEquivalence Object for shearch
   * @returns {string} The value translated
   */
  public translatedValue(valueToSearch: string, objectEquivalence: any): string {
    try {
      let valueTranslated: string;
      if (objectEquivalence.hasOwnProperty(valueToSearch)) {
        valueTranslated = objectEquivalence[valueToSearch];
      }

      return valueTranslated ?? valueToSearch;
    } catch (error) {
      return null;
    }
  }

  /**
   * @description Translates the suffix of some warning labels
   * @param {string} value to evaluate
   * @returns {string} The value translated
   */
  public getSuffix(value?: string): string {
    return this.language === KEY_LANGUAGE_DEFAULT ? value : AppConstants.EMPTY_STRING;
  }
}
