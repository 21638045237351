import { Component, Inject, OnInit } from '@angular/core';
import { DialogEditFreightCostLabel } from '../../../interfaces';
import { DialogEditFreightCostLabels, DialogEditFreightCostTags } from './dialog-edit-freight-cost.labels';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

@Component({
  selector: 'app-dialog-edit-freight-cost',
  templateUrl: './dialog-edit-freight-cost.component.html',
  styleUrls: ['../../../app.component.scss']
})
export class DialogEditFreightCostComponent implements OnInit {
  public costEdited: Boolean;
  public editFreightCostFormGroup: FormGroup;
  public freightRateBody: Object;
  public labels: DialogEditFreightCostLabel;
  public newRate: Number;
  public rate: Number;

  constructor(
    public dialogRef: MatDialogRef<DialogEditFreightCostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly builder: FormBuilder,
    private toast: ToastrAlertsService
  ) { this.labels =  DialogEditFreightCostTags; }

  ngOnInit() {
    this.rate = this.data.cost;
    this.newRate = 0;
    this.initForm(this.builder);
  }

  public onClickClose(): void {
    this.dialogRef.close(undefined);
  }

  public edited(): void {
    if (this.editFreightCostFormGroup.value.rate === this.data.cost) {
      this.costEdited = false;
      this.toast.warningAlert(DialogEditFreightCostLabels.editWarning);
    } else {
      this.costEdited = true;
      this.newRate = this.editFreightCostFormGroup.value.rate;
    }
  }

  public onUpdate(): void {
    this.dialogRef.close(this.newRate);
  }

  private initForm(fb: FormBuilder): void {
    this.editFreightCostFormGroup = fb.group({
      rate: new FormControl(this.rate, [Validators.required]),
    });
  }
}
