import { Injectable } from '@angular/core';

import { OrderForShipment, ShipmentRowExtended, ShipperConfiguration } from '../../interfaces';
import { ShipmentDataBody, StopsBody } from '../../interfaces/shipment';
import { ShipmentProvider } from '../../providers/shipments/shipment-provider.service';
import { ShipmentRequestStatusTEP } from '../../enums/shipment_request_status';
import { SHIPPERS_NEED_WMS_CONFIRM } from '../../constants/shippers-ids';
import { ShipmentType } from '../../enums/shipmentType';
import { ToastrAlertsService } from '../utils/toastr-alerts.service';
import { WarehouseProvider } from '../../providers/warehouse/warehouse-provider.service';

@Injectable()
export class ShareLoadPlanService {
  public loadPlanInfo: object;
  public stopsData: Array<StopsBody>;
  constructor(
    private shipmentProvider: ShipmentProvider,
    private toast: ToastrAlertsService,
    private wareHouseProvider: WarehouseProvider
  ) {
    this.stopsData = [];
  }

  /**
   * @description Checks if the shipper needs confirmation by wms to can confirm the shipments and if Exists the configuration
   * to send the load plan Data to WMS
   * @param {string} shipperId from shipper to validate
   * @param {ShipperConfiguration} shipperConfig Configuration from shipper
   * @returns {boolean} Validation result true/false
   */
  public shipperNeedsConfirmationByWms(shipperId: string, shipperConfig: ShipperConfiguration ): boolean {
    return (SHIPPERS_NEED_WMS_CONFIRM.includes(shipperId) && shipperConfig && shipperConfig.enableWmsInterface );
  }

  /**
   * @description Checks if shipment and shipper config to share load plan data
   * @param {string} tenantId Id from shipper
   * @param {ShipperConfiguration} shipperConfig Data about shipper config
   * @param {ShipmentDataBody} shipment Data about shipment to create/edit
   * @param {string} userName Username on shipper
   * @param {string} successMsg Success message to display if the operation is successfully
   * @param {string} errorMsg Error message to display if the operation fails
   */
  public async checkShipmentAndShareLoadPlan(tenantId: string, shipperConfig: ShipperConfiguration, shipment: ShipmentDataBody,
    userName: string, successMsg: string, errorMsg: string): Promise<void> {
    if (this.validateConfigToShareLoadPlan(tenantId, shipperConfig, shipment)) {
      await this.shareLoadPlanToWms(shipment, tenantId, userName, successMsg, errorMsg);
    }
  }

  /**
   * @description Checks if shipment is valid to share load plan data
   * @param shipmentStatus Status of shipment
   * @param shipmentTripType Trip trpe of shipment
   * @param isLoadPlanSentToWms Flag to kow if the load plan has been sent to WMS before
   * @param isLoadPlanConfirmedByWms Flag to know if the load plan data has been confirmated by WMS
   * @returns {boolean} True or false depending if the shipment is valid
   */
  public isShipmentValidToShareLoadPlanData(shipmentStatus: string, shipmentTripType: string, isLoadPlanSentToWms: boolean,
    isLoadPlanConfirmedByWms: boolean): boolean {

    return (shipmentStatus === ShipmentRequestStatusTEP.Assigned && shipmentTripType !== ShipmentType.Courier
      && shipmentTripType !== ShipmentType.Collection && !isLoadPlanSentToWms && !isLoadPlanConfirmedByWms);
  }

  /**
   * @description Validates if shipper needs confirmation by Wms and if exists the configuration for that
   * @param {string} shipperId Id from shipper
   * @param {ShipperConfiguration} shipperConfig Data about shipper
   * @param {ShipmentDataBody | ShipmentRowExtended} shipment Data of shipment to validate and share load plan data
   * @returns {boolean} true or false if the shipment and shipper are valid
   */
  public validateConfigToShareLoadPlan(shipperId: string, shipperConfig: ShipperConfiguration,
    shipment: ShipmentDataBody | ShipmentRowExtended): boolean {
    return (this.shipperNeedsConfirmationByWms(shipperId, shipperConfig) &&
      this.isShipmentValidToShareLoadPlanData(shipment.status, shipment.tripType, shipment.hasBeenSendedToWms,
      shipment.confirmationByInterface));
  }

  /**
   * @description creates an object with shipment data
   * @param {ShipmentRowExtended} shipmentsData Data about shipment
   * @returns {<Array<Object>>} Array with shipment info created
   */
  public async createShipmentBody(shipmentsData: ShipmentDataBody): Promise<Array<object>> {
    const shipmentsBody = [];
    this.loadPlanInfo = {
      shipmentId: shipmentsData.shipmentId,
      internalReference: shipmentsData.internalReference,
      tripType: shipmentsData.tripType,
      origin: shipmentsData.origin.name,
      loadDateTime: shipmentsData.loadStartDate,
      transport: {
        carrier: shipmentsData.transport.transportCarrier,
        vehicleType: shipmentsData.transport.vehicle,
        plate: shipmentsData.transport.plates,
        driver: shipmentsData.transport.driver,
      },
      stops: await this.createStopsBody(shipmentsData.orders),
    };
    shipmentsBody.push(this.loadPlanInfo);

    return shipmentsBody;
  }

  /**
   * @description Filter the orders depending if the are portage orders or not
   * @param {Array<OrderForShipment>} ordersData Orders data from shipment
   * @returns {<Array<StopsBody>>} an array with all stops info
   */
  public async createStopsBody(ordersData: Array<OrderForShipment>): Promise<Array<StopsBody>> {
    const orders = ordersData.filter(order => !order.plannedWarehouse);
    const portageOrders = ordersData.filter(order => order.plannedWarehouse);
    this.stopsData = [];

    if (orders.length) {
      this.createStopsForOrders(orders);
    }
    if (portageOrders.length) {
      await this.createStopsForPortageOrders(portageOrders);
    }

    return this.stopsData;
  }

  /**
   * @description Checks stops for the orders and then creates stops info with that orders info
   * @param {Array<OrderForShipment>} ordersData Array with all orders data
   */
  public createStopsForOrders(ordersData: Array<OrderForShipment>): void {
    const shipmentStops = [...new Set(ordersData.map(item => item.stop))];
    for (const stop of shipmentStops) {
      const ordersFromStop = ordersData.filter(order => order.stop === stop);
      const ordersIds = [];
      for (const order of ordersFromStop) {
        const orderId = { id: order.orderId };
        ordersIds.push(orderId);
      }
      const orderBody = {
        name: ordersFromStop[0].destination.name,
        address: ordersFromStop[0].destination.address,
        orders: ordersIds
      };
      this.stopsData.push(orderBody);
    }
  }

  /**
   * @description Checks stops for the portage orders and then creates stops info with that orders info
   * @param {Array<OrderForShipment>} portageOrdersData data about orders
   */
  public async createStopsForPortageOrders(portageOrdersData: Array<OrderForShipment>): Promise<void> {
    const stopsOfShipment = [...new Set(portageOrdersData.map(item => item.stop))];
    for await (const stop of stopsOfShipment) {
      const ordersFromStop = portageOrdersData.filter(order => order.stop === stop);
      const PlannedWarehouseData = await this.wareHouseProvider.getWarehouseByOid(ordersFromStop[0].plannedWarehouse._id);
      const ordersIds = [];
      for (const order of ordersFromStop) {
        const orderId = { id: order.orderId };
        ordersIds.push(orderId);
      }
      const orderBody = {
        name: ordersFromStop[0].plannedWarehouse.name,
        address: PlannedWarehouseData.addressInfo.address,
        orders: ordersIds
      };
      this.stopsData.push(orderBody);
    }
  }

  /**
   * @description Method to change the flag hasBeenSendedToWms depending if the share of load data has been succesfully
   * @param {string} tenantId Id of shipper
   * @param {ShipmentDataBody} shipmentData Data of shipment edited/created
   * @param {string} username name of user
   * @param {boolean} successShare Flag to know if load data has been shared succesfully to change the flag of shipment
   */
  public async changeSendToWmsStatus(tenantId: string, shipmentData: ShipmentDataBody, username: string, successShare: boolean):
    Promise<void> {
    if (successShare) {
      shipmentData.hasBeenSendedToWms = true;
    } else {
      shipmentData.hasBeenSendedToWms = false;
    }
    await this.shipmentProvider.updateShipment(tenantId, shipmentData.shipmentId, shipmentData, username);
  }

  /**
   * @description checks if data of load plan can be shared with WMS
   * @param {ShipmentDataBody} shipmentData Data about shipment to create/edit
   * @param {string} shipperId Id from shipper
   * @param {string} userName Username on shipper
   * @param {string} successMsg Success message to display if the operation is successfully
   * @param {string} errorMsg Error message to display if the operation fails
   */
  public async shareLoadPlanToWms(shipmentData: ShipmentDataBody, shipperId: string, userName: string,
      successMsg: string, errorMsg: string): Promise<void> {
    try {
      const shipmentBody = await this.createShipmentBody(shipmentData);
      const loadDataToShare = { data: shipmentBody };
      await this.shipmentProvider.shareLoadPlanData(loadDataToShare, shipperId);
      await this.changeSendToWmsStatus(shipperId, shipmentData, userName, true);
      this.toast.successAlert(successMsg);
    } catch (error) {
      this.toast.errorAlert(errorMsg);
      await this.changeSendToWmsStatus(shipperId, shipmentData, userName, false);
    }
  }
}
