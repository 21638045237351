import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public userName: string;

  constructor(
    private _appService: AppService,
    private router: Router
  ) {
    this.userName = this._appService.getShipperNameCookie();
  }
}
