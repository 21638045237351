<div class="title">
  <span>{{ title }}</span>
  <div class="count" [ngClass]="{'disabled': noInfo, 'move-left': !downloadAvailable}">
    <span *ngIf="!noInfo">{{ actualPosition + 1 }}/{{ images?.length }}</span>
    <span *ngIf="noInfo">0/0</span>
    <button
      *ngIf="downloadAvailable"
      [class.disabled]="noInfo"
      class="download-icon pointer"
      matTooltip="{{ tooltipDownload ? (tooltipDownload | translate) : 'miniCarouselTags.tooltipDownload' | translate }}"
      matTooltipPosition="above"
      mat-icon-button
      [matMenuTriggerFor]="downloadMenu">
    <img
      src="../../../assets/icons/more_vert-24px.svg"
      alt="downloadMenu">
    </button>

    <mat-menu #downloadMenu="matMenu">
      <button mat-menu-item (click)="onDownloadEvidences()">
        <img
          src="../../../assets/icons/save_alt-24px.svg"
          alt="save">
          <span class="download-evidences-button">{{ 'miniCarouselTags.separatedFiles' | translate }} ({{ images?.length }})</span>
        </button>
        <button
          mat-menu-item
          [disabled]="disableOneFileButton"
          (click)="onDownloadPDFEvidences()">
          <img
          src="../../../assets/icons/sim_card_download-24px.svg"
          alt="downloadAll">
          <span class="download-evidences-button">{{ 'miniCarouselTags.oneFile' | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>

<hr class="carousel-hr">

<div *ngIf="!noInfo" class="box-image">
  <mat-icon
    (click)="previousPicture(actualPosition)"
    class="pointer"
    fxFlex="30">
    <img
      class="icons"
      src='../../../assets/back.svg'
      alt="Back">
  </mat-icon>
  <div
    fxFlex="40"
    *ngIf="images && validatePDFExtension(images[actualPosition])"
    class="pdf-container">
      <img
        matTooltip="{{ 'miniCarouselTags.tooltipImage' | translate }}"
        [matTooltipShowDelay]="2000"
        matTooltipPosition="above"
        src="../../../../assets/pdf_Icon.png"
        class="pdf pointer"
        alt="Actual evidence"
        (click)="onViewImage(images[actualPosition])">
  </div>
  <img
    matTooltip="{{ 'miniCarouselTags.tooltipImage' | translate }}"
    [matTooltipShowDelay]="2000"
    matTooltipPosition="above"
    *ngIf="images && !validatePDFExtension(images[actualPosition])"
    src="{{images[actualPosition]}}"
    class="images pointer"
    fxFlex="40"
    alt="Actual evidence"
    onerror="src='../../../assets/notImage.png'"
    (click)="onViewImage(images[actualPosition])">
  <mat-icon
    (click)="nextPicture(actualPosition)"
    class="pointer"
    fxFlex="30">
    <img
      class="icons"
      src='../../../assets/next.svg'
      alt="Next">
  </mat-icon>
</div>

<div *ngIf="noInfo" class="legend box-image">({{ 'miniCarouselTags.noInfo' | translate }})</div>