import { Injectable } from '@angular/core';

import { LocalStorageService } from './../utils/local-storage.service';
import { RoadnetProvider } from '../../providers/roadnet/roadnet-provider.service';
import { ToastrAlertsService } from '../utils/toastr-alerts.service';

import { LOCALSTORAGE_KEYS } from './../../constants/localstorage/localstorage.constants';
import { RoadnetServiceLabels } from './roadnet-service.labels';
import { OrderClass, RoadnetToken, TMSRoadnetConfig } from '../../interfaces/roadnet/auth';
import { UserInfoLocalStorage } from './../../interfaces/user';

import * as _ from 'lodash';

const ASCII_KEY = 'ascii';
const BASE64_KEY = 'base64';
const EMPTY_STRING = '';
@Injectable({
  providedIn: 'root'
})
export class RoadnetService {
  private token: RoadnetToken;
  private config: TMSRoadnetConfig;
  constructor(
    private localstorage: LocalStorageService,
    private roadnetProvider: RoadnetProvider,
    private toast: ToastrAlertsService
  ) {
    this.handleRoadnetIntegration();
  }

  /**
   * @description Gets the roadnet config by the logged in user
   */
  private getShipperRoadnetConfig(): void {
    const userInfo: UserInfoLocalStorage = this.localstorage.getItemAsObject(LOCALSTORAGE_KEYS.USER_INFO_KEY);
    if (userInfo && userInfo.infoUsuario.embarcador && userInfo.infoUsuario.embarcador.configuracion.roadnetConfig) {
      this.setConfig(userInfo.infoUsuario.embarcador.configuracion.roadnetConfig);
    }
  }

  public setConfig(config: TMSRoadnetConfig): void {
    this.config = config;
  }

  public getConfig(): TMSRoadnetConfig {
    return this.config;
  }

  public removeConfig() {
    this.config = undefined;
  }

  /**
  * @description Get the token from Roadnet with credentials indicated
  */
  public async getRoadnetToken(): Promise<RoadnetToken> {
    if (this.config && this.config.isActive) {
      const encodedBase64Password = this.config.password;
      const buffer = Buffer.from(encodedBase64Password, BASE64_KEY);
      const undecodedPass = buffer.toString(ASCII_KEY);
      const roadnetTokenBodyReq = {
        username: this.config.username,
        password: undecodedPass
      };
      await this.roadnetProvider.getRoadnetToken(roadnetTokenBodyReq)
        .then(res => {
          this.token = res;
        })
        .catch(err => {
          this.toast.errorAlert(RoadnetServiceLabels.toastErrorAuth);
        });

      if (this.token) {
        return this.token;
      }
    }
  }

  /**
   * @description Handles the config for this singleton service
   */
  public handleRoadnetIntegration(): void {
    if (!this.config) {
      this.getShipperRoadnetConfig();
    }
  }

  /**
   * @description Checks whether logged in user's shipper has roadnet
   * @returns {boolean} Whether roadnet is active
   */
  public isRoadnetActive(): boolean {
    return this.config ? this.config.isActive : false;
  }

  public getFirstClass(): OrderClass {
    const orderClass = { identifier: EMPTY_STRING, entityKey: EMPTY_STRING, description: EMPTY_STRING };
    return this.config ? _.first(this.config.orderClasses) : orderClass;
  }

  public getSessionDescription(): string {
    return this.config ? this.config.sessionDescription : EMPTY_STRING;
  }
}
