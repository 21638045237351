<div fxLayout="row">
  <div fxFlex="90" class="dialog-input-title">
    {{ data.title }}
  </div>
  <div fxFlex="10" class="align-end-icon">
    <mat-icon style="cursor:pointer" (click)="onClickClose()">
      close
    </mat-icon>
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="center center" class="dialog-input-content">
  <mat-form-field fxFlex="45">
    <input
      id="input-delivery-date"
      matInput
      class="input--pointer"
      [matDatepicker]="picker"
      [placeholder]="labels.deliveryDate"
      readonly
      required
      [(ngModel)]="date"
      [ngModelOptions]="{standalone: true}"
      [min]="minDate"
      (dateChange)="concatenateDateAndHour()"
      (click)="picker.open()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <div fxFlex="10"></div>
  <mat-form-field fxFlex="45">
    <input
      id="input-delivery-hour"
      matInput
      (keydown.enter)="handleKeyEnter($event)"
      type="time"
      (change)="concatenateDateAndHour()"
      [(ngModel)]="hour"
      [ngModelOptions]="{standalone: true}"
      [placeholder]="labels.deliveryHour"
      required>
  </mat-form-field>
</div>

<div fxLayout="row" fxLayoutAlign="flex-end">
  <button
    id="dialog-button1"
    mat-raised-button
    color="primary"
    class="primary-button"
    (click)="onConfirm()"
    [disabled]="!isValidDate">
      {{ data.button1 }}
  </button>
</div>