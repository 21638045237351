export const environment = {

  production: window['appGlobalEnv']['production'],

  appInsightsInstrumentationKey: window['appGlobalEnv']['appInsightsInstrumentationKey'],
  appInsightsRoleName: window['appGlobalEnv']['appInsightsRoleName'],
  appInsightsEnabled: window['appGlobalEnv']['appInsightsEnabled'],
  apiUrl: window['appGlobalEnv']['apiUrl'],
  baseStorageUrl: window['appGlobalEnv']['baseStorageUrl'],
  mainContainer: window['appGlobalEnv']['mainContainer'],
  evidenceContainer: window['appGlobalEnv']['evidenceContainer'],
  trackingUrl: window['appGlobalEnv']['trackingUrl'],
  freightRateApiUrl: window['appGlobalEnv']['freightRateApiUrl'],
  evidenceApiUrl: window['appGlobalEnv']['evidenceApiUrl'],
  tmsIntegratorApiUrl: window['appGlobalEnv']['tmsIntegratorApiUrl'],
  orderApiUrl: window['appGlobalEnv']['orderApiUrl'],
  shipmentApiUrl: window['appGlobalEnv']['shipmentApiUrl'],
  clientUrl: window['appGlobalEnv']['clientUrl'],
  googleMaps: window['appGlobalEnv']['googleMaps'],
  roadnetApiUrl: window['appGlobalEnv']['roadnetApiUrl'],
  languageEnabled: window['appGlobalEnv']['languageEnabled'],
  labelApiUrl: window['appGlobalEnv']['labelApiUrl'],
  serverlessUrl: window['appGlobalEnv']['serverlessUrl'],
  secondaryBlobStorage: window['appGlobalEnv']['secondaryBlobStorage'],
  shipmentRequestApiUrl: window['appGlobalEnv']['shipmentRequestApiUrl'],
  courierConnectionUrl: window['appGlobalEnv']['courierConnectionUrl'],
  tmsIntegratorSystemOid: window['appGlobalEnv']['tmsIntegratorSystemOid'],
  firebase: {
    apiKey: window['appGlobalEnv']['apiKey'],
    authDomain: window['appGlobalEnv']['authDomain'],
    databaseURL: window['appGlobalEnv']['databaseURL'],
    projectId: window['appGlobalEnv']['projectId'],
    storageBucket: window['appGlobalEnv']['storageBucket'],
    messagingSenderId: window['appGlobalEnv']['messagingSenderId'],
    appId: window['appGlobalEnv']['appId']
  },
  notificationApiUrl: window['appGlobalEnv']['notificationApiUrl'],
  tmsWebappMedistikCmr: window['appGlobalEnv']['tmsWebappMedistikCmr'],
};
