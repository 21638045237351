<div class="principal-container">
  <div class="title-container">
    <span class="dialog-title">
      {{ 'dialogPasswordLabels.title' | translate }}
      <strong>
        {{ data.driver.username }}
      </strong>
    </span>
    <mat-icon class="close-icon" (click)="onClickClose()">
      close
    </mat-icon>
  </div>
  <div class="content-container">
    <div class="image">
      <img
        src="{{ image }}"
        width="75%"
        height="75%"
        alt="Dialog image">
      </div>
    <div class="password-form">
      <form [formGroup]="passwordForm">
        <mat-form-field>
          <input
            type="password"
            placeholder="{{ 'dialogPasswordLabels.placeholderNewPassword' | translate }}"
            matInput
            #inputPassword
            formControlName="password"
            [(value)]="password"
            required
            maxlength="16">
          <mat-hint align="end">
            {{ inputPassword.value?.length || 0 }}/16
          </mat-hint>
          <mat-error>
            {{ getErrorMessage() }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            type="password"
            placeholder="{{ 'dialogPasswordLabels.placeholderConfirmPassword' | translate }}"
            matInput
            #inputConfirm
            formControlName="confirmPassword"
            [(value)]="confirmPassword"
            required
            maxlength="16">
          <mat-hint align="end">
            {{ inputConfirm.value?.length || 0 }}/16
          </mat-hint>
          <mat-error>
            {{ getErrorMessage(true) }}
          </mat-error>
        </mat-form-field>
      </form>

    </div>
  </div>
  <div class="buttons-container">
    <button
      mat-button
      class="secondary-button secondary-button--align"
      (click)="onClickCancel()">
      {{ 'dialogPasswordLabels.buttonCancel' | translate }}
    </button>
    <button
      [disabled]="!validPasswords"
      mat-button
      class="primary-button"
      (click)="onClickSave()">
      {{ 'dialogPasswordLabels.buttonSave' | translate }}
    </button>
  </div>
</div>
