import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { WarehouseBody } from '../../interfaces/warehouse';

const apiUrl = environment.apiUrl;

@Injectable()
export class WarehouseProvider {

  constructor(private http: HttpClient, private _appService: AppService) { }

  public async createWarehouse(warehouseBody: any): Promise<object> {
    const shipperOid = this._appService.getShipperOid();
    return await this.http.post<object>(apiUrl + '/shipper/' + shipperOid + '/warehouse', warehouseBody).toPromise();
  }

  public async getWarehouse(getShipperOid?: string): Promise<Array<WarehouseBody>> {
    const shipperOid = this._appService.getShipperOid();
    const param = (getShipperOid === undefined) ? shipperOid : getShipperOid;
    return await this.http.get<Array<WarehouseBody>>(apiUrl + '/shipper/' + param + '/warehouse').toPromise();
  }

  public async updateWarehouse(warehouseOid: string, body: WarehouseBody): Promise<Array<WarehouseBody>> {
    const shipperOid = this._appService.getShipperOid();
    return await this.http.put<Array<WarehouseBody>>(apiUrl + '/shipper/' + shipperOid + '/warehouse/' + warehouseOid, body).toPromise();
  }

  public async getWarehouseByOid(warehouseOid: string): Promise<WarehouseBody> {
    const shipperOid = this._appService.getShipperOid();
    return await this.http.get<WarehouseBody>(apiUrl + '/shipper/' + shipperOid + '/warehouse/' + warehouseOid).toPromise();
  }

  public async getWarehouseByName(warehouseName: string) {
    const shipperId = this._appService.getShipperOid();
    return await this.http.get<WarehouseBody>(`${apiUrl}/shipper/${shipperId}/warehouse?warehouseName=${warehouseName}`).toPromise();
  }

  public getRelatedUserWarehouses(): Observable<Array<WarehouseBody>> {
    const userOId = this._appService.getUserOid();
    const shipperOId = this._appService.getShipperOid();
    return this.http.get<Array<WarehouseBody>>(`${apiUrl}/shipper/${shipperOId}/user/${userOId}/warehouses`);
  }

  /**
   * @description Get RFC warehouses by shipperOid
   * @param {string} shipperOid Current shipper oid provided
   * @returns {Array<string>} An array with warehouses rfc
   */
  public async getRFCWarehousesByShipper(shipperOid: string): Promise<Array<string>> {
    return this.http.get<Array<string>>(`${apiUrl}/shipperOid/${shipperOid}/getRFCWarehouseByShipper`).toPromise();
  }
}
