<mat-expansion-panel [expanded]="isExpanded">
  <mat-expansion-panel-header collapsedHeight='2.5em' expandedHeight='2.5em'>
    <mat-panel-title>
      {{ labels.titleCard }} {{ title }}{{ labels.colon }}
    </mat-panel-title>
  </mat-expansion-panel-header>
    <mat-grid-list cols="4" rowHeight="1:.30">
      <form [formGroup]="filterSearchForm">
        <mat-grid-tile *ngIf="isCarrier">
          <mat-form-field>
            <mat-select
              id="carrierSelect"
              [placeholder]="labels.placeholderCarrier"
              multiple
              required
              formControlName="carriers">
              <mat-option *ngFor="let carrier of carriers" [value]="carrier">{{ carrier.nombre }}</mat-option>
            </mat-select>
            <mat-error>
              {{ labels.placeholderCarrier }} {{ labels._is }} <strong>{{ labels.required }}</strong>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <mat-select
            id="datesRangeTypeSelect"
            [placeholder]="labels.datesRangeType" required
            formControlName="dateRangeType"
            (selectionChange)=dateRangeTypeOnChange($event.value)>
              <mat-option
              *ngFor="let rangeType of datesRangeTypes"
              [value]="rangeType.type">
              {{ rangeType.type }}
               </mat-option>
            </mat-select>
            <mat-error>
              {{ labels.datesRangeType }} {{ labels._is }}
              <strong>
                {{ labels.required }}
              </strong>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <input
            id="beginDateInput"
            class="input--pointer"
            matInput [matDatepicker]="beginDate"
            [placeholder]="labels.beginDate"
            readonly
            required
            formControlName="beginDate"
            (click)="beginDate.open()"
            [min]="minBeginDate"
            [max]="maxBeginDate"
            (dateChange)="beginDateOnChange($event.value)">
            <mat-error>
              {{ labels.beginDate }} {{ labels._is }}
              <strong>
                {{ labels.required }}
              </strong>
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="beginDate"></mat-datepicker-toggle>
            <mat-datepicker #beginDate></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <input
              id="endDateInput"
              #inputEndDate class="input--pointer"
              matInput [matDatepicker]="endDate"
              [placeholder]="labels.endDate"
              readonly
              required
              formControlName="endDate"
              (click)="endDate.open()"
              [min]="minEndDate"
              [max]="maxEndDate">
            <mat-error>
              {{ labels.endDate }} {{ labels._is }} <strong>{{ labels.required }}</strong>
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
      </form>

      <div *ngIf="isCarrier">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
      </div>

      <mat-grid-tile>
        <button id="generalSearchButton" [disabled]="isDisableButton()" mat-raised-button
          class="primary-button" (click)="onSearch()">
          {{ labels.search }}
        </button>
      </mat-grid-tile>
    </mat-grid-list>
</mat-expansion-panel>
