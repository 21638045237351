/**
 * @description In this constant should be added any route who is not declared in DB
 * it is for routes that should be access by other declared view.
 */
export const INTERNAL_ROUTES = [
  {
    module: 'home',
    routes: ['/home']
  },
  {
    module: 'cost',
    routes: ['/cost/freight-rate-create']
  },
  {
    module: 'catalogs',
    routes: [
      '/catalogs/warehouse/warehouse-create',
      '/catalogs/vehicle/vehicle-create',
      '/catalogs/shipper-create',
      '/catalogs/account/account-create',
      '/catalogs/vehicle-type/vehicle-type-create',
      '/catalogs/incidence-evidence/incidence-evidence-create'
    ]
  },
  {
    module: 'orders',
    routes: ['/order/order-history']
  },
  {
    module: 'shipments',
    routes: 'shipment/collection-request-view'
  },
  {
    module: 'tracking',
    routes: ['/journey-menu/details']
  }
];
