import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { Charge,
  CostStatus,
  CostUpdate,
  FreightChargeResponse,
  FreightChargeShipment,
  ShipmentCostCreate,
  ShipmentUpdateStatus
} from '../../interfaces/';

const apiUrl = environment.apiUrl;
const freightCostApiUrl = environment.freightRateApiUrl;

@Injectable()
export class CostReviewProvider {

  constructor(
    private http: HttpClient,
    private _appService: AppService
    ) {}

  public async getShipmentCostByTenant(tenantId: string, shipmentId: string): Promise<Object> {
    return await this.http.get<Object>(`${freightCostApiUrl}/cost/tenant/${tenantId}/shipmentId/${shipmentId}`).toPromise();
  }

  public async getShipmentById(shipmentId: string): Promise<Object> {
    const shipperOid = this._appService.getShipperOid();
    return await this.http.get<Object>(`${apiUrl}/solicitudes/${shipmentId}/evidencias?embarcador=${shipperOid}`).toPromise();
  }

  public async getChargesTypesByTenant(tenantId: string): Promise<Object> {
    return await this.http.get<Object>(`${freightCostApiUrl}/charge/tenant/${tenantId}`).toPromise();
  }

  public async updateShipmentCost(tenantId: string, shipmentId: string, bodyCost: CostUpdate): Promise<Object> {
    return await this.http.put<Object>(`${freightCostApiUrl}/cost/tenant/${tenantId}/shipmentId/${shipmentId}`, bodyCost).toPromise();
  }

  public async createShipmentCost(tenantId: string, shipmentId: string, isFirst: boolean, bodyCost: ShipmentCostCreate): Promise<Object> {
    return await this.http.post<Object>(
      `${freightCostApiUrl}/cost/tenant/${tenantId}/shipmentId/${shipmentId}?isFirst=${isFirst}`,
      bodyCost).toPromise();
  }

  /**
   * @description Generate the charge to shipment confirmed
   * @param {string} shipperId Shipper/Tenant charges owner
   * @param {string} shipmentId shipmentId to apply charge
   * @returns {Promise<FreightChargeResponse>} Response of Charge
   */
  public async createFreightCharge(shipperId: string, shipmentId: string, bodyCost: FreightChargeShipment): Promise<FreightChargeResponse> {
    return await this.http.post<FreightChargeResponse>(
      `${freightCostApiUrl}/freightCharge/shipperId/${shipperId}/shipmentId/${shipmentId}`, bodyCost).toPromise();
  }

  public async updateCostStatus(tenantId: string, shipmentId: string, bodyCost: CostStatus): Promise<Object> {
    return await this.http.patch<Object>(
      `${freightCostApiUrl}/cost/tenant/${tenantId}/shipmentId/${shipmentId}/status`,
      bodyCost).toPromise();
  }

  public async updateShipmentStatus(shipmentOid: string, bodyShipment: ShipmentUpdateStatus): Promise<Object> {
    const userOid = this._appService.getUserOid();
    bodyShipment.user_id = userOid;
    return await this.http.put<Object>(`${apiUrl}/solicitudes/${shipmentOid}/estatus`, bodyShipment).toPromise();
  }

  /**
   * @description Get Charges catalog for the current shipper/tenant
   * @param {string} tenantId Shipper/Tenant charges owner
   * @returns {Promise<Array<Charge>>} Charges catalog
   */
  public async getChargesTypes(tenantId: string): Promise<Array<Charge>> {
    return await this.http.get<Array<Charge>>(`${freightCostApiUrl}/charge/tenant/${tenantId}`).toPromise();
  }
}
