import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { BatchedResponse } from '../../interfaces';
import { CreateUpdateLocationRequest } from './../../interfaces/create-update-location.request';
import { environment } from '../../../environments/environment';
import { PaginationRequest } from './../../interfaces/pagination/pagination.request';
import { PaginationResponse } from './../../interfaces/pagination/pagination.response';
import { RequestToolsService } from '../../services/utils/request-tools.service';
import { UniqueLocationFieldRequest } from './../../interfaces/unique-field.request';
import { LocationApiResponse } from './../../interfaces/locations';

import { Observable } from 'rxjs';

const apiUrl = environment.apiUrl;
const IDENTIFIER_SEARCH_KEY = 2;
const EQUAL = 'equal';
const isActive = true;
const MEDISTIK_REGION = 'MEDISTIK'; // TODO This region is only for Roadnet sales environment


@Injectable()
export class LocationProvider {

  constructor(
    private http: HttpClient,
    private requestToolService: RequestToolsService,
    private _appService: AppService
    ) { }

  /**
   * @description makes a query to get origins registered dependending of the parameters received
   * @param searchParameterFlag Flag to determinate if the search will be by zip, identifier or name
   * @param searchParam Value to be searched
   * @param {string} searchLocationsBy Optional param to especify the param by the origin will be searched for. Use this param to search
   * all origins registered, included locations registered as origin-destinations
   * @returns {ubicaciones: LocationApiResponse[]} all coincidences found
   */
  public getOrigin(searchParameterFlag, searchParam, searchLocationsBy?: string): Promise<{ ubicaciones: Object[] }> {
    const shipOid = this._appService.getShipperOid();
    if (searchLocationsBy) {
      return this.http
        .get<{ ubicaciones: Object[] }>(`${apiUrl}/embarcadores/${shipOid}/ubicaciones?tipo[]=Origen&tipo[]=Origen-Destino&` +
        `${searchLocationsBy}=${searchParam}&isActive=${isActive}`).toPromise();
    } else if (searchParameterFlag === 0) {
      return this.http
        .get<{ ubicaciones: Object[] }>(apiUrl + '/embarcadores/' + shipOid + '/ubicaciones?tipo=Origen&codigoPostal=' + searchParam)
        .toPromise();
    } else {
      return this.http
        .get<{ ubicaciones: Object[] }>(apiUrl + '/embarcadores/' + shipOid + '/ubicaciones?tipo=Origen&nombre=' + searchParam)
        .toPromise();
    }
  }

  /**
   * @description makes a query to get destinations registered dependending of the parameters receiveds
   * @param searchParameterFlag Flag to determinate if the search will be by zip, identifier or name
   * @param searchParam Value to be searched
   * @param {string} searchLocationsBy Optional param to especify the param where the destination will be searched for.
   *  Use this param to search all destinations registered, included locations registered as origin-destinations
   * @returns {ubicaciones: LocationApiResponse[]} all coincidences found
   */
  public getDestination(searchParameterFlag, searchParam, searchLocationsBy?: string): Promise<{ ubicaciones: LocationApiResponse[] }> {
    const shipOid = this._appService.getShipperOid();
    if (searchLocationsBy) {
      return this.http.get<{ ubicaciones: LocationApiResponse[] }>
        (`${apiUrl}/embarcadores/${shipOid}/ubicaciones?tipo[]=Destino&tipo[]=Origen-Destino&${searchLocationsBy}=${searchParam}`
      +  `&isActive=${isActive}`).toPromise();
    }
    if (searchParameterFlag === 0) {
      return this.http.get<{ ubicaciones: LocationApiResponse[] }>
        (apiUrl + '/embarcadores/' + shipOid +
         '/ubicaciones?tipo=Destino&codigoPostal=' + searchParam + '&isActive=' + isActive).toPromise();
    } else if (searchParameterFlag === IDENTIFIER_SEARCH_KEY) {
      return this.http.get<{ ubicaciones: LocationApiResponse[] }>
        (apiUrl + '/embarcadores/' + shipOid +
         '/ubicaciones?tipo=Destino&identifier=' + searchParam + '&isActive=' + isActive).toPromise();
    } else if (searchParameterFlag === EQUAL) {
      return this.http.get<{ ubicaciones: LocationApiResponse[] }>
        (apiUrl + '/embarcadores/' + shipOid +
         '/ubicaciones?tipo=Destino&equal=' + searchParam + '&isActive=' + isActive).toPromise();
    } else {
      return this.http.get<{ ubicaciones: LocationApiResponse[] }>
        (apiUrl + '/embarcadores/' + shipOid +
         '/ubicaciones?tipo=Destino&nombre=' + searchParam + '&isActive=' + isActive).toPromise();
    }
  }

  /**
   * @description Gets all active locations paginated by paginationRequest
   * @param {PaginationRequest | Array<PaginationRequest>} paginationRequest Number of locations to search by page
   * @param {number} chunksNumber Times to repeat the request
   * @returns {Promise<PaginationResponse>} Pagination response
   */
  public async getPaginatedLocation(paginationRequest: PaginationRequest | Array<PaginationRequest>):
    Promise<PaginationResponse | BatchedResponse> {
    if (paginationRequest['length']) {
      const arrayPaginationRequest = paginationRequest as Array<PaginationRequest>;
      for (const request of arrayPaginationRequest) {
        const index = arrayPaginationRequest.indexOf(request);
        const url = apiUrl + `/shippers/${request.searchBy['shipperId']}/locations?page=${request.paginationObject.page}
        &pageSize=${request.paginationObject.pageSize}&filterBy=${JSON.stringify(request.filterBy)}&globalSearch=${request.globalSearch}`;
        arrayPaginationRequest[index]['url'] = url;
      }
      const response = await this.requestToolService.doGetRequestByBatch(arrayPaginationRequest);
      return response;
    } else {
      const pagination = paginationRequest as PaginationRequest;
      const { filterBy, globalSearch, paginationObject, searchBy } = pagination;
      return await this.http
      .get<PaginationResponse>(apiUrl + `/shippers/${searchBy['shipperId']}/locations?page=${paginationObject.page}
        &pageSize=${paginationObject.pageSize}&filterBy=${JSON.stringify(filterBy)}&globalSearch=${globalSearch}`)
      .toPromise();
    }
  }

  /**
   * @description Sets active false to the given location
   * @param {string} locationId
   */
  public deleteLocation(locationId: string): Observable<void> {
    return this.http
      .delete<void>(apiUrl + '/location/' + locationId);
  }

  /**
   * @description Creates new location
   * @param {CreateUpdateLocationRequest} createLocationRequest
   */
  public createLocation(createLocationRequest: CreateUpdateLocationRequest): Observable<void> {
    return this.http
      .post<void>(apiUrl + '/location', createLocationRequest);
  }

  /**
   * @description Updates new location
   * @param {CreateUpdateLocationRequest} updateLocationRequest
   */
  public updateLocation(updateLocationRequest: CreateUpdateLocationRequest): Observable<void> {
    return this.http
      .put<void>(apiUrl + '/location', updateLocationRequest);
  }

  /**
   * @description Checks whether location field (name | clienteId) is unique for shipper given
   * @param {UniqueLocationFieldRequest} fieldValidationRequest - Object to check if field is unique
   * @returns {Observable<boolean>} true | false validation for field given
   */
  public checkUniqueLocationField(fieldValidationRequest: UniqueLocationFieldRequest): Observable<boolean> {
    return this.http
      .post<boolean>(apiUrl + '/location/unique-field-validation', fieldValidationRequest);
  }

  /**
   * @description Synchronizes locations from roadnet with the ones in warehouse
   * @param {string} token token generated for roadnet
   * @returns {Observable<Object>} Object that contains the response from update locations endpoint
   */
  public synchronizeLocations(token: string): Observable<Object> {
    return this.http.post<Object>(apiUrl + '/roadnet/updateLocations', { region: MEDISTIK_REGION, roadnetToken: token });
  }

  /**
   * @description Gets all destinations by shipper
   * @returns {Observable<Object>} an observable
   */
  public getDestinationsByShipper(): Observable<Array<LocationApiResponse>> {
    const shipperOid = this._appService.getShipperOid();
    return this.http.get<Array<LocationApiResponse>>(apiUrl + `/location/shipperOid/${shipperOid}/all/destination`);
  }

  /**
   * @description Gets the destinations full data from core api
   * @param shipperOId  The current shipper object id
   * @param clientIds Array with all clientIds to find the locations
   * @returns Location response with full location data
   */
  public getDestinationsByClientIds(shipperOId: string, clientIds: Array<string>): Promise<Array<LocationApiResponse>> {
    return this.http
      .post<Array<LocationApiResponse>>(`${apiUrl}/location/shipperOid/${shipperOId}/byclientids/destination`, clientIds).toPromise();
  }

  /**
   * @description Gets RFC Locations by shipper Oid
   * @param {string} shipperOid Current shipper oid provided
   * @returns {Array<string>} An array with rfc locations found
   */
  public getRFCLocationsByShipper(shipperOid: string): Promise<Array<string>> {
    return this.http.get<Array<string>>(`${apiUrl}/location/shipperOid/${shipperOid}/getRFCLocationsByShipper`).toPromise();
  }
}
