export const LABEL_ORDER_PRINTING = {
  BEGIN_DATE_FORM_KEY: 'beginDate',
  DETAILS: 'Maestra con detalles',
  END_DATE_FORM_KEY: 'endDate',
  MASTER: 'Solo Maestra',
  MAX_DAYS: 30,
  MILLISECONDS_CONVERTER: 1000 * 60 * 60 * 24,
  RANGE_TYPE_CREATION_DATE: 'creationDate',
  RANGE_TYPE_DELIVERY_DATE: 'deliveryDate',
  RANGE_TYPE_SELECT_DELIVERY_DATE: 'Fecha de entrega',
  SEARCH_TYPE_BY_FOLIO: 'Folio'
};
