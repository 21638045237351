import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { includes as _includes, isEmpty as _isEmpty } from 'lodash';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogViewGuidesData } from '../../../interfaces/order-reception';

@Component({
  selector: 'app-dialog-guide-capture',
  templateUrl: './dialog-guide-capture.component.html'
})
export class DialogGuideCaptureComponent implements OnInit {
  public captureGuideForm: FormGroup;
  public guides: Array<string>;

  constructor(public dialogRef: MatDialogRef<DialogGuideCaptureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogViewGuidesData) { }

  /**
   * @description Init interface implementation for angular component
   */
  public ngOnInit(): void {
    this.guides = [];
    this.initForm();
  }

  /**
   * @description push guides to guides array
   * @param {string} value value to push
   */
  public pushGuide(value: string): void {
    if (_isEmpty(this.guides)) {
      this.guides.push(value);
    } else {
      const isValid = !_includes (this.guides, value);
      if (isValid) {
        this.guides.push(value);
      }
    }
    this.captureGuideForm.patchValue({ guide: '' });
    this.captureGuideForm.patchValue({ capturedGuides: this.guides.length});
  }

  /**
   * @description Close dialog handler
   */
  public onClose(): void {
    this.dialogRef.close(this.guides);
  }

  /**
   * @description Initializes form
   */
  private initForm(): void {
    this.captureGuideForm = new FormGroup({
      guide: new FormControl(null,  Validators.required),
      capturedGuides: new FormControl({ value: 0, disabled: true }, Validators.required)
    });
  }
}
