<div>
  <div class="title">
    <h2>{{labels.title}}</h2>
  </div>
  <div fxLayout="row">
    <div fxFlex="12">
    </div>
    <div fxFlex="76">
      <div *ngFor="let resumeElement of resume" fxLayout="row">
        <div fxFlex="5">
          <strong>{{resumeElement.count}}</strong>
        </div>
        <div fxFlex="95">
          <strong>{{resumeElement.text}}</strong>
        </div>
      </div>

      <div class="detail">
        <div class="expansionDiv" (click)="expandDetails()">
          <div class="expansionSubtitle">{{labels.detailsSubtitle}}</div>
          <div class="expansionLine"><hr></div>
          <div class="expansionArrow">
            <mat-icon *ngIf="!expandedDetails">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedDetails">keyboard_arrow_up</mat-icon>
          </div>
        </div>
        <div class="table-container" *ngIf="expandedDetails">
          <table
            mat-table
            [dataSource]="dataSourceErrors"
            aria-describedby="errorsTable">
            <ng-container matColumnDef="errorType">
              <th
                id="errorType"
                mat-header-cell
                *matHeaderCellDef>
                {{labels.errorTypeCol}}
              </th>
              <td mat-cell *matCellDef="let vehicle" class="errorTypeColumn">
                <div fxLayout="row">
                  <div fxFlex="5">
                    <img *ngIf="vehicle.errorType == 'Invalid'"
                      src="./../../../assets/massive_load_error.svg"
                      alt="Error"/>
                    <img *ngIf="vehicle.errorType == 'Warning'"
                      src="../../../../assets/massive_load_warning.svg"
                      alt="Advertencia"/>
                  </div>
                  <div fxFlex="95">
                    <p *ngIf="vehicle.errorType == 'Invalid'">{{labels.invalidVehicle}}</p>
                    <p *ngIf="vehicle.errorType == 'Warning'">{{labels.warningVehicle}}</p>
                    <p *ngIf="vehicle.errorType == 'Valid'">{{labels.validVehicle}}</p>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="rowLine">
              <th
                id="rowLine"
                mat-header-cell
                *matHeaderCellDef>
                {{labels.rowLineCol}}
              </th>
              <td mat-cell *matCellDef="let vehicle" class="rowColumn">
                {{ vehicle.__rowNum__ }}
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th
                id="description"
                mat-header-cell
                *matHeaderCellDef>
                {{labels.errorDescCol}}
              </th>
              <td mat-cell *matCellDef="let vehicle">
                {{ vehicle.errorDescription }}
              </td>
            </ng-container>
            <ng-container matColumnDef="value">
              <th
                id="value"
                mat-header-cell
                *matHeaderCellDef>
                {{labels.fieldCol}}
              </th>
              <td mat-cell *matCellDef="let vehicle">
                <input
                  type="text"
                  value="{{vehicle.errorValue}}"
                  (keydown)="keyDownHandler($event)"
                  (change)="onChangeInfo($event, vehicle)">
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedCols; sticky: true" ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedCols;"></tr>
          </table>
        </div>
      </div>

      <div class="container-buttons" mat-dialog-actions>
        <mat-grid-list cols="2" rowsHeight="1:.10">
          <mat-grid-tile>
            <button
              class="secondary-button align-right"
              mat-stroked-button
              color="primary"
              [disabled]="!enabledRetryButton"
              (click)="onRetry()">
              {{ labels.retryButton }}
            </button>
          </mat-grid-tile>
          <mat-grid-tile>
            <button
              class="primary-button align-left"
              mat-raised-button
              color="primary"
              [disabled]="countVehiclesWithoutErrors==0"
              (click)="onContinue()">
              {{ labels.continueButton }}
              ({{ countVehiclesWithoutErrors > 0? countVehiclesWithoutErrors: null }})
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
    <div fxFlex="12">
    </div>
  </div>
</div>