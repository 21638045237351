<scf-header
  [showNotificationCenter]="true"
  [configurationPanel]="configurationPanel"
  [notificationCenterConfig]="notificationCenterConfig"
  [solution]="solution"
  [items]="menu"
  [owner]="owner"
  [ngClass]="{'logout-request': logoutRequest}"
  [languages]="languages"
  [showSolutions]="false"
  [tenantName]="tenantName"
  [userFullName]="userFullName"
  [userProfileName]="userProfile"
  [avatarImgSrc]="avatarImgSrc"
>
</scf-header>
<scf-sidebar
  [solution]="solution"
  *ngIf="menu" [items]="menu" [ngClass]="{'logout-request': logoutRequest}">
  <router-outlet scf-workspace>
  </router-outlet>
</scf-sidebar>
