import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemClientConfigs } from 'src/app/interfaces/system-client-configs';

import { environment } from '../../../environments/environment';
import {
  IntegratorTaxStampResponse,
  PostalCodeBodyModel,
  SatLocationResponseApi,
  SystemClientConfigsResponse,
  TaxStampData
} from '../../interfaces';

const tmsIntegratorApiUrl = environment.tmsIntegratorApiUrl;
const tmsWebappMedistikCmr = environment.tmsWebappMedistikCmr;
const objectId = environment.tmsIntegratorSystemOid;

@Injectable()
export class IntegratorProvider {
    constructor(private http: HttpClient) { }

    /**
     * @description Sends the TaxStampData to the tax stamp provider service.
     * @param {TaxStampData} body The information mapped to be sent to the microservice.
     * @param {string} tenantOid Tenant object id associated
     * @returns An object with the server response.
     */
    public async generateTaxStamp(body: TaxStampData, tenantOid: string): Promise<IntegratorTaxStampResponse> {
        return this.http.post<IntegratorTaxStampResponse>
        (`${tmsIntegratorApiUrl}/stampShipment/system/${objectId}/client/${tenantOid}`, body)
            .toPromise();
    }

    /**
     * @description Find the matching sat locations using a postal code from the locations catalog.
     * @param postalCodes An array of one or more postal codes to find.
     * @return An array of SatLocationResponseApi with the matching results.
     */
    public async getLocationSatData(postalCodes: PostalCodeBodyModel): Promise<SatLocationResponseApi> {
        return this.http.post<SatLocationResponseApi>( tmsIntegratorApiUrl + '/locationSatCatalog/findByPostalCodes', postalCodes)
            .toPromise();
    }

    /**
     * @description Find the stamp providers in SystemClientConfigs by criteria of search
     * @param {string} clientId tenantid for generate the request
     * @return {Promise<SystemClientConfigsResponse>} Object response with the matching results.
     */
    public async getSystemClientsConfigsStampProviders(clientId: string): Promise<SystemClientConfigsResponse> {
      return this.http.get<SystemClientConfigsResponse>(`${tmsIntegratorApiUrl}/systemClientConfigs/system/${objectId}/client/${clientId}`)
        .toPromise();
    }

    /**
     * @description Set the new stamp provider for tenant selected into the view config
     * @param {string} clientId tenantid for generate the request
     * @return {Promise<SystemClientConfigsResponse>} Object result.
     */
    public async setNewStampProvider(
      clientId: string,
      stampProviderId: string,
      object: SystemClientConfigs): Promise<SystemClientConfigsResponse> {
      return this.http.put<SystemClientConfigsResponse>(
        `${tmsIntegratorApiUrl}/systemClientConfigs/client/${clientId}/stampProvider/${stampProviderId}`, object
        ).toPromise();
      }

    /**
     * @description Sends the TaxStampData to the tax stamp provider service on a Windows Network Drive service.
     * @param {TaxStampData} body The information mapped to be sent to the microservice.
     * @param {string} tenantOid Tenant object id associated
     * @returns An object with the server response.
     */
    public async generateTaxStampWindowsDrive(body: TaxStampData, tenantOid: string): Promise<IntegratorTaxStampResponse> {
        return this.http.post<IntegratorTaxStampResponse>
        (`${tmsWebappMedistikCmr}/stampShipment/system/${objectId}/client/${tenantOid}`, body)
            .toPromise();
    }
}
