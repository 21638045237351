import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class FormattedDateService {

  /**
   * @description Add a zero to the hour
   * @param {number} num
   * @return {string} Hour with two digits
   */
   public formattingTwoDigits(num: number): string {
    const twoSpaces = -2;
    const twoDigitis = ('0' + num).slice(twoSpaces);
    return twoDigitis;
  }

  /**
   * @description Convert Date to String Format
   * @param {Date} date
   * @returns {string} String format
   */
   public dateToString(date: Date): string {
    const millisecondsInDay = 86400000;
    const millisecondsInMinute = 60000;
    const minutesTimezoneOffset = (new Date).getTimezoneOffset();
    const millisecondsTimeZoneOffset = minutesTimezoneOffset * millisecondsInMinute;
    const minute = millisecondsInMinute + millisecondsTimeZoneOffset;
    const limit = minute / millisecondsInDay;
    let days: number, time: number;
    let dateString: string;

    if (date.getTime() <= 0) {
      throw new Error('Does not convert date to string format ' + date);
    }

    days = Math.floor(date.getTime() / millisecondsInDay);
    time = ((date.getTime() / millisecondsInDay) - days);

    if (days > 0 && time > limit) {
      const dayStr = `${this.formattingTwoDigits(date.getDate())}`;
      const monthStr = `${this.formattingTwoDigits(date.getMonth() + 1)}`;
      const yearStr = `${date.getFullYear()}`;
      const hourStr = `${this.formattingTwoDigits(date.getHours())}`;
      const minuteStr = `${this.formattingTwoDigits(date.getMinutes())}`;
      const datePartStr = `${dayStr}/${monthStr}/${yearStr}`;
      const timePartStr = `${hourStr}:${minuteStr}`;
      dateString = `${datePartStr} ${timePartStr}`;
    } else if (days > 0) {
      const dayStr = `${this.formattingTwoDigits(date.getDate())}`;
      const monthStr = `${this.formattingTwoDigits(date.getMonth() + 1)}`;
      const yearStr = `${date.getFullYear()}`;
      dateString = `${dayStr}/${monthStr}/${yearStr}`;
    } else if (time > 0) {
      const hourStr = `${this.formattingTwoDigits(date.getHours())}`;
      const minuteStr = `${this.formattingTwoDigits(date.getMinutes())}`;
      const secondStr = `${this.formattingTwoDigits(date.getSeconds())}`;
      dateString = `${hourStr}:${minuteStr}:${secondStr}`;
    }

    return dateString;
  }

  /**
   *
   * @description Convert Excel Date to JS Date
   * @param {any} date
   * @returns {String} Date converted to string
   */
     public async excelDateToJSDate(date: any): Promise<string> {
      const daysDiffZeroDates = 25569;
      const secondsInDay = 86400;
      const millisecondsInSecond = 1000;
      const millisecondsInDay = secondsInDay * millisecondsInSecond;
      const millisecondsInMinute = 60000;
      const secondOfDay = 1 / secondsInDay;
      const minutesTimezoneOffset = (new Date).getTimezoneOffset();
      const millisecondsTimeZoneOffset = minutesTimezoneOffset * millisecondsInMinute;
      let dateString: string;
      const type = typeof date;
      switch (type) {
        case 'number':
          if (date > 1) {
            date -= daysDiffZeroDates;
          }
          date += secondOfDay;
          const newDate = new Date((millisecondsInDay * date) + millisecondsTimeZoneOffset);
          dateString = this.dateToString(newDate);
        break;
        default:
          dateString = date;
        break;
      }
      return dateString;
    }

  /**
   * @description Convert time in format long to string
   * @param {number} seconds Value to convert to time in format HH:MM
   * @return {string} The value of time convert to string
   */
  public convertTimeLongtoFormatHHMM(value: number): string {
    if (!value) { return null; }

    const MINUTES_INHOUR = 60;
    const SECONTS_INMINUT = 3600;
    const TWOPOINTS = ':';
    const TWODIGITS = 10;
    const ZERO = '0';
    let hours = 0;
    let minutes = 0;
    let result = '';

    hours = Math.floor(value / SECONTS_INMINUT);
    value -= hours * SECONTS_INMINUT;
    result = (hours < TWODIGITS) ? ZERO + hours + TWOPOINTS : hours + TWOPOINTS;
    minutes = Math.floor(value / MINUTES_INHOUR);
    value -= minutes * MINUTES_INHOUR;
    result += (minutes < TWODIGITS) ? ZERO + minutes : minutes;

    return result;
  }
}
