import { Injectable } from '@angular/core';

const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;

@Injectable()
export class DateToolsService {

  /**
   * @description calcultes the minutes between two dates
   * @param {Date} actualDate first date
   * @param {Date} pastDate second date
   * @return {number} minutes between dates
   */
  public differenceInMinutesBewteenTwoDates(actualDate: Date, pastDate: Date): number {
    let minutesDifference = (actualDate.getTime() - pastDate.getTime()) / MILLISECONDS_IN_SECOND;
    minutesDifference /= SECONDS_IN_MINUTE;
    const totalMinutes = Math.abs(Math.round(minutesDifference));

    return totalMinutes;
  }

}
