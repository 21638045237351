<div class="preview-title-container">
  <div class="preview-title">
    <span class="dialog-title">
      <p>{{data.title}}</p>
    </span>
  </div>
  <div class="preview-icon">
    <mat-icon
      style="cursor: pointer"
      (click)="onClickClose()">
      close
    </mat-icon>
  </div>
</div>

<div *ngIf="additionalInformation" class="evidence-information">
  <hr>
  <table role="presentation">
    <tr>
      <td class="title-td"><span>{{ 'dialogFilePreviewTags.informationOrigin' | translate }} </span></td>
      <td><span class="data-value text-highlighted">{{ evidenceOrigin }}</span></td>
      <td class="title-td"><span>{{ 'dialogFilePreviewTags.infoShipment' | translate }}</span></td>
      <td><span class="data-value text-highlighted">{{ evidenceShipment ? evidenceShipment : ('dialogFilePreviewTags.noInfo' | translate) }}</span></td>
    </tr>
    <tr>
      <td class="title-td"><span>{{ 'dialogFilePreviewTags.infoFolio' | translate }}</span></td>
      <td><span class="data-value">{{ evidenceFolio ? evidenceFolio : ('dialogFilePreviewTags.noInfo' | translate) }}</span></td>
      <td class="title-td"><span>{{ 'dialogFilePreviewTags.infoDate' | translate }}</span></td>
      <td><span class="data-value">{{ evidenceDate ? (evidenceDate | date:'dd/MM/yyyy hh:mm:ss a') : ('dialogFilePreviewTags.noInfo' | translate) }}</span></td>
    </tr>
  </table>
</div>
<div *ngIf="showIncidence" class="evidence-information">
  <hr>
  <table role="presentation">
    <tr>
      <td class="title-td"><span>{{ 'dialogFilePreviewTags.incidenceType' | translate }}</span></td>
      <td><span class="data-value text-highlighted">{{ incidenceData.tipo }}</span></td>
      <td class="title-td"><span>{{ 'dialogFilePreviewTags.incidenceDate' | translate }}</span></td>
      <td><span class="data-value">{{ incidenceData.timestamp | date:'dd/MM/yyyy hh:mm:ss a' }}</span></td>
    </tr>
    <tr>
      <td class="title-td"><span>{{ 'dialogFilePreviewTags.incidenceDesc' |  translate }}</span></td>
      <td><span class="data-value">{{ incidenceData.descripcion }}</span></td>
    </tr>
  </table>
</div>
<div *ngIf="additionalFunctions" class="icons-container">
  <button
    *ngIf="isPrintable"
    class="icon-button print-icon"
    isIconButton="true"
    [useExistingCss]="true"
    printSectionId="print-section-container"
    ngxPrint>
    <mat-icon class="print-icon">
      print
    </mat-icon>
  </button>
  <button
    id="download-button"
    *ngIf="isDownloadable"
    class="icon-button download-icon"
    isIconButton="true"
    (click)="onDownload()">
    <mat-icon class="print-icon">
      get_app
    </mat-icon>
  </button>
</div>

<div *ngIf="resourceType === 0">
  <div *ngIf="!isPDF" class="image-preview-container">
    <div *ngIf="isEvidenceInfo">
      <button
        id="rotateLeftButton"
        class="icon-button"
        (click)="rotateImage('left')">
        <mat-icon>
          rotate_left
        </mat-icon>
      </button>
      <button
        id="rotateRightButton"
        class="icon-button"
        (click)="rotateImage('right')">
        <mat-icon>
          rotate_right
        </mat-icon>
      </button>
      <button
        id="zoomInButton"
        class="icon-button"
        (click)="zoom('in')">
        <mat-icon>
          zoom_in
        </mat-icon>
      </button>
      <button
        id="zoomOutButton"
        class="icon-button"
        (click)="zoom('out')">
        <mat-icon>
          zoom_out
        </mat-icon>
      </button>
      <button
        id="downloadButton"
        class="icon-button"
        (click)="onDownloadImage()">
        <mat-icon>
          download
        </mat-icon>
      </button>
      <div class="image-container">
        <img id="image-preview-dialog"
        class="image-preview"
        [src]="url"
        (click)="openImage()"
        alt="Image preview">
      </div>
    </div>
    <img *ngIf="!isEvidenceInfo" id="image-preview-dialog"
      class="image-preview"
      (click)="openImage()"
      [src]="url"
      onerror="src='../../../../assets/notImage.png'"
      alt="Image preview">
  </div>

  <div *ngIf="isPDF" class="pdf-preview-container">
    <button
      id="expandPDF"
      class="icon-button"
      (click)="expandPDF()">
      <mat-icon>
        fullscreen
      </mat-icon>
    </button>
    <br>
    <object class="pdf-preview" type="application/pdf" [data]="url"></object>
  </div>
</div>

<div
  *ngIf="resourceType === 1"
  id="print-section-container"
  class="print-shipment-load">
  <app-load-plan [shipmentData]="shipmentToLoadPlan"></app-load-plan>
</div>

<div
  *ngIf="resourceType === 2"
  id="print-section-container"
  class="print-shipment-load">
  <app-evidence-release-folio-document [evidenceFolio]="evidenceReleaseFolioData"></app-evidence-release-folio-document>
</div>

<div
  *ngIf="resourceType === 3"
  id="print-section-container"
  class="print-shipment-load">
  <app-evidence-folio-format [evidenceFolio]="evidenceFolioData"></app-evidence-folio-format>
</div>

<div
  *ngIf="resourceType === 4"
  id="print-section-container"
  class="print-shipment-load">
  <app-shipment-request-format [shipmentRequestData]="shipmentRequestData"></app-shipment-request-format>
</div>
