export const DATEFORMAT_CONSTANTS = {
  DASH_DATE_FORMAT_DDMMYYYY: 'DD-MM-YYYY',
  DATE_FORMAT: 'YYYYMMDD',
  EVIDENCE_RELEASE_12_HOUR_FORMAT_ONLY_HOURS: 'hh:mm:ss a',
  FILE_DOWNLOAD_DATE: 'DDMMYYYY[_]HHmmss',
  FULL_DATE_FORMAT: 'DD/MM/YYYY HH:mm:ss',
  GENERAL_FORMAT: 'DD/MM/YYYY hh:mm:ss a',
  HOUR_AND_MINUTES_FORMAT: 'HH:mm',
  LOCAL_DATE_AND_TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
  POD_DOWNLOAD_FILE: 'DD_MM_YYYY',
  SLASH_DATE_FORMAT_DDMMYY: 'DD/MM/YYYY',
  STARTS_WITH_MONTH: 'MM/DD/YY HH:mm',
  TIME_FORMAT: 'HHmmss',
  TWO_DIGITS_DATE: 'DD/MM/YY'
};
