<div fxLayout="row">
  <div fxFlex="90" class="dialog-title">
    {{labels.title}}
  </div>
  <div fxFlex="10">
    <mat-icon style="cursor:pointer" (click)="onClickClose()">
      close
    </mat-icon>   
  </div>
</div>
<br/>
<div *ngIf="data.drivers.length"
  fxLayout="row"
  class="content">
  <br/>
  <div fxLayout="column">
    <div>{{labels.driverWarning}}</div>
    <div class="dialog-subtext">{{data.drivers.join()}}</div>
  </div>
</div>
<div *ngIf="data.vehicles.length"
  fxLayout="row"
  class="content">
  <br/>
  <div fxLayout="column">
    <div>{{labels.vehicleWarning}}</div>
    <div class="dialog-subtext">{{data.vehicles.join()}}</div>
  </div>
</div>
<br/>
<div>
  {{labels.questionConfirm}}
</div>
<br/>
<div fxLayout="row" class="group-btn">
  <div fxFlex='100'>
    <button 
      mat-button
      class="secondary-button align-left" 
      (click)="onClickSecundary()">
      {{labels.secondaryButton}}
    </button>
    <button
      mat-button
      mat-raised-button
      class="primary-button align-right"
      (click)="onClickPrimary()">
      {{labels.primaryButton}}
    </button>
  </div>
</div>
