import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { EvidencesReport } from '../../interfaces/evidences';
import {
  FreightCostReport,
  GeneralOrderStatusReport,
  GeneralOrderStatusSearchBody,
  IncidenceReport,
  MasterCostingReport,
  MasterCostingSearchBody,
  OperatingEfficiencyReport,
  PendingFreightCostReport,
  SearchBody,
  ShipmentPendingReport,
  ShipmentPendingSearchBody,
} from '../../interfaces/reports' ;
import { IBillingSheetPost } from '../../interfaces';
import { ReportSearch } from '../../components/report-filter-search/interface/report-search.interface';

const apiUrl = environment.apiUrl;
const freightRateApiUrl = environment.freightRateApiUrl;

@Injectable()
export class ReportProvider {
  constructor(private http: HttpClient, private appService: AppService) {}

  /**
   * @description Get Master Cost Report by date filter
   * @param {string} beginDate Begin date search filter
   * @param {string} endDate End date search filter
   * @param {string} findBy Search type order/shipment
   * @returns {Promise<Array<MasterCostingReport>>} Master Cost Report
   */
   public async getMasterCostingReport(searchBody: MasterCostingSearchBody): Promise<Array<MasterCostingReport>> {
    const shipperOid = await this.appService.getShipperOid();
    const url = `/shipper/${shipperOid}/masterCosting/report?` +
    `beginDate=${searchBody.beginDate}&endDate=${searchBody.endDate}&findBy=${searchBody.searchType}`;
    return this.http.get<Array<MasterCostingReport>>(apiUrl + url).toPromise();
  }

  /**
   * @description Get Freight Cost Report by date filter
   * @param {string} beginDate Begin date search filter
   * @param {string} endDate End date search filter
   * @param {string} findBy Search type order/shipment
   * @returns {Promise<Array<FreightCostReport>>} Freight Cost Report
   */
  public getFreightCostReport(beginDate: string, endDate: string, findBy?: string): Promise<Array<FreightCostReport>> {
    const shipperOid = this.appService.getShipperOid();
    const url = `/shipper/${shipperOid}/freightCost/report?beginDate=${beginDate}&endDate=${endDate}&findBy=${findBy}`;
    return this.http.get<Array<FreightCostReport>>(apiUrl + url).toPromise();
  }

  /**
   * @description Search report info
   * @param {ReportSearch} body search criteria
   * @param {string} dateRangeType date range type
   * @returns {Promise<Array<PendingFreightCostReport>>} Report info
   */
   public async getPendingFreightCostReport(body: ReportSearch, dateRangeType: string): Promise<Array<PendingFreightCostReport>> {
    const shipperOid = await this.appService.getShipperOid();
    return await this.http.get<Array<PendingFreightCostReport>>(apiUrl + '/shipperOid/' + shipperOid + '/reports/pendingFreightCost?' +
    'beginDate=' + body.beginDate + '&endDate=' + body.endDate + '&findBy=' + dateRangeType).toPromise();
  }

  /**
   * @description Get operating efficiency report info
   * @param {SearchBody} searchBody Date range to search
   * @param {string} findBy Type of search
   * @returns {Promise<Array<OperatingEfficiencyReport>>} Operating efficiency report info
   */
  public async getOperatingEfficiencyReport(searchBody: SearchBody, findBy: string): Promise<Array<OperatingEfficiencyReport>> {
    const shipperOid = await this.appService.getShipperOid();
    return await this.http.get<Array<OperatingEfficiencyReport>>(
      apiUrl + '/shipperOid/' + shipperOid + '/reports/operatingEfficiency' + '?beginDate=' + searchBody.beginDate + '&endDate=' +
      searchBody.endDate + '&findBy=' + findBy).toPromise();
  }

  public async getShipmentPendingReport(body: ShipmentPendingSearchBody): Promise<Array<ShipmentPendingReport>> {
    const shipperOid = await this.appService.getShipperOid();
    return await this.http.get<Array<ShipmentPendingReport>>(`${apiUrl}/shipperOid/${shipperOid}/reports/shipmentPending` +
      `?beginDate=${body.beginDate}&endDate=${body.endDate}`).toPromise();
  }

  /**
   * @description Get Incidence report info
   * @param {SearchBody} searchBody Date range to search
   * @param {string} findBy Type of search
   * @returns {Promise<Array<IncidenceReport>>} Incidence report info
   */
  public async getIncidencesReport(searchBody: SearchBody, findBy: string): Promise<Array<IncidenceReport>> {
    const shipperOid = await this.appService.getShipperOid();
    return await this.http.get<Array<IncidenceReport>>(
      apiUrl + '/shipperOid/' + shipperOid + '/incidences/report?' + 'beginDate=' + searchBody.beginDate + '&endDate=' +
      searchBody.endDate + '&findBy=' + findBy).toPromise();
  }

  /**
   * @description Search evidences report info
   * @param {string} tenantId search criteria
   * @param {string} findBy search criteria
   * @param {string} beginDate date range type
   * @param {string} endDate date range type
   * @returns {Promise<Array<EvidencesReport>>} Evidences report info
   */
  public async getEvidencesReport(tenantId: string, findBy: string, beginDate: string, endDate: string): Promise<Array<EvidencesReport>> {
    const url = `${apiUrl}/evidences/shipper/${tenantId}/report?findBy=${findBy}&beginDate=${beginDate}&endDate=${endDate}`;
    return this.http.get<Array<EvidencesReport>>(url).toPromise();
  }

  /**
   * @description Gets general order status report info
   * @param {GeneralOrderStatusSearchBody} body search body params
   * @returns {Promise<Array<GeneralOrderStatusReport>>}Array with report data
   */
  public async getGeneralOrderStatusReport(body: GeneralOrderStatusSearchBody): Promise<Array<GeneralOrderStatusReport>> {
    const shipperOid = await this.appService.getShipperOid();
    return await this.http.get<Array<GeneralOrderStatusReport>>(`${apiUrl}/shipperOid/${shipperOid}/reports/generalOrderStatus` +
      `?beginDate=${body.beginDate}&endDate=${body.endDate}`).toPromise();
  }

    /**
   * @description Get Freight Cost report info
   * @param {string} tenantId Search criteria
   * @param {Date} beginDate Begin date search filter
   * @param {Date} endDate End date search filter
   * @returns {Promise<Array<IBillingSheetPost>>} Freight Cost report info
   */
    public async getBillingSheetByDates(tenantId: string, beginDate: Date, endDate: Date): Promise<Array<IBillingSheetPost>> {
      return this.http.get<Array<IBillingSheetPost>>(freightRateApiUrl + '/billing/tenant/' + tenantId + '?beginDate=' + beginDate +
        '&endDate=' + endDate).toPromise();
    }

}
