export const GenericRegexp = {
  DELETE_HTML_TAGS: /<[^>]*>?/gm,
  HAS_ACCENTED_LETTERS: /[áéíóúÁÉÍÓÚ]/,
  LETTER: /[a-z]/i,
  MOBILE_PATTERN: /^[0-9]*$/,
  MULTIPLE_SEARCH_SPLIT: /[\,\n\r\t\f\v]/,
  NON_BLANKS_START_STRING: /^\s*\S+(?: \S+)*\s*$/,
  NON_DECIMAL_ALLOWED: /^[^.]*$/,
  NON_EMPTY_STRING: /^(?!\s*$).+/,
  NUMBER: /\d+/,
  NUMBER_WITH_COMMAS: /\B(?=(\d{3})+(?!\d))/g,
  NUMBER_WITH_TWO_DECIMALS_POINTS: /^\d*(?:[.,]\d{1,2})?$/,
  ONLY_NUMBERS: /[0-9\b]/,
  ONLY_ONE_DECIMAL: /^\d*\.?\d{0,1}$/,
  ONLY_POSITIVE_NUMBERS: /[0-9]/,
  ONLY_THREE_DECIMAL: /^\d*\.?\d{0,3}$/,
  ONLY_TWO_DECIMAL: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/,
  PATTERN_RFC: /([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])/,
  PHONE_REGEXP: /(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/,
  REG_COMMA: /,/g,
  REG_SPACE: / /g,
  SEPARATE_20_CHARACTERS: /.{1,20}/g
};
