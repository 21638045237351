import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { ScfCommunicationService, ScfNotification, ScfNotificationCenterConfig } from 'scf-library';
import * as _ from 'lodash';

import { AppService } from './app.service';
import { AuthService } from './authentication/auth.service';
import { environment } from '../environments/environment';
import { filter, pairwise } from '../../node_modules/rxjs/operators';
import { IMenuInterface } from './interfaces/menu-interface';
import { Language } from './interfaces/language.interface';
import { LanguageArrayResponse } from './interfaces/languageResponse.interface';
import { LanguageChangeEventService } from './services/translate/language-change-event.service';
import { LanguageService } from './providers/languages/language.service';
import { LanguageTranslateService } from './services/translate/language-translate.service';
import { NotificationsService } from './providers/notifications/notifications.service';
import { ToastrAlertsService } from './services/utils/toastr-alerts.service';
import { UserInfoLocalStorage } from './interfaces';
import { UserResponse } from './interfaces/userResponse.interface';
import { UserService } from './providers/user/user.service';

const KEY_ALLOWEDROUTES = 'allowed_routes';
const SOLUTION = 'tep';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  public avatarImgSrc: string;
  public configurationPanel: boolean;
  public currentRoute: string;
  public languageLabels: any;
  public languages: Array<Language>;
  public logoutRequest: boolean;
  public menu: IMenuInterface[];
  public notificationCenterConfig: ScfNotificationCenterConfig;
  public owner: string;
  public previousRoute: string;
  public solution: string;
  public tenantName: string;
  public userFullName: string;
  public userProfile: string;

  constructor(
    private _appService: AppService,
    private _authService: AuthService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageService: LanguageService,
    private _languageTranslateService: LanguageTranslateService,
    private _userService: UserService,
    private notificationService: NotificationsService,
    private router: Router,
    private scfComService: ScfCommunicationService,
    private toast: ToastrAlertsService,
  ) {
    this.configurationPanel = environment.languageEnabled;
    this.currentRoute = null;
    this.buildMenu();
    this.owner = '';
    this.previousRoute = null;
    this.solution = SOLUTION;
    this.notificationCenterConfig = {
      url: environment.notificationApiUrl,
      userId: this._appService.getUserOid()
    };
  }

  public ngOnInit(): void {
    this.setLanguage();
    this.getLanguages();
    this.getLanguageTags();
    this.logoutRequest = false;
    this.subscribeRoutes();
    this.initUserPanel();
    this.subscribeToScfEvents();
  }

  /**
   * @description Build the sidebar Menu
   * @return {void}
   */
  public async buildMenu(): Promise<void> {
    this.menu = JSON.parse(localStorage.getItem(KEY_ALLOWEDROUTES));
  }

  /**
   * @description Subscribes to the Router Service and switches current and previous route accordingly
   * @return {void}
   */
  private subscribeRoutes(): void {
    this.router.events
      .pipe(filter(event => event instanceof RoutesRecognized))
      .pipe(pairwise())
      .subscribe((events: any[]) => {
        this.currentRoute = events.pop().url;
        this.previousRoute = events.pop().url;
      });
  }

  /**
   * @description Init User panel data
   */
  public async initUserPanel(): Promise<void> {
    const user = this._appService.getLoggedUserInfo();
    this.owner = user.infoUsuario.username;
    this.userProfile = await (await this._appService.getUserRole()).name;

    if (user.infoUsuario.lineaTransporte) {
      this.tenantName = user.infoUsuario.lineaTransporte.nombre;
    } else {
      this.tenantName = user.infoUsuario.embarcador.nombre;
    }

    this.avatarImgSrc = this.getTenantLogo(user);
  }

  /**
   * @description Gets application languages.
   * @return {Promise<void>}
   */
  public async getLanguages(): Promise<void> {
    await this._languageService
      .getLanguages()
      .then((result: LanguageArrayResponse) => {
        this.languages = result.item;
      })
      .catch((err) => {
        console.error(err);
        this.toast.errorAlert(this.languageLabels.errorGettingLanguages);
      });
  }

  /**
  * @description Subscribes to SCF Events
  * @return {void}
  */
  private subscribeToScfEvents(): void {
    this.scfComService.navigateToSubscribe()
      .subscribe((routerLink: string) => {
        if (routerLink) {
          this.router.navigate([routerLink]);
        }
      });

    this.scfComService.logoutSubscribe()
      .subscribe((navRequest: any) => {
        if (!navRequest) {
          this.logoutRequest = true;
          this._authService.logout();
        }
      });

    this.scfComService.notificationReadSubscribe()
      .subscribe((notification: ScfNotification) => {
        if (notification) {
          this.notificationService.handleNotificationAction(notification);
        }
      });

    this.scfComService.languageChangedSubscribe()
      .subscribe(async (languageKey: string) => {
        if (languageKey) {
          this.setLanguage(languageKey);
          this.emitLanguageChangeEvent(languageKey);
          this.updateUserLanguage(languageKey);
          await this.translateMenu();
        }
      });
  }

  /**
   * @description Translate menu labels and sort them alphabetically
   * @return {Promise<void>}
   */
  private async translateMenu(): Promise<void> {
    const currentMenu = JSON.parse(localStorage.getItem(KEY_ALLOWEDROUTES));
    const translatedMenu = await this._authService.translateMenu(currentMenu);
    await this._authService.sortModulesAndViews(translatedMenu);
    localStorage.setItem(KEY_ALLOWEDROUTES, JSON.stringify(translatedMenu));
    this.menu = translatedMenu;
  }

  /**
   * @description Reacts to the SCF language change event emitting another event to be used by other components.
   * @return {void}
   */
  public emitLanguageChangeEvent(languageKey: string): void {
    this._languageChangeEventService.emitLanguageChangeEvent(languageKey);
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the SCF language change event updating the user's language in database
   * @return {Promise<void>}
   */
  public async updateUserLanguage(key: string): Promise<void> {
    const userID = this._appService.getUserOid();
    const userBody = {
      language: {
        _key: key
      }
    };

    await this._userService.updateUserLanguage(userID, userBody)
      .then((result: UserResponse) => {
        return result.item;
      })
      .catch((err) => {
        console.error(err);
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels('languageLabels')
    .catch(error => {
      console.log(error);
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Get tenant logo
   * @param {UserInfoLocalStorage} user Current logged user
   * @returns {string} URL logo
   */
  public getTenantLogo(user: UserInfoLocalStorage): string {
    let logo = environment.baseStorageUrl + environment.mainContainer;
    let url: string;

    if (user.infoUsuario.embarcador) {
      url = user.infoUsuario.embarcador.configuracion.logoUrl;
    }

    if (url && url !== '') {
      logo += url;
    } else {
      logo = undefined;
    }

    return logo;
  }
}
