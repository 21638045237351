export enum EvidenceStatus {
  Evidenced = 'Evidenciada',
  InProcess = 'En Proceso',
  Released = 'Liberado'
}

export const EvidenceStatusAndLabel = [
  { value: 'En Proceso', label: 'InProcess' },
  { value: 'Evidencia Mobility', label: 'Mobility' },
  { value: 'Evidenciada', label: 'Evidenced' },
  { value: 'Liberada', label: 'Released' },
  { value: 'Pendiente', label: 'Pending' },
  { value: 'Registrada', label: 'Registred' },
  { value: 'Sin Evidencia', label: 'NoEvidence' }
];
