<div fxLayout="row">
  <div fxFlex="90" class="dialog-input-title">
    {{ 'expiredSessionDialogLabels.title' | translate }}
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="center center" class="dialog-input-content">
  <div>
    <div fxFlex="40">
      <img src="{{ properties.imgPath }}" alt="expired icon">
    </div>
    <div fxFlex="60">
      <p class="dialog-description">{{ 'expiredSessionDialogLabels.description' | translate }}</p>
    </div>
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="flex-end">
  <button
    id="signOutButton"
    mat-raised-button
    color="primary"
    class="on-primary-button"
    (click)="onSignOut()">
    {{ 'expiredSessionDialogLabels.signOutButton' | translate }}
  </button>
</div>
