import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Charge } from '../../interfaces/charge';
import { BillingSheetCostResponse, IBillingSheetPost } from '../../interfaces';
import { environment } from '../../../environments/environment';

const freightRateApiUrl = environment.freightRateApiUrl;
const apiUrl = environment.apiUrl;

@Injectable()
export class BillingSheetProvider {

  constructor(private http: HttpClient) {}

  public async getShipmentsByIntervalAndCarrierId(searchData): Promise<object> {
    return await this.http.post<object>(apiUrl + '/shipment/costedShipments', searchData).toPromise();
  }

  public async getChargesByTenantId(tenantId: string): Promise<Array<Charge>> {
    return await this.http.get<Array<Charge>>(freightRateApiUrl + '/charge/tenant/' + tenantId).toPromise();
  }

  /**
   * @description Saves billing sheet data on db
   * @param {object} billingSheets List of generated billing sheets to save on db
   * @param {string} tenantId Required id to save data
   * @returns {object} Response of freight cost api that contains inserted data on db
  **/
  public async saveBillingSheet(billingSheets: Object, tenantId: string): Promise<object> {
    return await this.http.post<object>(freightRateApiUrl + '/billing/tenant/' + tenantId, billingSheets).toPromise();
  }

  public async updateShipmentsStatus(shipmentId: any, body: any): Promise<any> {
    return await this.http.put<Object>(apiUrl + '/solicitudes/' + shipmentId + '/estatus', body).toPromise();
  }

  public async loadDocumentFile(tenantId: any, shipmentFolio: any, document: any): Promise<Object> {
    // TODO When Costing MS will have a blobstorage method, change this petition if necessary
    return this.http.post(freightRateApiUrl + '/tenantId/' + tenantId + '/shipmentFolio/' + shipmentFolio, document);
  }

  public async getBillingSheetByFolio(tenantId: string, isFolio: boolean,
    folio: string, shipment: string, beginDate: string, endDate: string): Promise<Array<IBillingSheetPost>> {
    return this.http.get<Array<IBillingSheetPost>>(freightRateApiUrl + '/billing/tenant/' + tenantId + '?isFolio=' + isFolio + '&folio='
    + folio + '&shipment=' + shipment + '&beginDate=' + beginDate + '&endDate=' + endDate).toPromise();
  }

}
