export enum ShipmentRequestStatus {
  Accepted = 'Aceptada',
  Arrived = 'Arribado',
  Assigned = 'Asignada',
  Billing = 'Proceso Facturacion',
  Canceled = 'Cancelada',
  Close = 'Cerrada',
  Costed = 'Costeado',
  Delivered = 'Entregada',
  Evidenced = 'Evidenciada',
  Expired = 'Caducada',
  Finished = 'Finalizadas',
  InRoute = 'inRoute',
  LoadCompleted = 'Fin Carga',
  LoadStarted = 'Inicio Carga',
  OnBillingSheet = 'En Sabana',
  OnDelivery = 'En Transito',
  Open = 'Abierta',
  Paying = 'Proceso Pago',
  Rated = 'Calificada',
  Released = 'Liberado',
  Started = 'Iniciadas'
}

export enum ShipmentRequestStatusTEP {
  Assigned = 'Asignado',
  Delivered = 'Entregado',
  InHold = 'Esperando',
  InRouteToOrigin = 'En tránsito a origen',
  Loaded = 'Cargado',
  OnDelivery = 'En tránsito'
}
