export enum DialogAssignCarriersLabels {
  accept = 'Aceptar',
  assignCarriersError = 'Ocurrió un error al asignar las líneas de transporte',
  assignCarriersSuccess = 'Las líneas de transporte han sido asociadas correctamente.',
  cancel = 'Cancelar',
  carrierPlaceholder = 'Líneas de transporte',
  carrierRequired = 'Debes seleccionar al menos una línea de transporte',
  modalQuestion = '¿Deseas continuar?',
  modalResume = 'Las líneas de transporte seleccionadas quedarán asociadas al grupo ',
  modalTitle = 'Asignación de líneas de transporte.',
  noCarriersFoundMessage = 'No se pudieron obtener las líneas de transporte',
  save = 'Guardar',
  selectAll = 'Seleccionar todos',
  title = 'Asociar líneas de transporte'
}
