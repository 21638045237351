import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { mergeMapTo, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ScfNotification } from 'scf-library';
import { ToastrService } from 'ngx-toastr';

import { AppService } from '../../app.service';
import { DialogTemperatureAlertComponent } from '../../components/dialog/dialog-temperature-alert/dialog-temperature-alert.component';
import { environment } from '../../../environments/environment';
import { NotificationPayload } from '../../interfaces';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';


const ACCEPT = 'Accept';
const apiUrl = environment.apiUrl;
const DIALOGICON = '../../../assets/Illustration-laptop-Cancel.svg';
const DIALOGWIDTH = '600px';
const GOFOWARD = 'Aceptar';
const GOFOWARDTAP = 'Abrir en otra pestaña';
const MESSAGE_LISTENER = 'message';
const OPEN_NEW_TAB = 'Open a new tap';
const OUT_RANGE_TEMPERATURE_NOTIFICATION = 'Temperatura fuera de rango';
const QUESTION = '¿Deseas continuar?';
const RESUME = ' Si abres la notificación seleccionada, la información ingresada no será guardada';
const TIMETORELOAD = 300;
const TITLE = 'Cancelar tarea en proceso';
const TOAST_ERROR_MESSAGE = 'Ocurrió un error al activar las notificaciones';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private appService: AppService,
    private dialog: MatDialog,
    private firebase2: AngularFireMessaging,
    private http: HttpClient,
    private router: Router,
    private toastService: ToastrAlertsService,
    private toastr: ToastrService
  ) {
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(MESSAGE_LISTENER, this.onReceiveNotification.bind(this));
    }
  }

  /**
   * @description Listen when receive notification and show it
   * @param {NotificationPayload} notification - data of the notification received
   */
  private onReceiveNotification(notification: NotificationPayload): void {
    if (notification.data && notification.data.firebaseMessaging) {
      const notificationData = notification.data.firebaseMessaging.payload.notification;
      notification.data.firebaseMessaging.payload.notification.title === OUT_RANGE_TEMPERATURE_NOTIFICATION ?
      this.onClickTemperatureAlert(notificationData.body, notificationData.title) :
      this.toastService.infoAlert(notificationData.body, notificationData.title);
    }
  }

  /**
   * @description Catch the clic from the alert of temperature out of range
   * @param {string} message - data of the notification received
   * @param {string} title - data of the notification received
   */
  private onClickTemperatureAlert(message: string, title: string): void {
    this.toastr.warning(message, title)
    .onTap
    .pipe(take(1))
    .subscribe(() => this.onOpenTemperatureDialog(message));
  }

  /**
   * @description Opens the temperature out of range dialog
   * @param {string} message - id of shipment out of temperature range
   */
  private async onOpenTemperatureDialog(message: string): Promise<void> {
    const idShipment = message.substring(12, 25);
    const dialogTemperature = this.dialog.open(DialogTemperatureAlertComponent, {
      data: {
        title: TITLE,
        resume: RESUME,
        iconPath: DIALOGICON,
        question: QUESTION,
        textButton1: GOFOWARDTAP,
        textButton2: GOFOWARD
      },
      width: DIALOGWIDTH
    });

    dialogTemperature.afterClosed().subscribe(async result => {
      if (result === ACCEPT) {
        this.router.navigate(['journey-menu/tracking'], { queryParams: { idShipment: idShipment}});
        setTimeout(() => window.location.reload(), TIMETORELOAD);
      }
      if (result === OPEN_NEW_TAB) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['journey-menu/tracking'], { queryParams: { idShipment: idShipment}}));
        window.open(url, '_blank');
      }
    });
  }

  /**
   * @description Request to user if want to receive notifications
   * @param {string} userOid - user id
   */
  public async requestNotificationsPermission(userOid: string): Promise<void> {
    this.firebase2.requestPermission
      .pipe(mergeMapTo(this.firebase2.tokenChanges))
      .subscribe((token) => {
        this.registerTokenNotification(token, userOid);
      }
    );
  }

  /**
   * @description Register the notification token to receive them
   * @param {token} userOid - notification token
   * @param {string} userOid - user id
   */
  private async registerTokenNotification(token: string, userOid: string): Promise<void> {
    try {
      const body = {
        notificationToken: token,
        userOid: userOid,
        isRegistering: true
      };
      await this.http.post<object>(apiUrl + '/notifications/registerFirebaseToken', body).toPromise();
    } catch (error) {}
  }

  /**
   * @description Delete the notification token in firebase
   */
  public async deleteTokenNotification(): Promise<void> {
    try {
      const userOid = this.appService.getUserOid();
      const firebaseToken = await this.firebase2.getToken.toPromise();
      const body = {
        userOid: userOid,
        notificationToken: firebaseToken,
        isRegistering: false
      };
      await this.http.post<object>(apiUrl + '/notifications/registerFirebaseToken', body).toPromise();
    } catch (error) {}
  }

  /**
   * @description Handler for notification actions
   * @param {ScfNotification} notification Notification pressed
   */
  public handleNotificationAction(notification: ScfNotification): void {
    if (notification.title === OUT_RANGE_TEMPERATURE_NOTIFICATION) {
      this.onOpenTemperatureDialog('notification.message');
    }
  }
}
