<mat-grid-list cols="8" rowHeight="1:.30">
  <mat-grid-tile colspan="7" rowspan="1">
    <span class="dialog-title">
      <p>{{data.title}}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
    <mat-icon
      id="closeIcon"
      class="dialog-close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<div class="dialog-resume">
  {{data.resume}}
</div>
<mat-grid-list cols="2" rowHeight="1:.40">
  <mat-grid-tile colspan="1" rowspan="2">
    <img src="{{ data.imgRef }}" width="95%" height="95%" alt="Dialog image">
  </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <form class="dialog-selector" id="chargeTypeForm" [formGroup]="chargeTypeForm">
        <mat-form-field>
          <mat-select
            id="chargeTypeSelector"
            placeholder="{{ labels.typeOfCharge }}"
            formControlName="typeOfCharge"
            (selectionChange)="onChangeDetected()">
            <mat-option [value]="labels.unitValue">
              {{ labels.unit }}
            </mat-option>
            <mat-option [value]="labels.deliveryValue">
              {{ labels.delivery }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-grid-tile>
  <mat-grid-tile class="button-container" colspan="1" rowspan="1">
    <button
      id="cancelButton"
      mat-button
      class="secondary-button"
      (click)="onClickCancel()">
      {{ data.cancelButton }}
    </button>
    <button
      mat-raised-button
      class="primary-button align-right"
      (click)="onClickSave()"
      [disabled]="this.isSaveDisabled">
      {{ data.SaveButton }}
    </button>
  </mat-grid-tile>
</mat-grid-list>
