export const AppConstants = {
  CONFIRM: 'Confirm',
  DOT_CHAR: '.',
  EMPTY_STRING: '',
  GOOGLE_SUCCESS_RESPONSE: 'OK',
  IMAGES_FILES_FORMAT_ALLOWED: ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'],
  ISR_FACTOR: 0.0125,
  IVA_FACTOR: 0.16,
  KEY_BLOBNAME: 'blobName',
  KEY_SASURL: 'sasUrl',
  OBJECT_ID_KEY: '_id',
  PDF_PREFIX: 'pdf',
  RETENTION_FACTOR: 0.04,
  SHIPPER_DEFAULT_LOGO: 'assets/icons/solutions/tepLogo2.jpg',
  SYSTEM_DEFAULT_LOGO: 'assets/icons/solutions/tep-logo.svg',
  USER_INFO_KEY: 'userInfo',
  WHITE_SPACE: ' '
};
