import { DialogData } from 'scf-library/lib/scf-lib/scf-dialog/scf-dialog.interface';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { MatTableDataSource } from '@angular/material/table';

import { ConfirmationLabel, SpecialRequirementsViewTag} from '../../../../interfaces';
import { ConfirmationLabels, SpecialRequirementsViewTags } from '../../../../components/marketplace/confirmation/confirmation.labels';
import { ConfirmationProperties } from '../../../../components/marketplace/confirmation/confirmation.properties';
import {
  DIALOG_SHIPMENT_REQUEST_DETAILS
} from '../../../../constants/dialog-shipment-request-details/dialog-shipment-request-details.constants';
import { LocationWaypoint, MarkerOption } from '../../../../interfaces/waypoint';
import { MARKETPLACE_SHIPPER_CONSTANTS } from '../../../../constants/marketplace-shipper/marketplace-shipper.constants';
import { MapConstants } from '../../../../components/map/map-tracking.constants';
import { NewShipmentRequestProperties } from '../../../../pages/shipments/marketplace/new-shipment-request/new-shipment-request.properties';
import { ShipmentRequestDestination } from '../../../../interfaces/marketplace-shipper/shipment-request';
import { ShipmentRequestResponse } from '../../../../interfaces/marketplace-shipper/shipment-request';
import { ToastrAlertsService } from '../../../../services/utils/toastr-alerts.service';
import { Waypoint } from '../../../../interfaces/waypoint';

import * as moment from 'moment';

const LIMITDELIVELY = 1;
const MAX_LIMIT_DESTINATION_CONSULT = 25;
const MIN_ZOOM = 4;
const MINUTES_INHOUR = 60;
const SECONTS_INMINUT = 3600;
const STATUS_RESPONSE = 'OK';
const SEQUENCE_DEFAULT = 0;
const VIEWSHIPMENTREQUESTCOLUMNS = ['stopping', 'state', 'municipality', 'suburb', 'postalCode', 'address'];
@Component({
  selector: 'app-dialog-shipment-request-details',
  templateUrl: './dialog-shipment-request-details.component.html',
  styleUrls: ['./dialog-shipment-request-details.component.scss', '../../../../app.component.scss'],
})
export class DialogShipmentRequestDetailsComponent implements OnInit {
  public calendarClock: string;
  public completeDateFormat: string;
  public counterDeliveries: number;
  public defaultStop: number;
  public dateFormat: string;
  public destination: LocationWaypoint;
  public destinationsList: Array<ShipmentRequestDestination>;
  public destinationsSource: MatTableDataSource<ShipmentRequestDestination>;
  public distanceRoute: string;
  public displayedColumns: Array<string>;
  public fullOriginShipmentAddress: string;
  public fullDestinationShipmentAddress: string;
  private geoCoder: object;
  public labels: ConfirmationLabel;
  public latitude: number;
  public longitude: number;
  public mapLocation: string;
  public markers: Array<Waypoint>;
  public manuallyAddressResult: object;
  public markerOptions: MarkerOption;
  public moneyUp: string;
  public origin: LocationWaypoint;
  public originAndDestination: string;
  public optimizeWaypoints: boolean;
  public palletGear: string;
  public provideRouteAlternatives: boolean;
  public renderOptions: object;
  public searchElementRef: ElementRef;
  public shipmentRequestResponse: ShipmentRequestResponse;
  public timeDistanceRoute: string;
  public timeFormat: string;
  public truckGear: string;
  public viewTags: SpecialRequirementsViewTag;
  public waypointIcon: string;
  public waypoints: Array<Waypoint>;
  public zoom: number;
  constructor(
    public dialogRef: MatDialogRef<DialogShipmentRequestDetailsComponent>,
    private mapsAPILoader: MapsAPILoader,
    private toast: ToastrAlertsService,

    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.latitude = this.data['userCoordinates'] ? this.data['userCoordinates'].lat : MapConstants.defaultLatitude;
    this.longitude = this.data['userCoordinates'] ? this.data['userCoordinates'].lng : MapConstants.defaultLongitude;
    this.calendarClock = ConfirmationProperties.calendarClock;
    this.completeDateFormat = DIALOG_SHIPMENT_REQUEST_DETAILS.COMPLETE_DATE_FORMAT;
    this.defaultStop = 1;
    this.dateFormat = DIALOG_SHIPMENT_REQUEST_DETAILS.DATE_FORMAT;
    this.destination = {} as LocationWaypoint;
    this.destinationsList = [];
    this.labels = ConfirmationLabels;
    this.markers = [];
    this.mapLocation = ConfirmationProperties.mapLocation;
    this.markerOptions = {
      origin: {
        icon: NewShipmentRequestProperties.greenPin,
        draggable: false
      },
      destination: {
        icon: NewShipmentRequestProperties.redPin,
        draggable: false
      },
    };
    this.moneyUp = ConfirmationProperties.moneyUp;
    this.origin = {} as LocationWaypoint;
    this.originAndDestination = ConfirmationProperties.originDestination;
    this.palletGear = ConfirmationProperties.palletGear;
    this.renderOptions = { suppressMarkers: true };
    this.shipmentRequestResponse = data as ShipmentRequestResponse;
    this.truckGear = ConfirmationProperties.truckGear;
    this.timeFormat = DIALOG_SHIPMENT_REQUEST_DETAILS.TIME_FORMAT;
    this.viewTags = SpecialRequirementsViewTags;
    this.waypoints = [];
    this.waypointIcon = NewShipmentRequestProperties.blackPin;
    this.zoom = MIN_ZOOM;
  }

  /**
   * @description set the information of address to one information label
   */
  public setAddress(): void {
    this.fullOriginShipmentAddress =
    `${this.shipmentRequestResponse.origin.address}
    ${this.labels.codePostal} ${this.shipmentRequestResponse.origin.postalCode},
    ${this.shipmentRequestResponse.origin.city},
    ${this.shipmentRequestResponse.origin.state}.`;
    this.fullDestinationShipmentAddress =
    `${this.shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations?.length - 1].address}
    ${this.labels.codePostal}
    ${this.shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations?.length - 1]?.postalCode},
    ${this.shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations?.length - 1]?.city},
    ${this.shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations?.length - 1]?.state}.`;
  }

  /**
   * @description Close the dialog
   */
  public onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * @description This function format the date
   * @param {Date} date The date received from the shipment request
   * @returns {string} The date formated
   */
  public getDate(date: Date): string {
    return moment(date, MARKETPLACE_SHIPPER_CONSTANTS.MOMENT_FORMAT).format(this.dateFormat);
  }

  /**
   * @description Get hours and mintues in determinate date
   * @param {Date} date The date received from the shipment request
   * @returns {string} The hours and mintes formated
   */
  public getTime(date: Date): string {
    return moment(date, MARKETPLACE_SHIPPER_CONSTANTS.MOMENT_FORMAT).format(this.timeFormat);
  }

  /**
   * @description Angular Lifecycle for component initialization
   */
  public async ngOnInit(): Promise<void> {
    this.displayedColumns = VIEWSHIPMENTREQUESTCOLUMNS;
    this.getDestinationsSource();
    this.getWaypoints();
    this.optimizeWaypoints = true;
    this.provideRouteAlternatives = false;
    this.setAddress();
    this.setCurrentLocation();
    this.setRouteAndTimeTravel();
  }

  /**
   * @description Initialize the map
   */
  public async setMap(): Promise<void> {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      const distanceMatrixService = new google.maps.DistanceMatrixService();
      const matrixOptions: google.maps.DistanceMatrixRequest = {
        destinations: [`${this.destination.lat.toString()}, ${this.destination.lng.toString()}`],
        origins: [`${this.origin.lat.toString()}, ${this.origin.lng.toString()}`],
        travelMode: google.maps.TravelMode.DRIVING,
      };
      distanceMatrixService.getDistanceMatrix(matrixOptions, (response, status) => {
        if (status !== STATUS_RESPONSE) { return; }
          if (response.destinationAddresses[0] !== MARKETPLACE_SHIPPER_CONSTANTS.WITHOUT_COORDS &&
            response.originAddresses[0] !== MARKETPLACE_SHIPPER_CONSTANTS.WITHOUT_COORDS) {
          if (response.rows[0].elements[0].status === MARKETPLACE_SHIPPER_CONSTANTS.ZERO_RESULTS) {
            this.toast.warningAlert(ConfirmationLabels.noResultDirections);
          } else {
            this.timeDistanceRoute = this.convertTimeLongtoFormatHHMM(response.rows[0].elements[0].duration.value);
            this.distanceRoute = response.rows[0].elements[0].distance.text;
          }
        } else {
          this.toast.errorAlert(ConfirmationLabels.locationErrorMessage);
        }
      });
      this.setFullAddressMarkers();
    });
  }

  /**
   * @description Set the address value for tooltip for marker
   * @param {number} latitude The latitude for search full address
   * @param {number} longitude The longitude for search full address
   * @returns {string} The address formatted
   */
  public getAddress(latitude: number, longitude: number): Promise<string> {
    return new Promise((resolve, reject) => {
      this.geoCoder['geocode']({ 'location': { lat: latitude, lng: longitude } },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            resolve(results[0].formatted_address);
          } else {
            reject(new Error(status));
          }
        }
      );
    });
  }

  /**
   * @description Set the destination list to data source for table
   */
  public async getDestinationsSource(): Promise<void> {
    if (this.shipmentRequestResponse && this.shipmentRequestResponse.destinations?.length > 0) {
      this.destinationsList.push({
        address: this.shipmentRequestResponse.origin.address,
        city: this.shipmentRequestResponse.origin.city,
        deliveryDate: this.shipmentRequestResponse.origin.loadDate,
        geometry: this.shipmentRequestResponse.origin.geometry,
        name: this.shipmentRequestResponse.origin.name,
        sequence: SEQUENCE_DEFAULT,
        state: this.shipmentRequestResponse.origin.state,
        postalCode: this.shipmentRequestResponse.origin.postalCode,
      });
      this.shipmentRequestResponse.destinations?.forEach( (destination, index) => {
        this.destinationsList.push({
          address: destination.address,
          city: destination.city,
          deliveryDate: destination.deliveryDate,
          geometry: destination.geometry,
          name: destination.name,
          sequence: destination.sequence,
          state: destination.state,
          postalCode: destination.postalCode,
        });
      });
    }
    if (this.destinationsList && this.destinationsList.length > 0) {
      this.destinationsSource = new MatTableDataSource(this.destinationsList);
    }
  }

  /**
   * @description Set the current location for the map
   */
  public async setCurrentLocation(): Promise<void> {
    if (DIALOG_SHIPMENT_REQUEST_DETAILS.GEOLOCATION in navigator) {
      navigator.geolocation.getCurrentPosition((coord) => {
        this.latitude = coord.coords.latitude;
        this.longitude = coord.coords.longitude;
      });
    }
  }

  /**
   * @description Convert time in format long to string
   * @param {number} seconds Value to convert to time in format HH:MM
   * @return {string} The value of time convert to string
   */
  private convertTimeLongtoFormatHHMM(value: number): string {
    if (!value) { return null; }
    const TWOPOINTS = ':';
    const TWODIGITS = 10;
    const ZERO = '0';
    let hours = 0;
    let minutes = 0;
    let result = '';
    hours = Math.floor(value / SECONTS_INMINUT);
    value -= hours * SECONTS_INMINUT;
    result = (hours < TWODIGITS) ? ZERO + hours + TWOPOINTS : hours + TWOPOINTS;
    minutes = Math.floor(value / MINUTES_INHOUR);
    value -= minutes * MINUTES_INHOUR;
    result += (minutes < TWODIGITS) ? ZERO + minutes : minutes;
    return result;
  }

  /**
   * @description Fill the waypoints arrays in the map
   */
  public async getWaypoints(): Promise<void> {
    this.origin.lat = this.shipmentRequestResponse.origin.geometry.coordinates[0];
    this.origin.lng = this.shipmentRequestResponse.origin.geometry.coordinates[1];
    this.destination.lat = this.shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1]
    .geometry.coordinates[0];
    this.destination.lng = this.shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1]
    .geometry.coordinates[1];
    this.shipmentRequestResponse.destinations?.forEach((destination, index) => {
      this.waypoints[index] = {
         location: {
          lat: destination.geometry.coordinates[0],
          lng: destination.geometry.coordinates[1]
        },
         stopover: false,
       };
    });
    if (this.shipmentRequestResponse.destinations?.length  > LIMITDELIVELY) {
      this.shipmentRequestResponse.destinations.forEach((destination, index) => {
        this.markers.push({
          stopover: false,
          location: this.waypoints[index].location,
          destination: destination,
          origin: this.shipmentRequestResponse.origin
        });
      });
      this.markers.splice(-1);
    }
  }

  /**
   * @description Determines the data for distance and estimated time of the route
   */
  public setRouteAndTimeTravel(): void {
    if (this.shipmentRequestResponse.destinations.length > MAX_LIMIT_DESTINATION_CONSULT) {
      this.timeDistanceRoute = this.labels.timeRouteDestination;
      this.distanceRoute = this.labels.routeDestination;
    } else {
      this.setMap();
    }
  }

  /**
   * @description Set the address value for all markers
   */
  public setFullAddressMarkers(): void {
    this.markers.forEach(marker => {
      this.getAddress(marker.location.lat, marker.location.lng).then(place => {
        marker.addressMarker = place;
      }).catch(e => {});
    });
  }
}
